import React, { useRef } from "react"
import { Form } from "react-final-form"
import { find } from "lodash"
import arrayMutators from "final-form-arrays"
import {
  useTranslate,
  getFormInitialValues,
  useDataProvider,
} from "react-admin"
import { Box } from "rebass"
import CustomFormView from "../../components/form/CustomFormView"
import { HeaderCustomForm } from "../../components/form/utils"

const MCForm = ({
  initialValues,
  defaultValue,
  saving,
  showNotification,
  sourceApi,
  location,
  match,
  history,
  staticContext,
  typePostUrl,
  pathUrl,
  title,
  ...props
}) => {
  let redirect = useRef(props.redirect)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect
  }

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  )
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const { pathname } = location
  let paramsUrl = pathname

  if (typePostUrl === "CREATE") {
    paramsUrl = pathname.replace("/create", "")
  } else if (typePostUrl === "UPDATE") {
    const check = `/${pathUrl}/`
    const n = pathname.indexOf(check)
    const record_id = pathname.substring(n + check.length, pathname.length)
    paramsUrl = pathname.replace(`/${pathUrl}/${record_id}`, `/${pathUrl}`)
  }

  const submit = (values) => {
    // console.log(values)
    const finalValues = getFormInitialValues(finalInitialValues, values)
    // console.log(finalValues)
    if (typePostUrl === "CREATE") {
      dataProvider
        .getList(sourceApi, {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
          filter: {
            merchant_id: values.merchant_id,
            client_id: values.client_id,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            // console.log("client", response)
            const merchantClient = find(response.data, function (o) {
              // console.log(o.client_id === values.client_id, o)
              return o.client_id === values.client_id
            })
            if (merchantClient && merchantClient.id) {
              const params = {
                id: merchantClient.id,
                data: { ...merchantClient, ...values },
                previousData: merchantClient,
              }
              dataProvider
                .update(sourceApi, params)
                .then(({ data }) => {
                  // console.log(data)
                  showNotification("resources.notification.updated")
                  history.push(paramsUrl)
                })
                .catch((error) => {
                  showNotification(
                    "resources.notification.please_try_again",
                    "warning"
                  )
                })
            } else {
              const params = {
                data: values,
              }
              dataProvider
                .create(sourceApi, params)
                .then(({ data }) => {
                  showNotification("ra.notification.created")
                  history.push(paramsUrl)
                })
                .catch((error) => {
                  showNotification(
                    "resources.notification.please_try_again",
                    "warning"
                  )
                })
            }
          } else {
            const params = {
              data: values,
            }
            dataProvider
              .create(sourceApi, params)
              .then(({ data }) => {
                // console.log(data)
                showNotification("ra.notification.created")
                history.push(paramsUrl)
              })
              .catch((error) => {
                showNotification(
                  "resources.notification.please_try_again",
                  "warning"
                )
              })
          }
        })
        .catch((error) => {
          showNotification("resources.notification.please_try_again", "warning")
        })
    } else if (typePostUrl === "UPDATE") {
      // console.log(finalValues)
      const params = {
        id: finalValues.id,
        data: values,
        previousData: finalValues,
      }
      dataProvider
        .update(sourceApi, params)
        .then(({ data }) => {
          // console.log(data)
          showNotification("resources.notification.updated")
          history.push(paramsUrl)
        })
        .catch((error) => {
          showNotification("resources.notification.please_try_again", "warning")
        })
    }
  }

  return (
    <Box width={[1]}>
      <HeaderCustomForm
        translate={translate}
        title={title}
        finalInitialValues={finalInitialValues}
        paramsUrl={paramsUrl}
      />
      <Form
        key={props.version}
        initialValues={finalInitialValues}
        onSubmit={submit}
        mutators={{
          ...arrayMutators,
          updateField: ([field, value], state, utils) => {
            utils.changeValue(state, field, () => value)
          },
        }}
        keepDirtyOnReinitialize
        destroyOnUnregister
        subscription={defaultSubscription}
        {...props}
        render={({
          form: {
            mutators: { updateField },
          },
          ...formProps
        }) => (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...props}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )}
      />
    </Box>
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

export default MCForm
