import React from "react"
import {
  SaveButton,
  Toolbar,
  required,
  TextInput,
  NumberInput,
  FormDataConsumer,
  ImageField,
  useTranslate,
} from "react-admin"
import { Box } from "rebass"

import {
  BetterCustomSimpleForm,
  RaBooleanInput,
  ImageOlaSingleInput,
  RTDescription,
} from "../../components"

const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["blockquote"],
  ["clean"],
  ["image"],
]

const LevelsEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const Form = (props) => {
  const translate = useTranslate()
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.levels.name"}
      toolbar={<LevelsEditToolbar />}
    >
      <Box width={[1]}>
        <Box>
          <RaBooleanInput source="active" />
        </Box>
        <Box>
          <TextInput source="name" validate={required()} />
        </Box>
        <Box pt={[3]}>
          <RTDescription
            source="description"
            label={translate("resources.levels.fields.description")}
            toolbar={toolbar}
            key="Description"
          />
        </Box>
        <Box>
          <NumberInput source="min_accumulated_points" validate={required()} />
        </Box>
        <Box>
          <NumberInput source="achievement_days" validate={required()} />
        </Box>

        <FormDataConsumer>
          {({ dispatch, ...rest }) => (
            <ImageOlaSingleInput
              source="photo"
              toValue="photo"
              labelImage=""
              label={"resources.levels.fields.photo"}
              accept="image/*"
              key="photo"
              record={rest.formData}
              dispatch={dispatch}
              {...rest}
            >
              <ImageField source="src" title="title" />
            </ImageOlaSingleInput>
          )}
        </FormDataConsumer>
      </Box>
    </BetterCustomSimpleForm>
  )
}
