import React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { BetterCustomSimpleForm } from "../../components"
import MainTab from "./MainTab"

const ProgrammeEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect={props.redirect1} />
  </Toolbar>
)

export const Form = props => {
  const { urlList, ...rest } = props
  return (
    <BetterCustomSimpleForm
      {...rest}
      toolbar={<ProgrammeEditToolbar redirect1={urlList} />}
    >
      <MainTab {...rest} />
    </BetterCustomSimpleForm>
  )
}
