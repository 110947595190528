import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { Form } from "./components"
import { FullLoadingScreen } from "../components"

const EditMerchantClient = (props) => {
  const { match } = props
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "merchantClients",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }

  // console.log(data)
  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        title={"resources.merchants.fields.client"}
        sourceApi="merchantClients"
        typePostUrl={"UPDATE"}
        pathUrl="client"
        initialValues={data}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(EditMerchantClient)
