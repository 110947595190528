import React from "react"
import { required } from "react-admin"
import InputLabel from "@material-ui/core/InputLabel"
import { Box, Flex } from "rebass"
import {
  RaTextInput,
  RaBooleanInput,
  RaTextArea,
  ImageOlaInput,
} from "../../components"
import RaDateTimeInput from "../../components/ra-field/RaDateTimeInput"
import { CollapsePaper } from "./"

const MainTab = props => {
  const { translate } = props
  // console.log(props)
  return (
    <Box>
      <RaBooleanInput source="active" id={"active"} />
      <RaTextInput source="name" validate={required()} />
      <Box width={[1]} pt={[3]}>
        <InputLabel>
          {translate("resources.catalogues.fields.publish_date")}
        </InputLabel>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box pr={[4]}>
            <RaDateTimeInput
              source="start_at"
              style={{ width: 180 }}
              label=""
              notetext="resources.catalogues.message.date_start"
            />
          </Box>
          <Box>
            <RaDateTimeInput
              source="end_at"
              label=""
              style={{ width: 180 }}
              notetext="resources.catalogues.message.date_end"
            />
          </Box>
        </Flex>
      </Box>
      <RaTextArea
        source="description"
        label="resources.catalogues.fields.description"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <RaTextArea
        source="terms"
        label="resources.catalogues.fields.terms"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <Box width={[1]}>
        <ImageOlaInput
          source="banners"
          toValue="banners"
          labelImage={translate("resources.catalogues.fields.banner")}
          label={"resources.catalogues.fields.banner"}
          accept="image/*"
          key="banners"
          {...props}
        />
      </Box>
      <CollapsePaper {...props} translate={translate} />
    </Box>
  )
}

export default MainTab
