import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { translate, WithPermissions } from "react-admin"
import { ROLE_USER } from "../../utils"
import { PROFILE } from "../../restClient"
import DashboardMenuItem from "./DashboardMenuItem"

import {
  SuperAdminMenu,
  AccountAdminMenu,
  AccountClientMenu,
  LoyaltyProgramAdminMenu,
} from "./RoleMenu"

class Menu extends Component {
  state = {
    menuSettings: false,
  }

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  }

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }))
  }

  render() {
    const { onMenuClick, open, logout, translate } = this.props
    const profile = localStorage.getItem(PROFILE)
    // console.log(JSON.parse(profile))
    return (
      <div>
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.super_admin && (
              <SuperAdminMenu
                onMenuClick={onMenuClick}
                handleToggle={() => this.handleToggle("menuSettings")}
                menuSettings={this.state.menuSettings}
                open={open}
                translate={translate}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.account_admin && (
              <AccountAdminMenu
                onMenuClick={onMenuClick}
                handleToggle={() => this.handleToggle("menuSettings")}
                menuSettings={this.state.menuSettings}
                open={open}
                translate={translate}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.client_admin && (
              <AccountClientMenu
                onMenuClick={onMenuClick}
                translate={translate}
                menuSettings={this.state.menuSettings}
                handleToggle={() => this.handleToggle("menuSettings")}
                open={open}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.loyalty_program_admin &&
            profile && (
              <Fragment>
                <DashboardMenuItem />
                <LoyaltyProgramAdminMenu
                  onMenuClick={onMenuClick}
                  translate={translate}
                  profile={profile}
                />
              </Fragment>
            )
          }
        />

        {logout}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  // locale: state.i18n.locale,
})

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate)

export default enhance(Menu)
