import React from "react"
import { Box, Flex } from "rebass"
import Paper from "@material-ui/core/Paper"
import { RaBooleanInput, RaSelectInput, RaNumberInput } from "../../components"
import { listValidFor } from "./index"

export const BirthDaysContainer = ({ classes, translate }) => {
  return (
    <Paper className={classes.paper}>
      <Box>
        <RaBooleanInput
          label="resources.catalogues.fields.visible"
          source="rules.birthday_rule.active"
          id={"visible"}
          notetext="resources.catalogues.message.visible"
        />
        <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-start"}>
          <Box width={[230]} pr={[4]}>
            <RaNumberInput
              label="resources.catalogues.fields.day_before"
              source="rules.birthday_rule.number_of_days_before"
              style={{ width: 50, paddingTop: 16 }}
              sideMessage={translate("resources.catalogues.info.days")}
              notetext="resources.catalogues.message.day_before"
            />
          </Box>
          <Box width={[220]}>
            <RaSelectInput
              choices={listValidFor}
              label="resources.catalogues.fields.valid_for"
              source="rules.birthday_rule.valid_for_month"
              style={{ width: 130, paddingTop: 16 }}
              notetext="resources.catalogues.message.valid_for"
            />
          </Box>
        </Flex>
      </Box>
    </Paper>
  )
}
