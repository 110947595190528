import React from "react"
import { showNotification, useGetOne } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FullLoadingScreen } from "../components"
import { Form } from "./Create"
import { EditToolbar } from "../components"

const EarningRulesEdit = props => {
  const { location } = props
  const { pathname } = location
  const check = `/earningRules/`
  const n = pathname.indexOf(check)
  const record_id = pathname.substring(n + check.length, pathname.length)
  const { data, loading, error } = useGetOne("earningRules", record_id)

  if (loading) {
    return <FullLoadingScreen />
  }
  if (error) return <div>error</div>

  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        title={"resources.earningRules.edit_earning"}
        sourceApi="earningRules"
        typePostUrl={"UPDATE"}
        pathUrl="earningRules"
        record={data}
        initialValues={data}
        toolbar={<EditToolbar />}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(EarningRulesEdit)
