import React from "react"
import { useTranslate } from "react-admin"
import { Box } from "rebass"
import { Line } from "react-chartjs-2"
const ChartView = (props) => {
  const translate = useTranslate()
  const { labels, dataSetRedemption, dataSetConsumption } = props
  const newData = {
    labels: labels,
    datasets: [
      {
        label: translate("resources.dashboard.label.redemption"),
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataSetRedemption,
      },
      {
        label: translate("resources.dashboard.label.consumption"),
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,80,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataSetConsumption,
      },
    ],
  }

  return (
    <Box width={[1]} py={[2]}>
      <Line data={newData} />
    </Box>
  )
}

export default ChartView
