/* eslint-disable no-unused-vars */
import React, { Fragment } from "react"
import { Box, Flex } from "rebass"
import {
  useTranslate,
  FormDataConsumer,
  BooleanInput,
  TextInput,
} from "react-admin"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import { ImageOlaSingleInput, RaLongTextInput } from "../../components"
import {
  defaultValueColor,
  SketchColorPickerInput,
} from "./SketchColorPickerInput"

const SettingsTab = (props) => {
  const translate = useTranslate()
  const { record, ...rest } = props
  return (
    <Box width={[1]} py={[2]}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Fragment>
              <Box width={[1]} pb={[2]}>
                <ImageOlaSingleInput
                  source="app_cloud_settings.logo"
                  toValue="logo"
                  labelImage={translate("resources.programmes.fields.logo")}
                  label={"resources.programmes.fields.logo"}
                  accept="image/*"
                  key="app_cloud_settings.logo"
                  record={formData}
                  {...rest}
                />
              </Box>
              <Box width={[1]} p={[2]}>
                <RaLongTextInput
                  multiline={false}
                  label={"resources.programmes.fields.domain"}
                  source="app_cloud_settings.domain"
                />
              </Box>
              <Box py={[2]} width={[1]}>
                <Card variant={"outlined"}>
                  <CardHeader
                    title={translate(
                      "resources.programmes.fields.login_settings"
                    )}
                    style={{ padding: 8, paddingBottom: 4 }}
                  />
                  <CardContent style={{ padding: 4 }}>
                    <Fragment>
                      <Box width={[1]} p={[2]}>
                        <RaLongTextInput
                          label={"resources.programmes.fields.help_text"}
                          source="app_cloud_settings.help_text"
                        />
                      </Box>
                      <Flex
                        width={[1]}
                        flexDirection={"row"}
                        flexWrap={"wrap"}
                        justifyContent={"flex-start"}
                        p={[2]}
                      >
                        <BooleanInput
                          label={"resources.programmes.fields.login_google"}
                          source="app_cloud_settings.login_google"
                        />
                        <BooleanInput
                          label={"resources.programmes.fields.login_instagram"}
                          source="app_cloud_settings.login_instagram"
                        />
                        <BooleanInput
                          label={"resources.programmes.fields.login_mobile"}
                          source="app_cloud_settings.login_mobile"
                        />
                        <BooleanInput
                          label={"resources.programmes.fields.login_facebook"}
                          source="app_cloud_settings.login_facebook"
                        />
                      </Flex>
                    </Fragment>
                  </CardContent>
                </Card>
              </Box>
              <Card variant={"outlined"}>
                <CardHeader
                  title={translate("resources.programmes.fields.colors")}
                  style={{ padding: 8, paddingBottom: 4 }}
                />
                <CardContent style={{ padding: 4 }}>
                  <Flex
                    width={[1]}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    justifyContent={"flex-start"}
                  >
                    {defaultValueColor.map((item, index) => {
                      return (
                        <SketchColorPickerInput
                          key={index}
                          {...props}
                          formData={formData}
                          source={`app_cloud_settings.colors.${item.text}`}
                          value={item.value}
                          title={item.text}
                        />
                      )
                    })}
                  </Flex>
                </CardContent>
              </Card>
            </Fragment>
          )
        }}
      </FormDataConsumer>
    </Box>
  )
}

export default SettingsTab
