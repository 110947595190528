import React from "react"
// import { useTranslate } from "react-admin"
import { get } from "lodash"
import {
  BetterCreate,
  EditToolbar,
  ActionForm,
  NoAccessPage,
  BetterSimpleForm,
} from "../components"
import { Box } from "rebass"
import { PROFILE } from "../restClient"
import { ROLE_USER } from "../utils"
import { MainTab } from "./components"

const FormFields = (props) => {
  const { permissions } = props
  if (permissions !== ROLE_USER.client_admin) {
    return <NoAccessPage />
  }
  return (
    <Box width={[1]}>
      <MainTab {...props} />
    </Box>
  )
}

const MainForm = (props) => {
  const {
    staticContext,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...checkProps
  } = props
  return (
    <BetterSimpleForm {...checkProps}>
      <FormFields {...props} />
    </BetterSimpleForm>
  )
}
const CreateProgramme = (props) => {
  const profile = localStorage.getItem(PROFILE)

  const userProfile = JSON.parse(profile)
  // console.log(userProfile)
  return (
    <BetterCreate
      {...props}
      actions={<ActionForm title={"resources.programmes.new_title"} />}
    >
      <MainForm
        {...props}
        toolbar={<EditToolbar />}
        initialValues={{
          account_id: get(userProfile, "account_id", 0),
          client_id: get(userProfile, "client_id", 0),
        }}
      />
    </BetterCreate>
  )
}

export default CreateProgramme
