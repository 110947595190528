import React from "react"
import { EditForm } from "./components"
import { useTranslate, useQuery, Toolbar, SaveButton } from "react-admin"
import { Flex, Box, Text } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import { ListButton } from "../components/buttons"
import { BetterEdit, FullLoadingScreen } from "../components"
import { ROLE_USER } from "../utils"

const EditToolbar = (props) => {
  const { redirect1, ...rest } = props
  return (
    <Toolbar {...rest}>
      <SaveButton redirect={redirect1} />
    </Toolbar>
  )
}

const ActionForm = (props) => {
  const translate = useTranslate()
  const { data, basePath } = props
  const urlList = `/clients/${data.client_id}${basePath}`
  return (
    <Box width={[1]} pt={[2]} pl={[3]} pb={[2]}>
      <Box width={[1]}>
        <Text fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
        </Text>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton basepath={urlList} style={{ color: lightBlue[700] }} />
      </Flex>
    </Box>
  )
}

const ProgrammeEdit = (props) => {
  const { match, history, permissions } = props
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "programmes",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const urlList =
    permissions !== ROLE_USER.loyalty_program_admin
      ? `/clients/${data.client_id}/programmes`
      : `/programmes/${data.id}`
  if (permissions !== ROLE_USER.loyalty_program_admin) {
    return (
      <BetterEdit
        {...props}
        actions={<ActionForm title={"resources.programmes.name"} data={data} />}
      >
        <EditForm
          toolbar={<EditToolbar redirect1={() => history.push(urlList)} />}
          account_id={data.account_id}
          {...props}
        />
      </BetterEdit>
    )
  }
  return (
    <BetterEdit {...props}>
      <EditForm
        toolbar={<EditToolbar redirect1={() => history.push(urlList)} />}
        account_id={data.account_id}
        {...props}
      />
    </BetterEdit>
  )
}

export default ProgrammeEdit
