import React from "react"
import { withStyles } from "@material-ui/core/styles"
// import { Image, Box, Flex } from "rebass"
import AppBar from "./AppBar"

const DRAWER_WIDTH = 256

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    width: DRAWER_WIDTH,
  },
}

const AppHeader = withStyles(styles)(({ classes, ...props }) => {
  return (
    <AppBar
      {...props}
      style={{ boxShadow: "none", backgroundColor: "transparent" }}
    >
      <span className={classes.spacer} />
    </AppBar>
  )
})

export default AppHeader
