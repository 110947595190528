import React from "react"
import { Flex } from "rebass"
import { BetterEditButton, BetterShowButton, BetterChartShowButton } from "../"
import { MemberButton, ChannelButton } from "./LinkButton"

export const ButtonMemberEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <MemberButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  )
}

export const ButtonChannelEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <ChannelButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  )
}

export const ButtonEditView = (props) => {
  return (
    <Flex
      flexDirection={["row"]}
      justifyContent={"flex-end"}
      style={{ height: 33 }}
    >
      <BetterEditButton {...props} />
    </Flex>
  )
}

export const ButtonShowEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterShowButton {...props} />
    <BetterEditButton {...props} />
  </Flex>
)

export const ButtonChartEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartShowButton {...props} bashPath="/merchants/show" />
    <BetterEditButton {...props} />
  </Flex>
)
