import React from "react"
import { NumberInput, required, useTranslate } from "react-admin"
import Paper from "@material-ui/core/Paper"
import { DataDefaultForm } from "./DataDefaultForm"
import { CollapseMui } from "../../components"

export const MemberRegister = props => {
  const translate = useTranslate()

  if (props.record && !props.record.member_register) {
    props.record.member_register = {
      points: 0,
    }
  }
  return (
    <div>
      <NumberInput
        label="resources.earningRules.fields.member_register.points"
        source="member_register.points"
        validate={required()}
      />
      <CollapseMui
        label={translate("resources.earningRules.title.advanced_options")}
      >
        <Paper typepaper={"paper"} style={{ marginTop: 0, padding: 16 }}>
          <DataDefaultForm {...props} />
        </Paper>
      </CollapseMui>
    </div>
  )
}
