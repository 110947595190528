import React from "react"
import { SelectArrayInput } from "react-admin"
import Paper from "@material-ui/core/Paper"

import {
  RaReferenceArrayInput,
  RaSelectArrayChipInput,
  RaNumberInput,
} from "../../components"

export const CollapseContent = props => {
  const { classes, filterMerchant } = props
  return (
    <Paper
      typepaper={"paper"}
      style={{ marginTop: 0, padding: 16 }}
      className={classes.paper}
    >
      <RaSelectArrayChipInput
        source="merchant_offer.skus"
        label="resources.products.fields.sku"
        fullWidth={true}
        notetext="resources.products.note_message.sku"
      />
      <RaNumberInput
        source="merchant_offer.min_order_value"
        label="resources.products.fields.min_order_value"
        style={{ width: 500 }}
        notetext="resources.products.note_message.min_order_value"
      />
      <RaNumberInput
        source="merchant_offer.reward_validaty"
        label="resources.products.fields.reward_validity"
        style={{ width: 500 }}
        notetext="resources.products.note_message.reward_validity"
      />

      <RaReferenceArrayInput
        label={"resources.products.fields.not_in_branches"}
        source="merchant_offer.not_in_branches"
        resource={"products"}
        reference={"branches"}
        filter={filterMerchant}
        notetext="resources.products.note_message.not_in_branches"
      >
        <SelectArrayInput optionText="name" style={{ width: 500 }} />
      </RaReferenceArrayInput>
      <RaNumberInput
        source="merchant_offer.cost"
        label={"resources.products.fields.cost"}
      />
      <RaNumberInput
        source="merchant_offer.price"
        label={"resources.products.fields.price"}
      />
      <RaNumberInput
        source="merchant_offer.margin"
        label={"resources.products.fields.margin"}
      />
    </Paper>
  )
}
