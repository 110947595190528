import React, { useEffect } from "react"
import { Box, Flex } from "rebass"
import { isNaN } from "lodash"
import { useDataProvider } from "react-admin"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { useStylesSearch, remove } from "./utils"
import { categories } from "../../components"

const SearchItems = (props) => {
  const { translate, catalogue_id } = props
  const classes = useStylesSearch()
  const dataProvider = useDataProvider()
  const [isRequest, setIsRequest] = React.useState(false)
  const [search, setSearch] = React.useState("")
  const [minPrice, setMinPrice] = React.useState("")
  const [maxPrice, setMaxPrice] = React.useState("")
  const [category, setCategory] = React.useState("")

  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value)
    } else if (name === "minPrice") {
      setMinPrice(event.target.value)
    } else if (name === "maxPrice") {
      setMaxPrice(event.target.value)
    } else if (name === "category") {
      setCategory(event.target.value)
    }
    setIsRequest(true)
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        let dataMinPrice = parseInt(minPrice)
        let dataMaxPrice = parseInt(maxPrice)

        let params = {
          search: search,
          min_price: dataMinPrice,
          max_price: dataMaxPrice,
          category: category,
          catalog_id: catalogue_id,
        }
        if (isNaN(dataMinPrice)) {
          remove(params, "min_price")
        } else {
          params["min_price"] = dataMinPrice
        }
        if (isNaN(dataMaxPrice)) {
          remove(params, "max_price")
        } else {
          params["max_price"] = dataMaxPrice
        }
        if (category !== "") {
          params["category"] = category
        } else {
          remove(params, "category")
        }
        setIsRequest(false)
        dataProvider
          .getList("products", {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "name", order: "ASC" },
            filter: params,
          })
          .then((response) => {
            if (response && response.data) {
              const { data } = response
              props.searchHandle(data)
            }
          })
      }
    }, 500)
    return () => clearTimeout(timeout)
  })

  return (
    <Box width={[1]} pb={[2]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Box pr={[3]}>
          <TextField
            label="search"
            margin="dense"
            variant="filled"
            value={search}
            onChange={handleChange("search")}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box pr={[3]}>
          <FormControl className={classes.formControl}>
            <InputLabel id="label" style={{ minWidth: 180 }} shrink={true}>
              {translate("resources.rewards.fields.category")}
            </InputLabel>
            <Select
              id="category_id"
              value={category}
              margin="dense"
              variant="filled"
              style={{ minWidth: 180 }}
              onChange={handleChange("category")}
            >
              <MenuItem value="" style={{ minHeight: 32 }}></MenuItem>
              {categories &&
                categories &&
                categories.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
        <Box pr={[3]}>
          <TextField
            label="Min Price"
            margin="dense"
            variant="filled"
            onChange={handleChange("minPrice")}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box pr={[3]}>
          <TextField
            label="Max Price"
            margin="dense"
            variant="filled"
            onChange={handleChange("maxPrice")}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchItems
