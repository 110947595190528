import React from "react"
import { showNotification } from "react-admin"
import { withRouter } from "react-router-dom"
import { Flex, Box } from "rebass"
import { lightBlue } from "@material-ui/core/colors"

import { connect } from "react-redux"
import compose from "recompose/compose"
import { ListButton } from "../components"

const ProductChart = props => {
  const { location } = props
  const { pathname } = location
  const check = `/products`
  const n = pathname.indexOf(check)
  const currentParent = pathname.substring(0, n + check.length)

  return (
    <Box width={[1]} p={[3]}>
      <Box width={[1]} pt={[2]} pl={[3]} pb={[2]}>
        <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
          <ListButton
            basepath={currentParent}
            record={{}}
            style={{ color: lightBlue[700] }}
          />
        </Flex>
      </Box>
    </Box>
  )
}

const enhance = compose(
  withRouter,
  connect(
    undefined,
    { showNotification }
  )
)

export default enhance(ProductChart)
