import React from "react"
import { useGetOne } from "react-admin"
import { get } from "lodash"
import { Box, Text } from "rebass"
import { Loading } from "../mui"

const RaReferenceTextField = ({
  label,
  defaultValue,
  source,
  recordId,
  reference,
  textAlign = "left",
  ...rest
}) => {
  const { data, loading, error } = useGetOne(reference, recordId)
  if (loading) {
    return <Loading />
  }
  if (error) {
    return <div />
  }
  return (
    <Box>
      <Text fontSize={12} textAlign={textAlign}>
        {get(data, source, "")}
      </Text>
    </Box>
  )
}

export default RaReferenceTextField
