import React from "react"
import { Flex, Text, Box } from "rebass"
import moment from "moment"
import { get } from "lodash"
import { Link } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { lightBlue } from "@material-ui/core/colors"
import { ListButton } from "../../components"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
  },
  label: {
    fontSize: 10,
    fontWeight: "bold",
  },
}))

export const HeaderMember = ({ data, match }) => {
  const url = get(match, "url", "")
  const paramsUrl = url.replace(`/${match.params.id}/members`, "")
  return (
    <Flex
      pb={[3]}
      width={[1]}
      pt={[3]}
      justifyContent={["space-between"]}
      alignItems={["center"]}
    >
      <Text fontSize={12} fontWeight={"bold"}>
        {get(data, "name", "")}
      </Text>
      <ListButton basepath={paramsUrl} style={{ color: lightBlue[700] }} />
    </Flex>
  )
}

export const ListMembers = ({ members, translate }) => {
  const classes = useStyles()

  return (
    <Box width={[1]}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.levels.fields.email")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.levels.fields.phone")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.levels.fields.name")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.levels.fields.created_at")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.levels.fields.status")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Link to={`/members/${row.id}/show`}>{row.email}</Link>
                </TableCell>

                <TableCell>{row.phone}</TableCell>
                <TableCell>
                  {row.first_name} {row.first_name}
                </TableCell>
                <TableCell>
                  {moment(row.created_at).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>
                  {row.active
                    ? translate("resources.levels.fields.active")
                    : translate("resources.levels.fields.inActive")}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}
