/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Box, Flex, Text } from "rebass"
import { get } from "lodash"
import { SketchPicker } from "react-color"
import { lightBlue } from "@material-ui/core/colors"
import { CloseSquareIcon } from "../../components/icon_svg"

const defaultValueColor = [
  { text: "text", value: "#626163" },
  { text: "background", value: "#EFEFEF" },
  { text: "primary", value: "#6B5B37" },
  { text: "secondary", value: "#C3C0B6" },
  { text: "highlight", value: "#F1E656" },
  { text: "accent", value: "#E23098" },
  { text: "accent1", value: "#4A90E2" },
  { text: "accent2", value: "#3F5498" },
  { text: "muted", value: "#F2F1EF" },
]

const SketchColorPickerInput = (props) => {
  const { value, updateField, source, title, formData } = props
  let valueColor = get(formData, source, value)
  const defaultValue = {
    displayColorPicker: false,
    color: valueColor,
    result: valueColor,
  }
  const [colorState, setColorState] = useState(defaultValue)

  const handleChange = (color) => {
    let { rgb } = color
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    setColorState({ ...colorState, color: newColor, result: newColor })
  }
  const handleChangeComplete = (color, event) => {
    let { rgb } = color
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    updateField(source, newColor)
  }

  const handleDisabled = () => {
    setColorState({
      ...colorState,
      displayColorPicker: !colorState.displayColorPicker,
    })
  }
  return (
    <Box p={[2]}>
      <Text fontSize={[0]} style={{ textTransform: "capitalize" }} pb={[1]}>
        {title}
      </Text>
      <Box
        style={{
          backgroundColor: colorState.color,
          width: 120,
          height: 120,
          cursor: "pointer",
        }}
        onClick={() => handleDisabled()}
      />
      {colorState.displayColorPicker && (
        <div style={{ position: "absolute", zIndex: 1000 }}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            style={{ backgroundColor: lightBlue[900], color: "#FFFFFF" }}
            p={[1]}
          >
            <CloseSquareIcon
              onClick={() => handleDisabled()}
              style={{ cursor: "pointer" }}
            />
          </Flex>
          <SketchPicker
            color={colorState.color}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </Box>
  )
}

export { defaultValueColor, SketchColorPickerInput }
