import React from "react"

import { API_SERVER, API_DEBUG } from "../../restClient"

export const IsDebugForm = false

export const ValuesForm = ({ isDebug, formName = "record-form" }) => {
  if (API_SERVER !== API_DEBUG && isDebug) {
    return <div style={{ width: 400 }}>{/*<Values form={formName} />  */}</div>
  }
  return null
}

export const checkNumber = (v) => {
  if (v && v.length > 0) {
    return isNaN(v) ? undefined : parseFloat(v)
  }
  return isNaN(v) ? 0 : v
}
