import React from "react"
import { useQueryWithStore } from "react-admin"
import { Text } from "rebass"
import { get } from "lodash"
import moment from "moment"
import NumberFormat from "react-number-format"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
})

const DateField = ({ record, source }) => {
  const create_at = get(record, source, "")
  return (
    <Text fontSize={[0]}>
      {create_at !== "" ? moment(create_at).format("DD/MM/YYYY") : ""}
    </Text>
  )
}

const NumberField = ({ record, source }) => {
  const currency = get(record, source, "")
  return (
    <NumberFormat
      value={currency}
      displayType={"text"}
      thousandSeparator={true}
      renderText={value => <Text fontSize={[0]}>{value}</Text>}
    />
  )
}

const TextField = ({ record, source, ...rest }) => {
  const title = get(record, source, "")
  return (
    <Text fontSize={[0]} {...rest}>
      {title}
    </Text>
  )
}
const ChannelField = ({ record, source }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: "channels",
    payload: { id: get(record, source, "") },
  })
  if (!loaded) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div />
  }
  return <Text fontSize={[0]}>{data.name}</Text>
}

const BooleanField = ({ record, source }) => {
  const isShow = get(record, source, false)
  return isShow ? <CheckIcon /> : <CloseIcon />
}

const RewardField = ({ record, source }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: "rewards",
    payload: { id: get(record, source, "") },
  })
  if (!loaded) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div />
  }
  return <Text fontSize={[0]}>{get(data, "product.name", "")}</Text>
}

export {
  TextField,
  ChannelField,
  NumberField,
  DateField,
  useStyles,
  RewardField,
  BooleanField,
}
