import React from "react"
import { showNotification, SaveButton, Toolbar, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"

import { BetterCustomSimpleForm, FullLoadingScreen } from "../components"
import { FormFields } from "./FormFields"

const BranchEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const Form = props => {
  return (
    <BetterCustomSimpleForm {...props} toolbar={<BranchEditToolbar />}>
      <FormFields {...props} />
    </BetterCustomSimpleForm>
  )
}

const BranchEdit = props => {
  const { location } = props
  const { pathname } = location
  const check = `/branches/`
  const n = pathname.indexOf(check)
  const record_id = pathname.substring(n + check.length, pathname.length)

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "branches",
    payload: { id: record_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  if (error) return <div>error</div>

  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        sourceApi="branches"
        typePostUrl={"UPDATE"}
        pathUrl="branches"
        record={data}
        initialValues={data}
      />
    </Box>
  )
}

const enhance = compose(
  withRouter,
  connect(
    undefined,
    { showNotification }
  )
)

export default enhance(BranchEdit)
