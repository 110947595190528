import React from "react"
import { Box, Flex } from "rebass"
import { useTranslate, ReferenceField, TextField } from "react-admin"
import Avatar from "@material-ui/core/Avatar"
import { ActionForm, BetterShow } from "../components"
import { InputLabel } from "@material-ui/core"
import { StatusField } from "./"

const Form = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props
  const translate = useTranslate()
  return (
    <Box width={[1]}>
      <Box p={[2]}>
        <Flex width={[1]} alignItems={"center"} justifyContent={"center"}>
          <Avatar
            alt=""
            src={props.record.avatar || "/avatar.jpg"}
            style={{
              width: 200,
              height: "auto",
              borderWidth: 4,
              borderColor: "#0087BA",
              borderStyle: "solid",
            }}
          />
        </Flex>
      </Box>
      <Flex pt={[3]} width={[1]} flexDirection={"column"} alignItems={"center"}>
        <Box
          p={[3]}
          style={{
            borderWidth: 2,
            backgroundColor: "#FFFFFF",
            borderColor: "#EFEFEF",
            borderStyle: "solid",
          }}
        >
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.status")}
            </InputLabel>
            <StatusField {...rest} />
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.loyalty_program_name")}
            </InputLabel>
            <ReferenceField
              {...rest}
              source="custom.loyalty_program_id"
              reference="loyaltyPrograms"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.first_name")}
            </InputLabel>
            <TextField source="first_name" {...rest} />
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.last_name")}
            </InputLabel>
            <TextField source="last_name" {...rest} />
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.email")}
            </InputLabel>
            <TextField source="email" {...rest} />
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <InputLabel style={{ minWidth: 200, fontSize: 14 }}>
              {translate("resources.users.fields.role")}
            </InputLabel>
            <TextField source="role" {...rest} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
export const UserShow = (props) => {
  return (
    <BetterShow
      {...props}
      actions={
        <ActionForm title={"resources.users.name"} basePath={"/users"} />
      }
    >
      <Form {...props} />
    </BetterShow>
  )
}
