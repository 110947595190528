import React from "react"
import { useQueryWithStore } from "react-admin"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import {
  TextField,
  // ChannelField,
  NumberField,
  DateField,
  useStyles,
} from "./Fields"

const Transactions = (props) => {
  const classes = useStyles()
  const { record } = props
  // console.log(record.loyalty_program_id)
  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: "transactions",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "DESC" },
      filter: {
        type: "credit",
        contains_order: true,
        member: record.id,
        loyalty_program_id: record.loyalty_program_id,
      },
    },
  })

  if (!loaded) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div />
  }

  return (
    <Paper elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Order Number</TableCell>
            {/* <TableCell>Channel</TableCell> */}
            <TableCell>Amount</TableCell>
            <TableCell>Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data !== null &&
            data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <DateField record={row} source="created_at" />
                </TableCell>
                <TableCell>
                  <TextField record={row} source="order_details.ref" />
                </TableCell>
                {/* <TableCell>
                  <ChannelField record={row} source="channel_id" />
                </TableCell> */}
                <TableCell>
                  <NumberField record={row} source="order_details.total" />
                </TableCell>
                <TableCell>
                  <NumberField record={row} source="points" />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Transactions
