import React from "react"
import { SaveButton, Toolbar } from "react-admin"
import FormFieldsLPA from "./FormFieldsLPA"
import FormCreateLPA from "./FormCreateLPA"

const LPAToolbar = (props) => (
  <Toolbar {...props} style={{ paddingLeft: 16 }}>
    <SaveButton />
  </Toolbar>
)

export const FormLPA = (props) => {
  return (
    <FormCreateLPA {...props} toolbar={<LPAToolbar />} submitOnEnter={false}>
      <FormFieldsLPA {...props} />
    </FormCreateLPA>
  )
}
