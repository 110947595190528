import React from "react"
import {
  showNotification,
  useQuery,
  useTranslate,
  NumberField,
} from "react-admin"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { lightBlue } from "@material-ui/core/colors"
import { Flex, Box, Text } from "rebass"
import { EditIcon } from "../components/icon_svg"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import { ProfileDetails, ProfileSummary, FieldRow } from "./Profile"
import { FullLoadingScreen, HeaderEditButton } from "../components"
import { CardTransactions } from "./components"

const MemberShowAction = ({ match, record }) => {
  const { url } = match

  const newUrl = url.replace(`/${record.id}/profile`, "")
  const translate = useTranslate()
  return (
    <Flex
      width={[1]}
      py={[3]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box width={[1]}>
        <Text fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.members.name`)}
        </Text>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <HeaderEditButton
          basePath={newUrl}
          icon={<EditIcon />}
          sizeIcon={"xsmall"}
          record={record}
          style={{ color: lightBlue[700] }}
        />
      </Flex>
    </Flex>
  )
}

const Loyalty = props => {
  const { ...rest } = props
  return (
    <Card style={{ marginBottom: 8 }}>
      <CardHeader
        disableTypography={true}
        title={
          <Text fontSize={[14]} fontWeight={"bold"}>
            Loyalty
          </Text>
        }
        style={{ borderBottom: "solid 1px #cccccc" }}
      />
      <CardContent>
        <FieldRow justifyContent={["space-between"]}>
          <Text fontSize={[12]} pr={[1]}>
            Total earned points
          </Text>

          <NumberField {...rest} source="loyalty_stat.total_earned_points" />
        </FieldRow>
        <FieldRow justifyContent={["space-between"]}>
          <Text fontSize={[12]} pr={[1]}>
            Active points
          </Text>
          <NumberField {...rest} source="loyalty_stat.active_points" />
        </FieldRow>
        <FieldRow justifyContent={["space-between"]}>
          <Text fontSize={[12]} pr={[1]}>
            Used points
          </Text>
          <NumberField {...rest} source="loyalty_stat.used_points" />
        </FieldRow>
        <FieldRow justifyContent={["space-between"]}>
          <Text fontSize={[12]} pr={[1]}>
            Expired points
          </Text>
          <NumberField {...rest} source="loyalty_stat.expired_points" />
        </FieldRow>
      </CardContent>
    </Card>
  )
}

const ShowLayout = props => {
  const { staticContext, showNotification, translate, ...rest } = props
  return (
    <div>
      <Flex>
        <Box width={[1, 1 / 4]}>
          <ProfileSummary {...rest} />
          <ProfileDetails {...rest} />
        </Box>
        <Box width={[1, 3 / 4]} style={{ marginLeft: 8 }}>
          <Loyalty {...rest} />
          <CardTransactions {...rest} />
        </Box>
      </Flex>
    </div>
  )
}

const MemberProfile = props => {
  const { match } = props

  const translate = useTranslate()

  const { data, loading } = useQuery({
    type: "getOne",
    resource: "members",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  return (
    <Box width={[1]} p={[2]}>
      <MemberShowAction {...props} record={data} />
      <ShowLayout {...props} record={data} translate={translate} />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(MemberProfile)
