import React from "react"
import { ReferenceInput, TextInput, SelectInput, required } from "react-admin"
import { RaBooleanInput } from "../../components"
import { Flex } from "rebass"
const FormFieldsLPA = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaBooleanInput source="status" parse={(v) => (v ? 1 : 0)} />
      <TextInput source="first_name" style={{ width: 256 }} />
      <TextInput source="last_name" style={{ width: 256 }} />
      <TextInput
        source="email"
        type="email"
        validate={required()}
        style={{ width: 256 }}
      />
      <TextInput source="mobile" style={{ width: 256 }} />
      <ReferenceInput
        source="custom.loyalty_program_id"
        label="resources.users.fields.loyalty_program_name"
        reference="loyaltyPrograms"
        style={{ width: 256 }}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Flex>
  )
}

export default FormFieldsLPA
