import React from "react"
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  required,
  FormDataConsumer,
} from "react-admin"
import { get } from "lodash"
import {
  BetterSimpleForm,
  BetterEdit,
  BetterCreate,
  EditToolbar,
  ActionForm,
  RaBooleanInput,
  NoAccessPage,
} from "../components"
import { Flex } from "rebass"
import { ROLE_USER } from "../utils"
import { roles } from "./utils"

const MerchantField = (props) => {
  const { permissions, updateField } = props

  const onChangeMember = (e, merchant_id) => {
    // console.log(e.target.value, merchant_id)
    updateField("custom.branch_id", "")
  }
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaBooleanInput source="status" parse={(v) => (v ? 1 : 0)} />
      {/* {props.permissions === ROLE_USER.super_admin && (
        <ReferenceInput
          source="loyalty_program_id"
          reference="loyaltyPrograms"
          style={{ width: 300 }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )} */}

      <TextInput source="first_name" style={{ width: 256 }} />
      <TextInput source="last_name" style={{ width: 256 }} />
      <TextInput
        source="email"
        type="email"
        validate={required()}
        style={{ width: 256 }}
      />
      <TextInput source="mobile" style={{ width: 256 }} />
      {props.permissions === ROLE_USER.super_admin && (
        <SelectInput
          source="role"
          choices={roles(permissions)}
          validate={required()}
          style={{ width: 256 }}
        />
      )}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          props.permissions === ROLE_USER.account_admin &&
          formData.role !== ROLE_USER.account_admin &&
          formData.role !== ROLE_USER.super_admin && (
            <SelectInput
              source="role"
              choices={roles(permissions)}
              validate={required()}
              style={{ width: 256 }}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          props.permissions === ROLE_USER.client_admin &&
          formData.role !== ROLE_USER.account_admin &&
          formData.role !== ROLE_USER.super_admin &&
          formData.role !== ROLE_USER.client_admin &&
          formData.role !== ROLE_USER.merchant && (
            <SelectInput
              source="role"
              choices={roles(permissions)}
              validate={required()}
              style={{ width: 256 }}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.role === ROLE_USER.account_admin && (
            <ReferenceInput
              source="account_id"
              reference="accounts"
              label="resources.users.fields.account"
              style={{ width: 256 }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.role === ROLE_USER.merchant && (
            <ReferenceInput
              source="custom.merchant_id"
              reference="merchants"
              label="resources.users.fields.custom.merchant"
              // filter={{
              //   loyalty_program_id: get(
              //     formData,
              //     "custom.loyalty_program_id",
              //     0
              //   ),
              // }}
              onChange={(e) => onChangeMember(e, formData.custom.merchant_id)}
              style={{ width: 256 }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.role === ROLE_USER.merchant &&
            formData.custom &&
            formData.custom.merchant_id &&
            formData.custom.merchant_id !== "" && (
              <ReferenceInput
                source="custom.branch_id"
                reference="branches"
                label="resources.users.fields.branch"
                filter={{ merchant_id: formData.custom.merchant_id }}
                allowEmpty
                style={{ width: 256 }}
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          )
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.role === ROLE_USER.client_admin && (
              <ReferenceInput
                source="custom.client_id"
                label="resources.users.fields.custom.client"
                reference="clients"
                style={{ width: 256 }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          )
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.role === ROLE_USER.loyalty_program_admin && (
              <ReferenceInput
                source="custom.loyalty_program_id"
                label="resources.users.fields.loyalty_program_name"
                reference="loyaltyPrograms"
                style={{ width: 256 }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          )
        }}
      </FormDataConsumer>
    </Flex>
  )
}

const Form = ({ isEdit, ...props }) => {
  const { permissions, record } = props
  if (
    permissions === ROLE_USER.super_admin &&
    isEdit &&
    get(record, "role", "") !== ROLE_USER.account_admin
  ) {
    //isEdit &&
    return <NoAccessPage />
  }
  // if (
  //   permissions === ROLE_USER.client_admin &&
  //   get(record, "role", "") !== ROLE_USER.merchant
  // ) {
  //   return <NoAccessPage />
  // }

  // const onChangeMember = (e, merchant_id) => {
  //   console.log(e.target.value, merchant_id)
  //   // updateField("custom.branch_id", "")
  // }

  return (
    <BetterSimpleForm {...props}>
      <MerchantField {...props} />
    </BetterSimpleForm>
  )
}
export const create = (props) => (
  <BetterCreate
    {...props}
    actions={<ActionForm title={"resources.users.name"} pt={3} />}
  >
    <Form {...props} />
  </BetterCreate>
)
export const edit = (props) => (
  <BetterEdit
    {...props}
    actions={<ActionForm title={"resources.users.name"} pt={3} />}
  >
    <Form toolbar={<EditToolbar />} {...props} isEdit={true} />
  </BetterEdit>
)
