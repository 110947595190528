import React from "react"
import {
  Datagrid,
  FormTab,
  TextField,
  useTranslate,
  BooleanField,
  useQueryWithStore,
  ReferenceField,
} from "react-admin"
import { get } from "lodash"
import moment from "moment"
import { Flex, Text } from "rebass"
import { Pagination } from "react-admin"
import {
  BetterEditButton,
  BetterTabbedForm,
  RaReferenceManyField,
  BetterChartButton,
  BetterDeleteButton,
} from "../../components"
import MainTab from "./MainTab"

const DateField = ({ record, source }) => {
  const mydate = get(record, source, "")
  return <Text>{moment(mydate).format("DD-MMM-YYYY")}</Text>
}

const ListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ButtonEditCartView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartButton {...props} isCustomLink={true} taburl="rewards" />
    <BetterEditButton {...props} isCustomLink={true} taburl="rewards" />
    <BetterDeleteButton
      {...props}
      isCustomLink={true}
      taburl="rewards"
      isNotRedirect={true}
    />
  </Flex>
)

const RaTextMerchantName = ({ record = {} }) => {
  // const [value, setValue] = useState("")
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: "merchants",
    payload: { id: get(record, "product.merchant_id", 0) },
  })
  if (!loaded) {
    return <span />
  }
  if (error) {
    return <span />
  }
  return <span>{data.name}</span>
}

const EditForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    bashPath,
    ...checkProps
  } = props
  const translate = useTranslate()
  const onShowMerchant = (v) => {
    const { history, location } = props
    history.push(`${location.pathname}/${v}`)
  }
  return (
    <BetterTabbedForm {...props}>
      <FormTab label="resources.catalogues.tabs.info">
        <MainTab {...props} translate={translate} />
      </FormTab>
      <FormTab label="resources.catalogues.tabs.rewards" path="rewards">
        <RaReferenceManyField
          addLabel={false}
          labelCreate={"resources.rewards.info.add"}
          reference="rewards"
          target="catalog_id"
          pagination={<ListPagination />}
          fullWidth
          filter={{ search: "" }}
        >
          <Datagrid>
            <TextField
              source="product.name"
              label="resources.rewards.fields.name"
            />
            <RaTextMerchantName
              source="product.merchant_id"
              label="resources.rewards.fields.merchant_name"
            />
            <TextField
              source="product.points"
              label="resources.rewards.fields.points"
            />
            <BooleanField
              source="product.active"
              label="resources.rewards.fields.active"
            />
            <ButtonEditCartView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.catalogues.tabs.merchants" path="merchants">
        <RaReferenceManyField
          addLabel={false}
          reference="merchantClients"
          target="catalog_id"
          pagination={<ListPagination />}
          fullWidth
          filter={{ search: "" }}
          notShowSearch={true}
          notShowCreate={true}
        >
          <Datagrid rowClick={onShowMerchant}>
            <ReferenceField
              source="merchant_id"
              reference="merchants"
              label="resources.catalogues.fields.merchant"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>

            <DateField
              source="start_date"
              label="resources.catalogues.fields.start_at"
            />
            <DateField
              source="end_date"
              label="resources.catalogues.fields.end_at"
            />
            {/* <ButtonEditCartView localtion={checkProps.location} /> */}
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
    </BetterTabbedForm>
  )
}

export default EditForm
