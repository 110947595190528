import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FullLoadingScreen } from "../components"
import { Form } from "./components"

const RewardEdit = props => {
  const { location } = props
  const { pathname } = location
  const check = `/rewards/`
  const n = pathname.indexOf(check)
  const record_id = pathname.substring(n + check.length, pathname.length)

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "rewards",
    payload: { id: record_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  if (error) return <div>error</div>

  return (
    <Box width={[1]}>
      <Form
        title="resources.rewards.name"
        {...props}
        sourceApi="rewards"
        typePostUrl={"UPDATE"}
        pathUrl="rewards"
        record={data}
        initialValues={data}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(RewardEdit)
