import React from "react"
import { showNotification } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { get, parseInt } from "lodash"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { Form, earningRulesDefaulValue } from "./Create"

const EarningRulesCreate = props => {
  const programme_id = get(props, "match.params.program_id", 0)
  if (programme_id !== 0) {
    earningRulesDefaulValue["loyalty_program_id"] = parseInt(programme_id)
  }
  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        title={"resources.earningRules.new_earning"}
        sourceApi="earningRules"
        typePostUrl={"CREATE"}
        pathUrl="earningRules"
        initialValues={earningRulesDefaulValue}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(EarningRulesCreate)
