import React, { Children } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MuiAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { Flex, Text } from "rebass"
import Typography from "@material-ui/core/Typography"
import { withStyles, createStyles } from "@material-ui/core/styles"
import withWidth from "@material-ui/core/withWidth"
import compose from "recompose/compose"

import {
  LoadingIndicator,
  UserMenu,
  toggleSidebar as toggleSidebarAction,
} from "react-admin"

import HeadroomCustom from "./Headroom"
import { UserContent } from "./UserContent"

const styles = theme =>
  createStyles({
    toolbar: {
      paddingRight: 0,
      height: 72,
      backgroundColor: "#0087BA",
    },
    menuButton: {
      marginLeft: "0.5em",
      marginRight: "0.5em",
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(180deg)",
    },
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  })

const AppBar = props => {
  const {
    children,
    classes,
    className,
    logo,
    logout,
    open,
    title,
    toggleSidebar,
    userMenu,
    width,
    ...rest
  } = props

  return (
    <HeadroomCustom>
      <MuiAppBar className={className} position="static" {...rest}>
        <Toolbar
          disableGutters
          variant={width === "xs" ? "regular" : "dense"}
          className={classes.toolbar}
        >
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            backgroundColor={"#0087BA"}
            style={{ color: "#FFFFFF" }}
            px={[2]}
          >
            <Flex pl={["50px"]}>
              <Text fontSize={[3]} fontWeight={"bold"}>
                Loyalty
              </Text>
            </Flex>
            <Flex
              flexDirection={"row"}
              justifyContent={"flex-end"}
              backgroundColor={"#0087BA"}
              pr={[2]}
              style={{ color: "#FFFFFF" }}
            >
              <LoadingIndicator />
              {/* {cloneElement(userMenu, { logout })} */}
              <UserContent {...props} />
            </Flex>
          </Flex>
        </Toolbar>
      </MuiAppBar>
    </HeadroomCustom>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
}

AppBar.defaultProps = {
  userMenu: <UserMenu />,
}

const enhance = compose(
  connect(null, {
    toggleSidebar: toggleSidebarAction,
  }),
  withStyles(styles),
  withWidth()
)

export default enhance(AppBar)
