import React from "react"
import { showNotification } from "react-admin"
import { withRouter } from "react-router-dom"

import { connect } from "react-redux"
import compose from "recompose/compose"
import "antd/dist/antd.css"
import { RewardDashboard } from "../dashboard"

const RewardChart = (props) => {
  return <RewardDashboard {...props} />
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(RewardChart)
