import React, { useState } from "react"
import {
  showNotification,
  useTranslate,
  useQueryWithStore,
  useNotify,
} from "react-admin"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import { Box, Flex, Text } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { lightBlue } from "@material-ui/core/colors"
import { FullLoadingScreen, ListButton } from "../components"
import {
  useStyles,
  compareDataAdd,
  SearchItems,
  TableRewardCreate,
} from "./components"

const RewardCreate = (props) => {
  const { match, location } = props
  const { catalogue_id } = match.params
  const classes = useStyles()

  const notify = useNotify()
  const translate = useTranslate()
  const queryCatalogues = useQueryWithStore({
    type: "getOne",
    resource: "catalogs",
    payload: {
      id: catalogue_id,
    },
  })
  const [catalogue, setCatalogue] = useState({})
  const [listReward, setListReward] = useState([])
  const [isData, setIsData] = useState(true)

  if (queryCatalogues.data && !catalogue.id) {
    setCatalogue(queryCatalogues.data)
  }
  // console.log(queryCatalogues)
  const newfilterProduct =
    get(catalogue, "account_id", 0) !== 0
      ? {
          catalog_id: catalogue_id,
        }
      : { catalog_id: catalogue_id }
  const queryProducts = useQueryWithStore({
    type: "getList",
    resource: "products",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: newfilterProduct,
    },
  })
  // console.log(queryProducts.data)
  let queryRewards = useQueryWithStore({
    type: "getList",
    resource: "rewards",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: {
        catalog_id: catalogue_id,
        account_id: get(catalogue, "account_id", 0),
        catalog: true,
      },
    },
  })

  const searchHandle = (data) => {
    if (data) {
      const newList = compareDataAdd(data, queryRewards.data)
      setListReward([...newList])
    }
  }

  const refreshData = (data) => {
    const objIndex = listReward.findIndex((obj) => obj.id === data.product_id)
    listReward[objIndex].isAdded = true
    setListReward([...listReward])
  }

  if (queryProducts.loading || queryRewards.loading) {
    return <FullLoadingScreen />
  }

  if (queryProducts.data && queryRewards.data) {
    if (isData) {
      const newList = compareDataAdd(queryProducts.data, queryRewards.data)
      setListReward(newList)
      setIsData(false)
    }
  }
  const { pathname } = location
  const paramsUrl = pathname.replace("/create", "")

  return (
    <Box width={[1]} p={[2]}>
      <Box width={[1]} pt={[2]} pb={[3]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"}>{translate("resources.rewards.name")}</Text>
          <ListButton
            basepath={paramsUrl}
            record={{}}
            style={{ color: lightBlue[700] }}
          />
        </Flex>
      </Box>
      <Box width={[1]} pb={[2]}>
        <Text fontWeight={"bold"} fontSize={14}>{`${translate(
          "resources.rewards.info.label_reward_1"
        )} ${get(catalogue, "name", "")} ${translate(
          "resources.rewards.info.catalogue"
        )}`}</Text>
      </Box>
      <Box width={[1]}>
        <SearchItems
          {...props}
          catalogue_id={catalogue_id}
          catalogue={catalogue}
          searchHandle={searchHandle}
          translate={translate}
        />
      </Box>
      <TableRewardCreate
        translate={translate}
        catalogue_id={catalogue_id}
        notify={notify}
        refreshData={refreshData}
        classes={classes}
        listReward={listReward}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(RewardCreate)
