import React, { Fragment } from "react"
import compose from "recompose/compose"
import ExpandMore from "@material-ui/icons/ExpandMore"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import { withStyles } from "@material-ui/core/styles"
import { translate } from "react-admin"
const styles = {
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#EFEFEF",
    "&:hover": {
      backgroundColor: "rgba(0, 175, 239, 0.2)",
    },
  },
  listItemText: {
    textAlign: "left",
    paddingLeft: 0,
    fontSize: 12,
    letterSpacing: 3,
  },
  listItemIcon: {
    padding: 0,
    minWidth: 24,
    paddingRight: 13,
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  classes,
  children,
  translate,
}) => (
  <Fragment>
    <ListItem dense button onClick={handleToggle} className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <ListItemText
        inset
        primary={isOpen ? translate(name) : ""}
        secondary={isOpen ? "" : translate(name)}
        className={classes.listItemText}
      />
    </ListItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
    </Collapse>
  </Fragment>
)

const enhance = compose(withStyles(styles), translate)

export default enhance(SubMenu)
