import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Transactions from "./Transactions"
import PointTransfers from "./PointTransfers"
import Rewards from "./Rewards"
const CardTransactions = props => {
  const [tabIndex, setTabIndex] = useState(0)

  const changeTab = (e, tabIndex) => {
    setTabIndex(tabIndex)
  }

  return (
    <Card style={{ marginBottom: 8 }}>
      <CardContent>
        <Tabs value={tabIndex} onChange={changeTab}>
          <Tab label="Transactions" />
          <Tab label="Points transfers" />
          <Tab label="Rewards" />
        </Tabs>
        {tabIndex === 0 && (
          <div>
            <Transactions {...props} />
          </div>
        )}
        {tabIndex === 1 && (
          <div>
            <PointTransfers {...props} />
          </div>
        )}
        {tabIndex === 2 && (
          <div>
            <Rewards {...props} />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default CardTransactions
