export default {
  accounts: {
    name: "Accounts",
    fields: {
      name: "Name",
      address: "Business Address",
      contact_person: "Contact Person",
      contact_email: "Contact Email",
      contact_phone: "Contact Phone",
      remarks: "Remarks",
      created_at: "Create On",
    },
    message: {
      name: "Account name, typically, put company name",
    },
  },
}
