import React from "react"
import { get } from "lodash"
import { NumberInput } from "react-admin"
import { Flex, Box, Text } from "rebass"
import { grey } from "@material-ui/core/colors"
import { PanelView, TextMessage, MultiLevelSelectInput } from "../components"

export const MultiLevelOptions = props => {
  const { formData, updateField } = props
  const sizeLevel = get(formData, "member_referral.multi_level_option.levels", [
    0,
  ])

  return (
    <PanelView
      title={"resources.earningRules.multi_level_option.title"}
      width={400}
    >
      <div>
        <MultiLevelSelectInput
          source="member_referral.multi_level_option.number_of_levels"
          label="resources.earningRules.multi_level_option.number_of_levels"
          updateField={(key, value) => updateField(key, value)}
          levels={sizeLevel}
          choices={[
            { id: 1, name: "1" },
            { id: 2, name: "2" },
            { id: 3, name: "3" },
            { id: 4, name: "4" },
            { id: 5, name: "5" },
          ]}
          style={{ minWidth: 180 }}
        />
        <Box pt={[2]} width={["300px"]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Box width={80}>
              <TextMessage
                notetext={"resources.earningRules.multi_level_option.level"}
              />
            </Box>
            <TextMessage
              notetext={"resources.earningRules.multi_level_option.percent"}
            />
          </Flex>
          <Box>
            {sizeLevel.map((item, index) => {
              return (
                <Box key={index}>
                  <Flex
                    width={[1]}
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    key={index}
                  >
                    <Box width={80}>
                      <Text fontSize={14} pt={[3]} color={grey[700]}>
                        {index + 1}
                      </Text>
                    </Box>
                    <Box>
                      <NumberInput
                        source={`member_referral.multi_level_option.levels[${index}]`}
                        label={""}
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          padding: 0,
                          width: 90,
                        }}
                      />
                    </Box>
                  </Flex>
                </Box>
              )
            })}
          </Box>
        </Box>
      </div>
    </PanelView>
  )
}
