import React from "react"
import {
  List,
  Datagrid,
  Edit,
  Create,
  Toolbar,
  SaveButton,
  Show,
} from "react-admin"
import { withStyles } from "@material-ui/core/styles"
import { SimpleOlehForm, TabbedOlehForm, CustomSimpleOlehForm } from "./form"
import { useStyles, useStylesTable, stylesFormContainer } from "./utils"

const betterRowStyle = (record, index) => ({
  borderBottomWidth: 0,
  borderWidth: 0,
  padding: 0,
})

export const BetterList = (props) => {
  const { classes: classesOverride } = props
  const classes = useStyles({ classes: classesOverride })
  const classesT = useStylesTable({ classes: classesOverride })
  return (
    <List bulkActionButtons={false} {...props} classes={classes}>
      <Datagrid rowStyle={betterRowStyle} classes={classesT}>
        {props.children}
      </Datagrid>
    </List>
  )
}

export const BetterSimpleForm = (props) => {
  const {
    staticContext,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...checkProps
  } = props
  return (
    <SimpleOlehForm {...checkProps} redirect="list" submitOnEnter={false} />
  )
}
export const BetterCustomSimpleForm = (props) => {
  return <CustomSimpleOlehForm {...props} submitOnEnter={false} />
}

export const BetterCustomForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props
  return (
    <SimpleOlehForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  )
}

export const BetterTabbedForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    permissions,
    ...checkProps
  } = props

  return (
    <TabbedOlehForm
      {...checkProps}
      redirect="list"
      submitOnEnter={false}
      permissions={permissions}
    />
  )
}
export const BetterCreate = withStyles(stylesFormContainer)((props) => {
  const { classes, permissions } = props
  return <Create {...props} permissions={permissions} classes={classes} />
})
export const BetterShow = withStyles(stylesFormContainer)((props) => {
  const { classes } = props
  return <Show {...props} classes={classes} />
})

export const BetterEdit = withStyles(stylesFormContainer)((props) => {
  // console.log(props)
  const {
    classes,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    permissions,
    ...checkProps
  } = props

  return (
    <Edit
      {...checkProps}
      undoable={false}
      classes={classes}
      permissions={permissions}
    />
  )
})
export const EditToolbar = (props) => (
  <Toolbar {...props} style={{ paddingLeft: 16 }}>
    <SaveButton redirect={props.redirect} />
  </Toolbar>
)
