import React, { Component } from "react"
import { sum, get } from "lodash"
import { Flex, Box } from "rebass"
import { DatePicker } from "antd"
import * as Moment from "moment"
import { extendMoment } from "moment-range"
import {
  BoxTitleField,
  HeaderField,
  ChartView,
  TitleRangePicker,
} from "./components"
import { RestApi } from "../restClient"
import "antd/dist/antd.css"
const moment = extendMoment(Moment)

const { RangePicker } = DatePicker

class RewardDashboard extends Component {
  constructor(props) {
    super(props)
    const defaultStart = moment().add(-7, "days")
    this.state = {
      start: defaultStart,
      end: moment(),
      dailyNewMember: [],
      label: [],
      dataSet: [],
      dataSetRedemption: [],
      dataSetConsumption: [],
      totalRedemption: 0,
      totalConsumption: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getNewListDaily(this.state.start, this.state.end)
  }
  getNewListDaily = (start, end) => {
    const { match } = this.props
    const newStart = moment(start).format("YYYY-MM-DD")
    const newEnd = moment(end).format("YYYY-MM-DD")
    const url = `/rewards/${get(
      match,
      "params.id",
      0
    )}/dashboard?start_date=${newStart}&end_date=${newEnd}`
    // console.log(url)
    RestApi(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data && data.length > 0) {
          const sortedArray = data.sort(
            (a, b) =>
              new moment(a.register_date).format("YYYYMMDD") -
              new moment(b.register_date).format("YYYYMMDD")
          )
          const dataSetRedemption = sortedArray.map((item) => item.redemption)
          const dataSetConsumption = sortedArray.map((item) => item.consumption)
          const newFormat = sortedArray.map((item) =>
            moment(item.date).format("DD-MM-YYYY")
          )
          this.setState({
            dailyNewMember: data,
            label: newFormat,
            dataSetRedemption: dataSetRedemption,
            dataSetConsumption: dataSetConsumption,
            totalConsumption: sum(dataSetConsumption),
            totalRedemption: sum(dataSetRedemption),
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  onHandleChange = (value) => {
    if (value !== null) {
      if (value[0]) {
        this.setState({
          start: value[0],
        })
      }
      if (value[1]) {
        this.setState({
          end: value[1],
        })
      }
      this.getNewListDaily(this.state.start, this.state.end)
    }
  }
  render() {
    return (
      <Box width={[1]} pt={[3]}>
        <HeaderField {...this.props} resource={"rewards"} title={"rewards"} />
        <TitleRangePicker />
        <Box width={[1]}>
          <RangePicker
            placeholder={["Start", "End"]}
            allowClear={false}
            format="DD-MMM-YYYY"
            onChange={this.onHandleChange}
            defaultValue={[this.state.start, this.state.end]}
          />
        </Box>
        <BoxTitleField
          {...this.props}
          totalRedemption={this.state.totalRedemption}
          totalConsumption={this.state.totalConsumption}
        />
        <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
          <Box width={["86%"]}>
            <ChartView
              {...this.props}
              labels={this.state.label}
              datasets={this.state.dataSet}
              dataSetRedemption={this.state.dataSetRedemption}
              dataSetConsumption={this.state.dataSetConsumption}
            />
          </Box>
        </Flex>
      </Box>
    )
  }
}
export default RewardDashboard
