import React from "react"
import { Box } from "rebass"
import Paper from "@material-ui/core/Paper"
import { RaTextInput } from "../../components"
import { TagsInput } from "./TagsFields"

export const TagsRules = props => {
  const { classes, translate } = props
  return (
    <Paper className={classes.paper}>
      <Box pb={[2]}>
        <RaTextInput
          source="rules.tags_inclusion_type"
          label={"resources.catalogues.fields.tag_inclusion_exclusion"}
          notetext={"resources.catalogues.message.tag_inclusion_exclusion"}
        />
      </Box>
      <Box>
        <TagsInput
          {...props}
          source="rules.tags"
          label={translate("resources.catalogues.fields.tags")}
          notetext={"resources.catalogues.message.tags"}
          isUpdateField={true}
        />
      </Box>
    </Paper>
  )
}
