import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import { has } from "lodash"
import compose from "recompose/compose"
import { Form } from "./components"
import { FullLoadingScreen } from "../components"
import { getProfile } from "../utils"

const ProductCreate = props => {
  const { match } = props
  const profile = getProfile()
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "merchants",
    payload: { id: match.params.merchant_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const account_id = has(data, "account_id")
    ? { account_id: data.account_id }
    : has(profile, "account_id")
    ? { account_id: profile.account_id }
    : {}
  const dummyData = {
    merchant_id: data && data.id ? data.id : parseInt(match.params.merchant_id),
    points: 0,
    value: 0,
    expiry_days: 0,
    merchant_offer: {
      cost: 0,
      price: 0,
      margin: 0,
      reward_validaty: 0,
    },
    ...account_id,
  }
  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        sourceApi="products"
        typePostUrl={"CREATE"}
        pathUrl="products"
        initialValues={dummyData}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(ProductCreate)
