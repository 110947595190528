import React from "react"
import { get } from "lodash"
import {
  required,
  SelectInput,
  ReferenceInput,
  useTranslate,
} from "react-admin"
import { Box, Text, Flex } from "rebass"
import { RaTextInput, RaBooleanInput, RaTextArea } from "../../components"
import { ROLE_USER } from "../../utils"
import { listPaymentTerm, payment_mode } from "../Create"

const MainTab = ({ permissions, showAccount, ...props }) => {
  const translate = useTranslate()
  return (
    <Box width={[1]} p={[2]}>
      <RaBooleanInput source="active" id={"active"} />
      {permissions === ROLE_USER.super_admin && showAccount && (
        <ReferenceInput
          label="Account"
          source="account_id"
          reference="accounts"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      <RaTextInput
        source="name"
        validate={required()}
        notetext="resources.accounts.message.name"
        style={{ width: 256 }}
      />

      <RaTextInput
        source="uen"
        labek="resources.merchants.fields.uen"
        style={{ width: 256 }}
      />

      <RaTextInput
        source="address"
        label="resources.merchants.fields.address"
        style={{ width: 500 }}
        multiline
      />
      <RaTextInput
        source="contact.name"
        label="resources.merchants.fields.contact_person"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.email"
        label="resources.merchants.fields.email"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.phone"
        label="resources.merchants.fields.phone"
        style={{ width: 500 }}
      />

      <SelectInput
        source="payment.terms"
        label="resources.merchants.fields.payment_terms"
        choices={listPaymentTerm()}
        margin="dense"
        style={{ width: 180 }}
      />
      <Box>
        <SelectInput
          source="payment.mode"
          label="resources.merchants.fields.payment_mode"
          choices={payment_mode}
          margin="dense"
          style={{ width: 180 }}
        />
      </Box>

      <RaTextInput
        source="website"
        label="resources.merchants.fields.website"
        style={{ width: 500 }}
      />
      <RaTextArea
        source="remarks"
        label="resources.merchants.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      {!showAccount && (
        <Box width={[1]}>
          <Flex flexDirection={"row"} alignItems={"center"} pb={[2]}>
            <Text fontSize={12} pr={[2]} fontWeight={"bold"} width={140}>
              {translate("resources.merchants.fields.api_settings.app_id")}
            </Text>
            <Text fontSize={12}>
              {get(props, "record.api_settings.app_id", "")}
            </Text>
          </Flex>
          <Flex flexDirection={"row"} alignItems={"center"}>
            <Text fontSize={12} pr={[2]} fontWeight={"bold"} width={140}>
              {translate("resources.merchants.fields.api_settings.app_secret")}
            </Text>
            <Text fontSize={12}>
              {get(props, "record.api_settings.app_secret", "")}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default MainTab
