import React from "react"
import { required, useTranslate, SelectInput } from "react-admin"
import { Box } from "rebass"
import { timeZoneList } from "../../components/timezone"
import {
  RaTextInput,
  RaBooleanInput,
  RaTextArea,
  RaNumberInput,
  RTDescription,
  // RaReferenceArrayInput,
} from "../../components"

const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["blockquote"],
  ["clean"],
  ["image"],
]
// import { ROLE_USER } from "../../utils"

const MainTab = (props) => {
  const translate = useTranslate()
  // const { account_id, permissions } = props

  return (
    <Box width={[1]}>
      <RaBooleanInput source="active" id={"active"} />
      {/* <Box>
        {permissions !== ROLE_USER.client_admin && (
          <ReferenceInput
            source="client_id"
            reference="clients"
            filter={{ account_id: account_id }}
          >
            <SelectInput optionText="name" style={{ minWidth: 200 }} />
          </ReferenceInput>
        )}
      </Box> */}
      <RaTextInput
        source="name"
        label="resources.programmes.fields.loyalty_program_name"
        validate={required()}
        style={{ minWidth: 300 }}
        notetext="resources.programmes.message.loyalty_program_name"
      />
      {/* <RaTextInput
        source="loyalty_program_name"
        validate={required()}
        style={{ minWidth: 300 }}
        notetext="resources.programmes.message.loyalty_program_name"
      /> */}
      <RaTextInput
        source="currency"
        label="resources.programmes.fields.currency_code"
        notetext="resources.programmes.message.currency_code"
      />
      <RaTextInput
        source="points_singular"
        label="resources.programmes.fields.points_singular"
        notetext="resources.programmes.message.points_singular"
      />
      <RaTextInput
        label="resources.programmes.fields.points_plural"
        source="points_plural"
        notetext="resources.programmes.message.points_plural"
      />
      <RaNumberInput
        label="resources.programmes.fields.points_expiry"
        source="points_expiry"
        notetext="resources.programmes.message.points_expiry"
      />
      <SelectInput
        source="timezone"
        label="resources.programmes.fields.timezone"
        choices={timeZoneList}
        style={{ width: 100 }}
      />
      {/* resource sometime undefined, change with resource1 */}
      {/* <RaReferenceArrayInput
        label="resources.programmes.fields.catalogues"
        source="catalogues"
        reference="catalogues"
        resource="programmes"
        resource1="programmes"
        notetext="resources.programmes.message.catalogues"
      >
        <SelectArrayInput optionText="name" style={{ width: 500 }} />
      </RaReferenceArrayInput> */}
      <RTDescription
        source="description"
        label={translate("resources.programmes.fields.description")}
        toolbar={toolbar}
        options={{ resource: "products" }}
        key="Description"
      />
      <RTDescription
        source="contact_us"
        label={translate("resources.programmes.fields.contact_us")}
        toolbar={toolbar}
        options={{ resource: "products" }}
        key="contact_us"
      />

      <RaTextArea
        source="remarks"
        label="resources.programmes.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Box>
  )
}

export default MainTab
