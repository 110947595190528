import React from "react"
import { get } from "lodash"
import {
  SelectInput,
  NumberInput,
  FormDataConsumer,
  required,
  useTranslate,
} from "react-admin"
import Paper from "@material-ui/core/Paper"
import { MultiLevelOptions } from "../FieldItems"
import { DataDefaultForm } from "./DataDefaultForm"
import { CollapseMui } from "../../components"

export const MemberReferral = props => {
  const translate = useTranslate()
  if (props.record && !props.record.member_referral) {
    props.record.member_referral = {
      event_name: "",
      reward: "",
      points: 0,
      multi_level_option: {
        number_of_levels: 1,
        levels: [0],
      },
    }
  }

  return (
    <div>
      <div>
        <SelectInput
          label="resources.earningRules.fields.member_referral.event_name"
          source="member_referral.event_name"
          choices={[
            { id: "Every Purchase", name: "Every Purchase" },
            { id: "First Purchase", name: "First Purchase" },
            { id: "Register", name: "Register" },
          ]}
          validate={required()}
          style={{ minWidth: 180 }}
        />
      </div>
      <div>
        <SelectInput
          label="resources.earningRules.fields.member_referral.reward"
          source="member_referral.reward"
          choices={[
            { id: "Both", name: "Both" },
            { id: "Referred", name: "Referred" },
            { id: "Referrer", name: "Referrer" },
            { id: "Multi-Level", name: "Multi-Level" },
          ]}
          validate={required()}
          style={{ minWidth: 180 }}
        />
      </div>

      <div>
        <FormDataConsumer>
          {({ formData, translate, ...rest }) => {
            const reward = get(formData, "member_referral.reward", undefined)
            return (
              reward &&
              reward === "Multi-Level" && (
                <MultiLevelOptions
                  updateField={props.updateField}
                  formData={formData}
                  translate={translate}
                  {...rest}
                />
              )
            )
          }}
        </FormDataConsumer>
      </div>
      <div>
        <NumberInput
          label="resources.earningRules.fields.member_referral.points"
          source="member_referral.points"
          validate={required()}
        />
      </div>

      <CollapseMui
        label={translate("resources.earningRules.title.advanced_options")}
      >
        <Paper typepaper={"paper"} style={{ marginTop: 0, padding: 16 }}>
          <DataDefaultForm {...props} />
        </Paper>
      </CollapseMui>
    </div>
  )
}
