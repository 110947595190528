import React, { Component } from "react"
import PropTypes from "prop-types"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { createMuiTheme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
// import Divider from "@material-ui/core/Divider"
import { Flex, Image, Text } from "rebass"
import CardActions from "@material-ui/core/CardActions"
import { Notification } from "react-admin"
import LoginButton from "./LoginButton"
import { loginClientJWT, TOKEN, PROFILE, ROLE } from "../restClient"
const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0087BA",
  },
  card: {
    minWidth: 550,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    borderRadius: 16,
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
}

export default class Login extends Component {
  componentDidMount() {
    if (window.location.href.indexOf("?token=") >= 0) {
      return loginClientJWT(
        JSON.stringify({
          token: window.location.href.substring(
            window.location.href.indexOf("?token=") + 7
          ),
        })
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response)
          localStorage.setItem(TOKEN, response.token)
          localStorage.setItem(PROFILE, JSON.stringify(response.profile))
          localStorage.setItem(ROLE, response.profile.role)
          window.location.href = "/"
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
  render() {
    const { theme } = this.props
    const muiTheme = createMuiTheme(theme)

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Flex
          width={[1]}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          style={{ backgroundColor: "#0087BA", height: "100vh" }}
        >
          <Card style={styles.card}>
            <Flex
              width={[1]}
              flexDirection={"column"}
              alignItems={["center"]}
              justifyContent={"center"}
              paddingTop={[1]}
              paddingBottom={[3]}
            >
              <Image src={require("../assets/images/e-buy.svg")} height={160} />
              <Flex pb={[2]} />
              {/* <Divider
                style={{
                  backgroundColor: "#00AEEF",
                  width: "100%",
                  height: 3,
                }}
                variant={"middle"}
                orientation={"horizontal"}
              /> */}
              <Text
                fontSize={"24px"}
                pt={[2]}
                fontWeight={"bold"}
                style={{ color: "#00AEEF", letterSpacing: "2px" }}
              >
                Loyalty
              </Text>
            </Flex>

            <CardActions>
              <LoginButton />
            </CardActions>
          </Card>
          <Notification />
        </Flex>
      </MuiThemeProvider>
    )
  }
}

Login.propTypes = {
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

Login.defaultProps = {
  theme: {},
}
