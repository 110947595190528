import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin"

import { loginClient, TOKEN, PROFILE, ROLE } from "./restClient"

export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN)
  localStorage.removeItem(PROFILE)
  localStorage.removeItem(ROLE)
}

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    // console.log("AUTH_LOGIN", params)
    if (params.tokenId && params.accessToken) {
      return loginClient(JSON.stringify(params))
        .then(response => response.json())
        .then(response => {
          // console.log(response)
          localStorage.setItem(TOKEN, response.token)
          localStorage.setItem(PROFILE, JSON.stringify(response.profile))
          localStorage.setItem(ROLE, response.profile.role)
          return Promise.resolve()
        })
        .catch(e => {
          console.error(e)
          return Promise.reject()
        })
    }
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    clearLocalStorage()
    // console.log("masuk logout", params)
    return Promise.resolve()
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      clearLocalStorage()
      return Promise.reject()
    }
    return Promise.resolve()
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const { resource } = params

    if (
      resource === "registration" ||
      resource === "setup" ||
      resource === "approved"
    ) {
      // check credentials for the posts resource
      // console.log(params)
      return Promise.resolve()
    }

    return localStorage.getItem(TOKEN) ? Promise.resolve() : Promise.reject()
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem(ROLE)
    return Promise.resolve(role)
  }
  return Promise.reject("Unknown method")
}
