import React from "react"
import {
  SaveButton,
  Toolbar,
  TextInput,
  useTranslate,
  ReferenceInput,
  SelectInput,
  DateInput,
} from "react-admin"
import { Flex } from "rebass"
import { get } from "lodash"
import { RaBooleanInput, BetterCustomSimpleForm } from "../../components"
import TagsInput from "../tagsInput"
import { formatDate } from "../../utils"

const MemberToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const MainFields = (props) => {
  const { record } = props
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaBooleanInput source="active" />
      <TextInput source="email" type="email" style={{ width: 400 }} />
      <TextInput source="phone" style={{ width: 400 }} />
      <TextInput source="first_name" style={{ width: 400 }} />
      <TextInput source="last_name" style={{ width: 400 }} />
      <SelectInput
        source="gender"
        choices={[
          { id: "male", name: "Male" },
          { id: "female", name: "Female" },
        ]}
        style={{ width: 300 }}
      />
      <DateInput source="birthdate" style={{ width: 300 }} parse={formatDate} />
      <ReferenceInput
        source="level_id"
        reference="levels"
        filter={{
          active: true,
          loyalty_program_id: get(record, "loyalty_program_id", 0),
        }}
      >
        <SelectInput style={{ width: 300 }} />
      </ReferenceInput>
      <TagsInput {...props} source="tags" resource={"members"} />
      <TextInput source="reference_id" />
    </Flex>
  )
}

export const FormFields = (props) => {
  const translate = useTranslate()
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.members.name"}
      toolbar={<MemberToolbar />}
    >
      <MainFields {...props} translate={translate} />
    </BetterCustomSimpleForm>
  )
}
