import React from "react"
import { showNotification, useGetOne } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { Form } from "./components"
import { FullLoadingScreen } from "../components"

const ProductEdit = props => {
  const { location } = props
  const { pathname } = location
  const check = `/products/`
  const n = pathname.indexOf(check)
  const record_id = pathname.substring(n + check.length, pathname.length)
  const { data, loading, error } = useGetOne("products", record_id)

  if (loading) {
    return <FullLoadingScreen />
  }
  if (error) return <div>error</div>

  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        sourceApi="products"
        typePostUrl={"UPDATE"}
        pathUrl="products"
        record={data}
        initialValues={data}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(ProductEdit)
