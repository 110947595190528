import React from "react"
import {
  Datagrid,
  DateField,
  FormTab,
  TextField,
  BooleanField,
  EmailField,
  NumberField,
} from "react-admin"

import { Flex } from "rebass"
import { Pagination } from "react-admin"
import {
  BetterEditButton,
  BetterUserButton,
  BetterTabbedForm,
  RaReferenceManyField,
  BetterChartButton,
} from "../../components"
import MainTab from "./MainTab"
import SettingsTab from "./SettingsTab"

const ListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ButtonEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterEditButton {...props} isCustomLink={true} taburl="earningRules" />
  </Flex>
)
const ButtonEditCatalogView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartButton {...props} isCustomLink={true} taburl="catalog" />
    <BetterEditButton {...props} isCustomLink={false} taburl="catalog" />
  </Flex>
)
const ButtonEditLevelView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterUserButton {...props} isCustomLink={true} taburl="levels" />
    <BetterEditButton {...props} isCustomLink={true} taburl="levels" />
  </Flex>
)

const ButtonEditMembersView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterUserButton
      {...props}
      isCustomLink={true}
      taburl="members"
      customUrl="profile"
    />
    <BetterEditButton {...props} isCustomLink={true} taburl="members" />
  </Flex>
)

const PriceField = ({ record = {} }) => (
  <span>{record.cost + record.margin}</span>
)
PriceField.defaultProps = {
  label: "Price",
}

const defaultNewInitialValue = {
  app_cloud_settings: {
    logo: "https://admin.staging.bcxstudio.com/static/media/logo.a602cd23.png",
    colors: {
      text: "#626163",
      background: "#EFEFEF",
      primary: "#6B5B37",
      secondary: "#C3C0B6",
      highlight: "#F1E656",
      accent: "#E23098",
      accent1: "#4A90E2",
      accent2: "#3F5498",
      muted: "#F2F1EF",
      white: "#FFFFFF",
      black: "#000000",
    },
    help_text:
      "You can sign up with your Google login or using your mobile number",
    login_google: true,
    login_instagram: false,
    login_mobile: true,
    login_facebook: false,
  },
}

const EditForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    bashPath,
    ...checkProps
  } = props

  return (
    <BetterTabbedForm {...props} initialValues={defaultNewInitialValue}>
      <FormTab label="resources.programmes.tabs.programme">
        <MainTab {...props} />
      </FormTab>

      <FormTab
        label="resources.programmes.tabs.earning_rules"
        path="earningRules"
      >
        <RaReferenceManyField
          addLabel={false}
          reference="earningRules"
          target="loyalty_program_id"
          pagination={<ListPagination />}
          fullWidth
          notShowSearch={true}
          filter={{ q: "" }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="type" />
            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <BooleanField source="active" />
            <ButtonEditView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.programmes.tabs.catalog" path="catalog">
        <RaReferenceManyField
          addLabel={false}
          reference="catalogues"
          target="loyalty_program_id"
          fullWidth
          notShowSearch={true}
          pagination={<ListPagination />}
        >
          <Datagrid style={{ width: "100%" }}>
            <TextField source="name" />
            <BooleanField source="active" />
            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <ButtonEditCatalogView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.programmes.tabs.members" path="members">
        <RaReferenceManyField
          addLabel={false}
          reference="members"
          target="loyalty_program_id"
          fullWidth
          notShowSearch={true}
          pagination={<ListPagination />}
        >
          <Datagrid style={{ width: "100%" }}>
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <NumberField source="points" />

            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <BooleanField source="active" />
            <ButtonEditMembersView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.programmes.tabs.levels" path="levels">
        <RaReferenceManyField
          addLabel={false}
          reference="levels"
          target="loyalty_program_id"
          fullWidth
          notShowSearch={true}
          pagination={<ListPagination />}
        >
          <Datagrid style={{ width: "100%" }}>
            <TextField source="name" />
            <BooleanField source="active" />
            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <ButtonEditLevelView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.programmes.tabs.settings" path="settings">
        <SettingsTab {...props} />
      </FormTab>
    </BetterTabbedForm>
  )
}

export default EditForm
