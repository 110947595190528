import polyglotI18nProvider from "ra-i18n-polyglot"
import customEnglishMessages from "./en"
// const messages={
//   en: { ...englishMessages, ...customEnglishMessages }
// }
// export default locale => (messages["en"]);

export default polyglotI18nProvider(locale => {
  if (locale === "fr") {
    return import("./en").then(messages => messages.default)
  }

  // Always fallback on english
  return customEnglishMessages
}, "en")
