import jsonServerRestClient from "ra-data-json-server"
import { fetchUtils } from "react-admin"

export const API_DEBUG = "http://localhost:4002/admin"
export const API_SERVER = process.env.REACT_APP_SERVER || API_DEBUG
export const API_REPORT = API_SERVER.replace("/admin", "")
// console.log(API_SERVER)

export const TOKEN = "token_loyalty_admin_bcx"
export const PROFILE = "profile_loyalty_admin_bcx"
export const ROLE = "role_loyalty_admin_bcx"
export const NOAUTHPAGE = "no_auth_page_loyalty_admin_bcx"

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    })
  }
  const token = localStorage.getItem(TOKEN)
  options.headers.set("Authorization", `Bearer ${token}`)

  //can change url in here

  const resource = url.replace(API_SERVER, "")
  // console.log(resource)
  // console.log(url)
  if (resource.includes("programmes")) {
    url = url.replace("programmes", "loyaltyPrograms")
  } else if (resource.includes("catalogues")) {
    url = url.replace("catalogues", "catalogs")
  } else if (resource.includes("register")) {
    url = url.replace("register", "herokuProvisions")
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Pending review")}`
    }
  } else if (resource.includes("provisions")) {
    url = url.replace("provisions", "herokuProvisions")
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Approved")}`
    }
  }
  return fetchUtils.fetchJson(url, options)
}

export const dataProvider = jsonServerRestClient(API_SERVER, httpClient)

export const UploadClient = (formData, path) => {
  var myHeaders = new Headers()
  const token = localStorage.getItem(TOKEN)
  myHeaders.append("Authorization", `Bearer ${token}`)
  return fetch(API_SERVER + path, {
    method: "POST",
    body: formData,
    headers: myHeaders,
  })
}

export const RestApi = (urlParams) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" })
  const token = localStorage.getItem(TOKEN)
  myHeaders.append("Authorization", `Bearer ${token}`)
  const url = API_SERVER + urlParams
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  })
}
export const RestJsonReports = (urlParams) => {
  let options = {}
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  const token = localStorage.getItem(TOKEN)
  options.headers.set("Authorization", `Bearer ${token}`)
  options.method = "GET"
  const url = API_SERVER + urlParams
  //"/reports/orders";
  return fetch(url, options)
}
export const RestApiReward = (urlParams, method = "GET", params) => {
  let options = {}
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" })
  }
  const token = localStorage.getItem(TOKEN)
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`)
  }

  options.method = method
  if (params) {
    options.body = JSON.stringify(params)
  }
  const url = `${API_SERVER}/${urlParams}`
  return fetch(url, options)
}

export const loginClient = (params) => {
  const url = API_SERVER + "/sessions"
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  })
}
export const loginClientJWT = (params) => {
  const url = API_SERVER + "/../mc/sessions/token"
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  })
}

export const updateLogin = (response) => {
  localStorage.setItem(TOKEN, response.token)
  localStorage.setItem(PROFILE, JSON.stringify(response.profile))
  localStorage.setItem(ROLE, response.profile.role)
}
