import React from "react"
import { Box } from "rebass"
import Paper from "@material-ui/core/Paper"
import { NumberInput, required, useTranslate } from "react-admin"
import { RaSelectArrayChipInput, CollapseMui } from "../../components"

import { DataDefaultForm } from "./DataDefaultForm"

export const MultiplyEarnedPoints = props => {
  const translate = useTranslate()

  if (props.record && !props.record.multiply_earned_points) {
    props.record.multiply_earned_points = {
      skus: [],
      categories: [],
    }
  }
  return (
    <div>
      <RaSelectArrayChipInput
        label="resources.earningRules.fields.multiply_earned_points.skus"
        source="multiply_earned_points.skus"
        fullWidth={true}
      />
      <RaSelectArrayChipInput
        label="resources.earningRules.fields.multiply_earned_points.categories"
        source="multiply_earned_points.categories"
        fullWidth={true}
      />
      <NumberInput
        label="resources.earningRules.fields.multiply_earned_points.multiplier"
        source="multiply_earned_points.multiplier"
        validate={required()}
      />
      <br />
      <NumberInput
        label="resources.earningRules.fields.multiply_earned_points.min_order_value"
        source="multiply_earned_points.min_order_value"
        notetext={"resources.earningRules.notetext.min_spending"}
        validate={required()}
      />
      <CollapseMui
        label={translate("resources.earningRules.title.advanced_options")}
      >
        <Paper typepaper={"paper"} style={{ marginTop: 0, padding: 16 }}>
          <Box width={[1]}>
            <DataDefaultForm {...props} />
          </Box>
        </Paper>
      </CollapseMui>
    </div>
  )
}
