import rawTimeZones from "./rawTimeZones"
export { default as rawTimeZones } from "./rawTimeZones"

export const tz = rawTimeZones.map((item) => {
  const myvalue = item.rawOffsetInMinutes / 60
  const isDecimal = myvalue % 1 !== 0
  let result = myvalue
  if (isDecimal) {
    if (myvalue - parseInt(myvalue) === 0.5) {
      result = myvalue - 0.2
    } else if (myvalue - parseInt(myvalue) === -0.5) {
      result = myvalue + 0.2
    } else if (myvalue - parseInt(myvalue) === 0.75) {
      result = myvalue - 0.3
    } else if (myvalue - parseInt(myvalue) === -0.75) {
      result = myvalue + 0.3
    }
  }
  const myGMT = result > 0 ? `GMT+${result}` : `GMT${result}`
  return {
    text: myGMT,
    value: result,
    rawOffsetInMinutes: item.rawOffsetInMinutes,
  }
})

export { default as timeZoneList } from "./timezonelist"
