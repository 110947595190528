import { createMuiTheme } from "@material-ui/core/styles"

import { red } from "@material-ui/core/colors"
import { bcx, bcx_secondary_orange } from "../app"
export const themeRaDateTIme = createMuiTheme({
  palette: {
    primary: bcx,
    secondary: bcx_secondary_orange,
    error: red,
  },
  typography: {
    fontFamily: ["SalesforceSans, sans-serif"].join(","),
    fontSize: 12,
    useNextVariants: true,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
      marginNormal: {
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        paddingTop: 2,
        paddingBottom: 8,
      },
      inputMultiline: {
        paddingTop: 2,
        paddingBottom: 2,
      },
      input: {
        padding: "10px 2px 2px",
        fontSize: 12,
      },
      multiline: {
        padding: "10px 2px 2px",
        fontSize: 12,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        paddingTop: 2,
        paddingBottom: 4,
        padding: "10px 2px 2px",
      },
      input: {
        padding: "20px 2px 2px",
        fontSize: 12,
      },
      multiline: {
        padding: "10px 2px 2px",
        fontSize: 12,
      },
    },
  },
})
