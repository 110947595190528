export default {
  menus: {
    accounts: "Accounts",
    catalogues: "Catalogues",
    clients: "Clients",
    members: "Members",
    merchants: "Merchants",
    admin: "Admin",
    registrations: "Registrations",
    provisions: "Provisions",
    users: "Users",
    dashboard: "Dashboard",
    programmes: "Programmes",
    loyalty: "Loyalty",
    download_report: "Download Report",
  },
}
