import { PROFILE } from "./restClient"

import moment from "moment"

export const formatDate = (v) => {
  return moment(v).toISOString()
}

export const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`

export function jsUcfirst(string) {
  return string && string !== ""
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : ""
}
export const ROLE_USER = {
  super_admin: "Super Admin",
  loyalty_program_admin: "Loyalty Program Admin",
  loyalty_program_user: "Loyalty Program User",
  merchant: "Merchant",
  account_admin: "Account Admin",
  client_admin: "Client Admin",
}

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g
let classCounter = 0

// Heavily inspired of Material UI:
// @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js
// The issue with the MUI function is that is create a new index for each
// new `withStyles`, so we handle have to write our own counter
export const generateClassName = (rule, styleSheet) => {
  classCounter += 1

  if (process.env.NODE_ENV === "production") {
    return `c${classCounter}`
  }

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix
    // Sanitize the string as will be used to prefix the generated class name.
    prefix = prefix.replace(escapeRegex, "-")

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`
    }

    return `${prefix}-${rule.key}-${classCounter}`
  }

  return `${rule.key}-${classCounter}`
}

export const getProfile = () => {
  const profile = localStorage.getItem(PROFILE)
  if (profile && profile !== null && profile !== "") {
    const parseProfile = JSON.parse(profile)
    return parseProfile
  }

  return false
}
