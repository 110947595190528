/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useInput, FieldTitle } from "ra-core"
import {
  KeyboardDatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/core/styles"
import MomentUtils from "@date-io/moment"
import { themeRaDateTIme } from "./theme"

const Picker = ({ PickerComponent, ...fieldProps }) => {
  const { options, label, source, resource, className, isRequired } = fieldProps

  const { input, meta } = useInput({ source })

  const { touched, error } = meta

  const handleChange = useCallback((value) => {
    Date.parse(value)
      ? input.onChange(value.toISOString())
      : input.onChange(null)
  }, [])

  return (
    <ThemeProvider theme={themeRaDateTIme}>
      <div className="picker">
        <PickerComponent
          {...options}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
          value={input.value ? new Date(input.value) : null}
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            input.onBlur(
              input.value ? new Date(input.value).toISOString() : null
            )
          }
        />
      </div>
    </ThemeProvider>
  )
}

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    utils: MomentUtils,
    locale: undefined,
  },
}

export const RaDateInput = (props) => (
  <Picker PickerComponent={KeyboardDatePicker} {...props} />
)
export const TimeInput = (props) => (
  <Picker PickerComponent={TimePicker} {...props} />
)
export const DateTimeInput = (props) => (
  <Picker PickerComponent={DateTimePicker} {...props} />
)
