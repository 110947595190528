import React from "react"
import { required } from "react-admin"
import InputLabel from "@material-ui/core/InputLabel"
import { Box, Flex } from "rebass"
import { useTranslate, SaveButton, Toolbar } from "react-admin"
import {
  RaTextInput,
  RaBooleanInput,
  RaTextArea,
  ImageOlaInput,
  BetterCustomSimpleForm,
} from "../../components"
import RaDateTimeInput from "../../components/ra-field/RaDateTimeInput"
import { CollapsePaper } from "./"

const CatalogToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const MainFields = (props) => {
  const { translate } = props
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaBooleanInput source="active" id={"active"} />
      <RaTextInput source="name" validate={required()} />
      <Box width={[1]} pt={[3]}>
        <InputLabel>
          {translate("resources.catalogues.fields.publish_date")}
        </InputLabel>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box pr={[4]}>
            <RaDateTimeInput
              source="start_at"
              style={{ width: 180 }}
              label=""
              notetext="resources.catalogues.message.date_start"
              validate={required()}
            />
          </Box>
          <Box>
            <RaDateTimeInput
              source="end_at"
              label=""
              style={{ width: 180 }}
              notetext="resources.catalogues.message.date_end"
              validate={required()}
            />
          </Box>
        </Flex>
      </Box>
      <RaTextArea
        source="description"
        label="resources.catalogues.fields.description"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <RaTextArea
        source="terms"
        label="resources.catalogues.fields.terms"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <Box width={[1]}>
        <ImageOlaInput
          source="banners"
          toValue="banners"
          labelImage={translate("resources.catalogues.fields.banner")}
          label={"resources.catalogues.fields.banner"}
          accept="image/*"
          key="banners"
          {...props}
        />
      </Box>
      <CollapsePaper {...props} translate={translate} />
    </Flex>
  )
}

export const FormFields = (props) => {
  const translate = useTranslate()
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.catalogues.name"}
      toolbar={<CatalogToolbar />}
    >
      <MainFields {...props} translate={translate} />
    </BetterCustomSimpleForm>
  )
}
