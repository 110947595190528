import React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { has } from "lodash"
import { MyTab } from "./MyTab"
import { makeStyles } from "@material-ui/core/styles"
import MCForm from "./MCForm"

export const useStyles = makeStyles((theme) => ({
  expand: {
    padding: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  paper: {
    width: "100%",
  },
}))

const MCEditToolbar = (props) => {
  console.log(props)
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

export const Form = (props) => {
  const { match } = props
  const { params } = match
  const filterMerchant = has(props, "match.params.merchant_id")
    ? { merchant_id: params.merchant_id }
    : {}

  return (
    <MCForm {...props} toolbar={<MCEditToolbar />} submitOnEnter={false}>
      <MyTab {...props} filterMerchant={filterMerchant} />
    </MCForm>
  )
}
