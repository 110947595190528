import React from "react"
import { Field } from "react-final-form"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import { TrashIcon } from "../components/icon_svg"
import { Title } from "../components"

class TagsInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
    }
    this.updateField = this.updateField.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.addTags = this.addTags.bind(this)
    this.removeTags = this.removeTags.bind(this)
  }
  componentDidMount() {
    const tags = this.props.record[this.props.source] || {}
    const keys = Object.keys(tags)
    var stateTags = this.state.tags
    for (var i = 0; i < keys.length; i++) {
      stateTags.push({ key: keys[i], value: tags[keys[i]] })
    }
    this.setState({ tags: stateTags })
  }
  updateField() {
    const stateTags = this.state.tags
    var tags = {}
    for (var i = 0; i < stateTags.length; i++) {
      if (stateTags[i].key !== "") {
        tags[stateTags[i].key] = stateTags[i].value
      }
    }

    this.props.updateField(this.props.source, tags)
  }
  handleChange(index, key, value) {
    const stateTags = this.state.tags
    stateTags[index].key = key
    stateTags[index].value = value
    this.setState({ tags: stateTags })
    this.updateField()
  }
  addTags() {
    this.state.tags.push({ key: "", value: "" })
    this.setState({
      tags: this.state.tags,
    })
    this.updateField()
  }
  removeTags(index) {
    this.state.tags.splice(index, 1)
    this.setState({
      tags: this.state.tags,
    })
    this.updateField()
  }
  render() {
    const { translate } = this.props

    return (
      <Field name={"tags"}>
        {formProps => {
          return (
            <Card style={{ width: "100%", marginTop: 20 }}>
              <CardContent>
                <div>
                  <Title fontSize={12} fontWeight={"bold"}>
                    {translate(
                      this.props.label ||
                        "resources." +
                          this.props.resource +
                          ".fields." +
                          this.props.source
                    )}
                  </Title>
                </div>
                {this.state.tags.map((tag, index) => (
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    key={index}
                  >
                    <TextField
                      label="Key"
                      name={`${tag}.key`}
                      style={{ marginRight: 8 }}
                      value={tag.key}
                      onChange={e =>
                        this.handleChange(index, e.target.value, tag.value)
                      }
                    />
                    <TextField
                      label="Value"
                      name={`${tag}.value`}
                      value={tag.value}
                      onChange={e =>
                        this.handleChange(index, tag.key, e.target.value)
                      }
                    />
                    <IconButton
                      color="primary"
                      onClick={e => this.removeTags(index)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.addTags()}
                  style={{ marginTop: 8 }}
                >
                  Add{" "}
                  {translate(
                    this.props.label ||
                      "resources." +
                        this.props.resource +
                        ".fields." +
                        this.props.source
                  )}
                </Button>
              </CardContent>
            </Card>
          )
        }}
      </Field>
    )
  }
}
export default TagsInput
