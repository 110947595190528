import React, { Children, useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Form, FormSpy } from "react-final-form"
import { get } from "lodash"
import moment from "moment"
import arrayMutators from "final-form-arrays"
import classnames from "classnames"
import {
  useTranslate,
  useInitializeFormWithRecord,
  sanitizeEmptyValues,
  getFormInitialValues,
  FormInput,
  Toolbar,
  CardContentInner,
} from "react-admin"
import { sanitizeRestProps } from "../components"
import { IsDebugForm } from "../components/form/ValuesForm"
import { API_SERVER, API_DEBUG } from "../restClient"

const MemberForm = ({ initialValues, defaultValue, saving, ...props }) => {
  let redirect = useRef(props.redirect)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = newRedirect => {
    redirect.current = newRedirect
  }

  const translate = useTranslate()

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  )

  const submit = values => {
    const finalRedirect =
      typeof redirect.current === undefined ? props.redirect : redirect.current

    if (values.birthdate && values.birthdate !== null) {
      values.birthdate = moment(
        values.birthdate,
        "YYYY-MM-DD 00:00:00"
      ).toISOString()
    }

    const finalValues = sanitizeEmptyValues(finalInitialValues, values)
    props.save(finalValues, finalRedirect)
  }

  return (
    <Form
      key={props.version}
      initialValues={finalInitialValues}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
        updateField: ([field, value], state, utils) => {
          utils.changeValue(state, field, () => value)
        },
      }}
      keepDirtyOnReinitialize
      destroyOnUnregister
      subscription={defaultSubscription}
      {...props}
      render={({
        form: {
          mutators: { updateField },
        },
        ...formProps
      }) => {
        return (
          <SimpleFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...props}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )
      }}
    />
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

export default MemberForm

const SimpleFormView = ({
  basePath,
  children,
  className,
  invalid,
  form,
  pristine,
  record,
  redirect: defaultRedirect,
  resource,
  saving,
  setRedirect,
  submitOnEnter,
  toolbar,
  undoable,
  version,
  handleSubmit,
  variant,
  margin,
  updateField,
  ...rest
}) => {
  useInitializeFormWithRecord(record)
  const [formValues, setFormValues] = useState(record)

  const handleSubmitWithRedirect = useCallback(
    (redirect = defaultRedirect) => {
      setRedirect(redirect)
      handleSubmit()
    },
    [setRedirect, defaultRedirect, handleSubmit]
  )
  return (
    <form
      className={classnames("simple-form", className)}
      {...sanitizeRestProps(rest)}
    >
      <CardContentInner key={version}>
        {Children.map(children, input => {
          if (get(input, "props.source", "") === "tags") {
            return (
              <FormInput
                basePath={basePath}
                input={input}
                record={record}
                resource={resource}
                variant={variant}
                margin={margin}
                formvalues={formValues}
                updateField={(key, value) => updateField(key, value)}
              />
            )
          }
          return (
            <FormInput
              basePath={basePath}
              input={input}
              record={record}
              resource={resource}
              variant={variant}
              margin={margin}
              formvalues={formValues}
            />
          )
        })}
      </CardContentInner>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
      {API_SERVER !== API_DEBUG && IsDebugForm && (
        <FormSpy subscription={{ values: true }}>
          {props => {
            setFormValues(props.values)
            return (
              <code style={{ fontSize: 10, padding: 10, margin: 16 }}>
                {JSON.stringify(props.values, undefined, 2)}
              </code>
            )
          }}
        </FormSpy>
      )}
    </form>
  )
}

SimpleFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number,
}

SimpleFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
}
