import React from "react"
import Paper from "@material-ui/core/Paper"
import { SelectInput, required, useTranslate } from "react-admin"
import {
  RaNumberInput,
  RaBooleanInput,
  RaSelectArrayChipInput,
  PanelView,
  CollapseMui,
} from "../../components"
import { DataDefaultForm } from "./DataDefaultForm"

export const GeneralSpendingRule = props => {
  const translate = useTranslate()
  if (props.record && !props.record.general_spending_rule) {
    props.record.general_spending_rule = {
      excluded_skus: [],
      excluded_categories: [],
      tags_inclusion_type: [],
      tags: [],
    }
  }
  return (
    <div>
      <RaNumberInput
        label="resources.earningRules.fields.general_spending_rule.point_value"
        source="general_spending_rule.point_value"
        notetext={"resources.earningRules.notetext.point_value"}
        validate={required()}
      />
      <RaNumberInput
        label="resources.earningRules.fields.general_spending_rule.min_order_value"
        source="general_spending_rule.min_order_value"
        notetext={"resources.earningRules.notetext.min_spending"}
        validate={required()}
      />
      <CollapseMui
        label={translate("resources.earningRules.title.advanced_options")}
      >
        <Paper typepaper={"paper"} style={{ marginTop: 0, padding: 16 }}>
          <PanelView title={"resources.earningRules.title.subtitle"}>
            <div>
              <RaSelectArrayChipInput
                label="resources.earningRules.fields.general_spending_rule.excluded_skus"
                source="general_spending_rule.excluded_skus"
                notetext={"resources.earningRules.notetext.product_sku"}
                options={{ fullWidth: true }}
              />
              <RaSelectArrayChipInput
                label="resources.earningRules.fields.general_spending_rule.excluded_categories"
                source="general_spending_rule.excluded_categories"
                notetext={"resources.earningRules.notetext.product_categories"}
                options={{ fullWidth: true }}
              />
            </div>
          </PanelView>
          <PanelView
            title={
              "resources.earningRules.fields.general_spending_rule.tags_rules"
            }
          >
            <div>
              <SelectInput
                label="resources.earningRules.fields.general_spending_rule.tags_inclusion_type"
                source="general_spending_rule.tags_inclusion_type"
                choices={[
                  { id: "Include", name: "Include" },
                  { id: "Exclude", name: "Exclude" },
                ]}
                style={{ minWidth: 180 }}
              />
              <RaSelectArrayChipInput
                label="resources.earningRules.fields.general_spending_rule.tags"
                source="general_spending_rule.tags"
                style={{ minWidth: 180 }}
                options={{ fullWidth: true }}
              />
              <RaBooleanInput
                label="resources.earningRules.fields.general_spending_rule.exclude_delivery_cost"
                source="general_spending_rule.exclude_delivery_cost"
              />
            </div>
          </PanelView>

          <DataDefaultForm {...props} />
        </Paper>
      </CollapseMui>
    </div>
  )
}
