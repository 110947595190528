import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

export const useStyles = makeStyles(theme => ({
  expand: {
    padding: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  paper: {
    padding: theme.spacing(3, 2),
    borderColor: grey[300],
    borderWidth: 1,
    borderStyle: "solid",
    boxShadow: "none",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export const listValidFor = [
  { id: 1, name: "1 month" },
  { id: 2, name: "2 months" },
  { id: 3, name: "3 months" },
  { id: 4, name: "4 months" },
  { id: 5, name: "5 months" },
  { id: 6, name: "6 months" },
  { id: 7, name: "7 months" },
  { id: 8, name: "8 months" },
  { id: 9, name: "9 months" },
  { id: 10, name: "10 months" },
  { id: 11, name: "11 months" },
  { id: 12, name: "12 months" },
]

export { BirthDaysContainer } from "./BirthdaysContent"
export { TagsInput } from "./TagsFields"
export { TagsRules } from "./TagsRules"
export { CollapsePaper } from "./CollapsePaper"
export { default as EditForm } from "./EditForm"
export { default as MainTab } from "./MainTab"
export { FormFields } from "./FormFields"
