import React from "react"
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
  DateInput,
} from "react-admin"
import {
  EditToolbar,
  ActionForm,
  BetterCreate,
  BetterEdit,
  RaBooleanInput,
} from "../components"
import TagsInput from "./tagsInput"
import MemberForm from "./MemberForm"

const BetterSimpleForm = props => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props
  return <MemberForm {...checkProps} redirect="list" submitOnEnter={false} />
}

const Form = props => {
  const {
    basePath,
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    saving,
    undoable,
    ...rest
  } = props
  const translate = useTranslate()
  return (
    <BetterSimpleForm {...props}>
      <RaBooleanInput source="active" />
      <TextInput source="email" type="email" />
      <TextInput source="phone" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <SelectInput
        source="gender"
        choices={[
          { id: "male", name: "Male" },
          { id: "female", name: "Female" },
        ]}
      />
      <DateInput source="birthdate" />
      <ReferenceInput
        source="level_id"
        reference="levels"
        filter={{ active: true }}
      >
        <SelectInput />
      </ReferenceInput>
      <TagsInput {...rest} source="tags" translate={translate} />
      <TextInput source="reference_id" />
    </BetterSimpleForm>
  )
}

export const create = props => (
  <BetterCreate
    {...props}
    actions={<ActionForm title={"resources.members.name"} />}
  >
    <Form {...props} />
  </BetterCreate>
)
export const edit = props => (
  <BetterEdit
    {...props}
    actions={<ActionForm title={"resources.members.name"} />}
  >
    <Form toolbar={<EditToolbar />} {...props} />
  </BetterEdit>
)
