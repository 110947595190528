import { filter, some } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

export const useStyles = makeStyles(theme => ({
  expand: {
    padding: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    padding: theme.spacing(3, 2),
    borderColor: grey[300],
    borderWidth: 1,
    borderStyle: "solid",
    boxShadow: "none",
  },
}))

export function compareDataAdd(products, rewards) {
  if (!products || !rewards) {
    return []
  }

  const result = filter(products, product => {
    const result2 = some(rewards, reward => {
      return reward !== null && product.id === reward.product_id
    })
    product["isAdded"] = result2
    return product
  })
  return result
}

export const useStylesSearch = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export function remove(obj, key) {
  for (var k in obj) {
    if (k === key) {
      delete obj[key]
      return true
    } else if (typeof obj[k] === "object") {
      if (remove(obj[k], key)) return true
    }
  }
  return false
}
