import React from "react"
import { useTranslate } from "react-admin"
import ChipInput from "material-ui-chip-input"
import { get } from "lodash"
import { Box } from "rebass"
import TextMessage from "./utils"

const RaSelectArrayChipInput = ({
  updateField,
  record,
  source,
  label,
  notetext,
  ...rest
}) => {
  const [chip, setChip] = React.useState(get(record, source, []))
  const translate = useTranslate()
  const handleAddChip = valueChip => {
    const values = [...chip, valueChip]
    setChip(values)
    // updateField(source, values)
  }
  const handleDeleteChip = (valueChip, index) => {
    const values = chip.filter(v => v !== valueChip)
    // console.log(values)
    setChip(values)
    // updateField(source, values)
  }
  return (
    <Box>
      <ChipInput
        label={label ? translate(label) : source}
        value={chip}
        onAdd={chip => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
        {...rest}
      />
      <TextMessage notetext={notetext} />
    </Box>
  )
}
export default RaSelectArrayChipInput
