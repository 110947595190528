import React from "react"
import { Flex, Box } from "rebass"
import { useTranslate } from "react-admin"
import { ListButton } from "../buttons"
import { Title } from "../"
const HeaderDetail = (props) => {
  const { title, basepath } = props
  const translate = useTranslate()
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems="center"
    >
      <Box>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(title)}
        </Title>
      </Box>
      <ListButton basepath={basepath} />
    </Flex>
  )
}

export default HeaderDetail
