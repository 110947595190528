import React from "react"
import PropTypes from "prop-types"
// import DashboardIcon from "@material-ui/icons/Dashboard"
import { translate } from "react-admin"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { Chart8 } from "../icon_svg"
import MenuItemLink from "./MenuItemLink"

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      color: theme.palette.text.secondary,
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 12,
      letterSpacing: 3,
    },
    active: {
      fontSize: 12,
      fontWeight: "bold",
      alignItems: "center",
      backgroundColor: "rgba(54,76,144,0.4)",
      color: "#FFFFFF",
    },
    icon: { paddingRight: "1.2em", display: "flex", size: 24 },
  })

const DashboardMenuItem = ({ locale, onClick, translate, ...props }) => (
  <MenuItemLink
    onClick={onClick}
    to="/"
    primaryText={translate("ra.page.dashboard")}
    leftIcon={<Chart8 />}
    exact
    {...props}
  />
)

DashboardMenuItem.propTypes = {
  classes: PropTypes.object,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  translate: PropTypes.func.isRequired,
}

export default translate(withStyles(styles)(DashboardMenuItem))
