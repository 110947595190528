export default {
  programmes: {
    name: "Programmes",
    new_title: "New Programme",
    tabs: {
      programme: "Programme",
      earning_rules: "Earning Rules",
      members: "Members",
      catalog: "Catalog",
      levels: "Levels",
      settings: "Settings",
    },
    fields: {
      active: "Active",
      account: "Account",
      name: "Name",
      loyalty_program_name: "Loyalty Program Name",
      currency_code: "Currency Code",
      points_singular: "Point in Singular",
      points_plural: "Point in Plural",
      points_expiry: "Point Expiry (in Days)",
      catalogues: "Catalogues",
      remarks: "Remarks",
      created_at: "Create On",
      logo: "Logo",
      colors: "Colors",
      help_text: "Login Text",
      login_facebook: "Login With Facebook",
      login_google: "Login with Google",
      login_mobile: "Login with Mobile",
      login_instagram: "Login with Instagram",
      login_settings: "Login Settings",
      description: "Description",
      contact_us: "Contact Us",
      timezone: "Timezone",
      domain: "Domain",
    },
    info: {
      title_download_report: "Download Report",
      loyalty_program_chart: "Programmes Dashboard",
      assign_loyalty_program_admin: "Assign Loyalty Program Admin",
    },
    message: {
      loyalty_program_name: "How your customer remember your loyalty program",
      currency_code: "For example, SGD, THB, IDR, MYR",
      points_singular: "For example, point, dollar, $",
      points_plural: "For example, points, dollars, $",
      points_expiry: "Put 0 for no expiry",
      catalogues: "Which catalogue to include for this program",
    },
  },
}
