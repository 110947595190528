import React from "react"
import { useTranslate } from "react-admin"
import { Text, Box, Flex } from "rebass"
import { grey, lightBlue } from "@material-ui/core/colors"

import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import { ListButton, Title } from "../../components"

export const useStyles = makeStyles((theme) => ({
  main: {
    width: 220,
    marginRight: "1em",
    marginTop: 0,
    paddingTop: 0,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 8,
    minHeight: 50,
    backgroundColor: grey[50],
  },
}))

export const TitleRangePicker = () => {
  const translate = useTranslate()
  return (
    <Flex flexDirection={"row"} p={[1]} pt={[4]}>
      <Text width={["140px"]} fontSize={[0]}>
        {translate("resources.dashboard.label.start")}
      </Text>
      <Text fontSize={[0]}>{translate("resources.dashboard.label.end")}</Text>
    </Flex>
  )
}

export const TitleField = (props) => {
  const translate = useTranslate()
  const { title, value } = props
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Text
          fontSize={14}
          fontWeight={"bold"}
          style={{ color: "#009C7D" }}
          textAlign="left"
        >
          {translate(`resources.dashboard.fields.${title}`)}
        </Text>
        <Text
          mt={[2]}
          fontSize={24}
          fontWeight={"bold"}
          style={{ color: "#838283" }}
        >
          {value}
        </Text>
      </Card>
    </div>
  )
}

export const BoxTitleField = (props) => {
  const { totalRedemption, totalConsumption } = props
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      alignItems={"center"}
      py={[3]}
      pb={[2]}
    >
      <TitleField title={"total_redemption"} value={totalRedemption} />
      <TitleField title={"total_consumption"} value={totalConsumption} />
    </Flex>
  )
}

export const HeaderField = (props) => {
  const { location, resource, title } = props
  const translate = useTranslate()

  const { pathname } = location

  const check = `/${resource}`
  const n = pathname.indexOf(check)
  const currentParent = pathname.substring(0, n + check.length)
  return (
    <Box width={[1]} pb={[2]}>
      <Flex width={[1]} justifyContent={"space-between"} alignItems={"center"}>
        <Title fontWeight={"bold"}>
          {translate(`resources.${title}.dashboard_name`)}
        </Title>
        <ListButton
          basepath={currentParent}
          record={{}}
          style={{
            color: lightBlue[700],
          }}
        />
      </Flex>
    </Box>
  )
}
