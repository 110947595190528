export default {
  products: {
    name: "Products",
    title_expand: "Restrictions",
    fields: {
      active: "Active",
      name: "Name",
      photos: "Photos",
      type: "Type",
      remarks: "Remarks",
      terms: "Terms",
      expiry_days: "Expiry Days",
      points: "Points",
      value: "Value",
      point_need: "Points Needed",
      reward_value: "Reward Value",
      sku: "SKU",
      min_order_value: "Mininum Order Value",
      reward_validity: "Reward Validity (Days)",
      not_in_branches: "Not in Branches",
      cost: "Cost",
      price: "Price",
      margin: "Margin",
    },
    note_message: {
      point_need: "Loyalty points needed to redeem this reward",
      reward_value: "Amount to be deducted from subtotal",
      sku: "Restricted to the above SKUs only",
      min_order_value:
        "Miniumn order amount in the shopping cart during checkout",
      reward_validity:
        "Number of days reward is valid after redemption. Put 0 if no expiry",
      not_in_branches: "List of branches not participate for this product",
    },
  },
}
