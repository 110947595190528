import React from "react"
import { required } from "react-admin"

import {
  BetterSimpleForm,
  BetterCreate,
  BetterEdit,
  EditToolbar,
  RaTextInput,
  ActionForm,
  // RaBooleanInput,
  RaTextArea,
} from "../components"

const Form = props => {
  return (
    <BetterSimpleForm {...props}>
      {/* <RaBooleanInput source="active" id={"active"} /> */}
      <RaTextInput source="name" validate={required()} />
      <RaTextInput source="address" style={{ width: 500 }} multiline />
      <RaTextInput
        label="resources.accounts.fields.contact_person"
        source="contact.name"
        style={{ width: 500 }}
      />
      <RaTextInput
        label="resources.accounts.fields.contact_email"
        source="contact.email"
        style={{ width: 500 }}
      />
      <RaTextInput
        label="resources.accounts.fields.contact_phone"
        source="contact.phone"
        style={{ width: 500 }}
      />

      <RaTextArea
        source="remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </BetterSimpleForm>
  )
}

export const create = props => {
  return (
    <BetterCreate
      {...props}
      actions={<ActionForm title={"resources.accounts.name"} />}
    >
      <Form />
    </BetterCreate>
  )
}
export const edit = props => (
  <BetterEdit
    {...props}
    actions={<ActionForm title={"resources.accounts.name"} />}
  >
    <Form toolbar={<EditToolbar />} {...props} />
  </BetterEdit>
)
