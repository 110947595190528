import React, { Component } from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import { get } from "lodash"
import { Flex, Box } from "rebass"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Snackbar from "@material-ui/core/Snackbar"
import { RemoveCircle } from "@material-ui/icons"
import { translate } from "react-admin"
import { grey } from "@material-ui/core/colors"
import { UploadClient } from "../../restClient"
import { styles } from "./styles"
import { ERROR_MESSAGE_MAX_SIZE, ERROR_MESSAGE_FAILED } from "./utils"
import "./index.css"

class ImageOlaSingleInput extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
    let photos = get(props, `record[${props.source}]`, "")
    let isShowing = false
    if (null != photos && "" !== photos) {
      isShowing = true
    }
    this.state = {
      photos: photos,
      isShow: isShowing,
      isLoading: false,
      openSnackBar: false,
      message: "Please try again",
      variant: "success",
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let photos = get(nextProps, `record[${nextProps.source}]`, "")
    if (null != photos && "" !== photos) {
      this.setState({ photos: photos })
    }
  }

  onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoading: true,
      })
      const name = this.props.toValue
      const flag = this.props.labelImage
      let url = this.props.basePath + "/upload"
      if (this.props.basePath === "/payment") {
        url = "/products/upload"
      } else if (this.props.basePath === "/adbanner") {
        url = "/products/upload"
      } else if (this.props.basePath === "/programmes") {
        url = "/products/upload"
      }

      if (name !== undefined && name !== "") {
        let brand = name.trim()
        brand = brand.replace(/\s+/g, "-")
        brand = brand.replace(/[&/\\#,+[/\]()$~%.@'"^:*!`?<>{}]/g, "")

        let joinData = brand

        if (flag.toLowerCase() === "desktop") {
          joinData += "-desktop"
        } else if (flag.toLowerCase() === "mobile") {
          joinData += "-mobile"
        }
        let formData = new FormData()
        // caption as brand-product name
        formData.append("caption", joinData)
        accepted.forEach((file) => {
          formData.append("photos", file, file.name)
        })

        UploadClient(formData, url)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.url) {
              this.onAdd(data.url)
            } else {
              this.setState({
                isLoading: false,
                openSnackBar: true,
                variant: "error",
                message: ERROR_MESSAGE_FAILED,
              })
            }
          })
          .catch((e) => {
            this.setState({
              isLoading: false,
              openSnackBar: true,
              variant: "error",
              message: ERROR_MESSAGE_FAILED,
            })
          })
      }
    } else {
      this.setState({
        isLoading: false,
        openSnackBar: true,
        variant: "error",
        message: ERROR_MESSAGE_MAX_SIZE,
      })
    }
  }

  onAdd(url) {
    this.setState({
      photos: url,
      isShow: true,
      isLoading: false,
      openSnackBar: false,
      message: "",
    })
    // console.log(this.state.photos);
    //this.props.input.onChange(url)
    //this.props.record[this.props.source]=url;
    this.props.updateField(this.props.source, url)
  }

  onRemove = (file) => () => {
    this.setState({ photos: "", isShow: false })
    //this.props.input.onChange("")
    this.props.updateField(this.props.source, "")
  }

  renderItem() {
    return (
      <div style={styles.gridImage}>
        <div style={styles.container}>
          <Button
            style={styles.removeButton}
            onClick={this.onRemove(this.state.photos)}
          >
            <RemoveCircle color={"secondary"} />
          </Button>
          <img
            src={this.state.photos}
            style={styles.image}
            alt="delete"
            title="photos"
          />
        </div>
      </div>
    )
  }
  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    })
  }
  render() {
    const {
      accept,
      disableClick,
      elStyle,
      maxSize,
      minSize,
      multiple,
      labelImage,
    } = this.props
    const { variant } = this.state
    return (
      <div style={elStyle}>
        <div className="labelImage">{labelImage}</div>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
        >
          <Box pr={[2]}>
            <Dropzone
              onDrop={this.onDrop}
              accept={accept}
              disableClick={disableClick}
              maxSize={maxSize}
              minSize={minSize}
              multiple={multiple}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: 160,
                    height: 160,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: grey[200],
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {this.state.isLoading && (
                      <div style={styles.insideBox}>
                        <CircularProgress />
                      </div>
                    )}
                    {!this.state.isLoading && (
                      <div style={styles.insideBox}>
                        <span>Drop your image file here </span>
                        <span>or Click here</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </Box>
          <div className="previews">
            {this.state.isShow && this.renderItem()}
          </div>
        </Flex>
        <Snackbar
          variant={variant}
          open={this.state.openSnackBar}
          message={this.state.message}
          autoHideDuration={4000}
          onClose={this.handleRequestClose}
        />
      </div>
    )
  }
}

ImageOlaSingleInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  toValue: PropTypes.string,
  updateField: PropTypes.func,
}

ImageOlaSingleInput.defaultProps = {
  addLabel: true,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  onUpload: () => {},
  toValue: "",
}

export default translate(ImageOlaSingleInput)
