import React from "react"
import ChipInput from "material-ui-chip-input"
import { get } from "lodash"
import { Box } from "rebass"
import { TextMessage } from "../../components/ra-field"

export const TagsInput = ({ updateField, record, source, label, notetext }) => {
  const [chip, setChip] = React.useState(get(record, source, []))
  const handleAddChip = valueChip => {
    const values = [...chip, valueChip]
    setChip(values)
    updateField(source, values)
  }
  const handleDeleteChip = (valueChip, index) => {
    const values = chip.filter(v => v !== valueChip)
    // console.log(values)
    setChip(values)
    updateField(source, values)
  }
  return (
    <Box>
      <ChipInput
        label={label}
        value={chip}
        onAdd={chip => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
      />
      <TextMessage notetext={notetext} />
    </Box>
  )
}
