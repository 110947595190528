import React from "react"
import moment from "moment"
import { useTranslate } from "react-admin"
import { Text, Box } from "rebass"
import { get } from "lodash"

const TextRecord = ({
  source,
  record = {},
  label,
  color,
  defaultValue = "",
  pb = 3,
  isDate = false,
  formatDate = "DD-MMM-YYYY",
}) => {
  const translate = useTranslate()
  const subColor = color ? { color: color } : {}
  if (isDate) {
    return (
      <Box pb={[pb]}>
        <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
          {translate(label)}
        </Text>
        <Text style={{ ...subColor }}>
          {get(record, source, defaultValue) !== "" &&
            moment(get(record, source, defaultValue)).format(formatDate)}
        </Text>
      </Box>
    )
  }

  return (
    <Box pb={[pb]}>
      <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
        {translate(label)}
      </Text>
      {source === "active" && (
        <Text style={{ textTransform: "capitalize", ...subColor }}>
          {get(record, source, "false")}
        </Text>
      )}
      {source !== "active" && (
        <Text style={{ ...subColor }}>{get(record, source, defaultValue)}</Text>
      )}
    </Box>
  )
}
export default TextRecord
