import React from "react"
import { required } from "react-admin"
import { Box } from "rebass"
import { RaTextInput, RaBooleanInput, RaTextArea } from "../components"

export const FormFields = props => {
  return (
    <Box width={[1]}>
      <RaBooleanInput source="active" id={"active"} />
      <RaTextInput source="name" validate={required()} style={{ width: 300 }} />
      {/* <RaTextInput
        source="type"
        label="resources.branches.fields.type"
        style={{ width: 180 }}
      /> */}
      <RaTextInput
        source="address"
        label="resources.branches.fields.address"
        style={{ width: 500 }}
        multiline
      />

      <RaTextInput
        source="contact.name"
        label="resources.branches.fields.contact_person"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.email"
        label="resources.branches.fields.email"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.phone"
        label="resources.branches.fields.phone"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="validation_code"
        label="resources.branches.fields.validation_code"
        style={{ width: 100 }}
      />

      <RaTextArea
        source="remarks"
        label="resources.branches.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Box>
  )
}
