import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FullLoadingScreen } from "../components"
import { has } from "lodash"
import { BetterCustomSimpleForm } from "../components"
import { FormFields } from "./FormFields"
import { getProfile } from "../utils"

const Form = props => {
  const { match } = props
  const profile = getProfile()

  const { data, loading } = useQuery({
    type: "getOne",
    resource: "merchants",
    payload: { id: match.params.merchant_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const account_id = has(data, "account_id")
    ? { account_id: data.account_id }
    : has(profile, "account_id")
    ? { account_id: profile.account_id }
    : {}
  const initValues = {
    merchant_id: data && data.id ? data.id : parseInt(match.params.merchant_id),
    ...account_id,
  }
  return (
    <BetterCustomSimpleForm {...props} initialValues={initValues}>
      <FormFields {...props} />
    </BetterCustomSimpleForm>
  )
}

const BranchCreate = props => {
  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...props}
        sourceApi="branches"
        typePostUrl={"CREATE"}
        pathUrl="branches"
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(BranchCreate)
