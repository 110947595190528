export default [
  { name: "GMT-11", id: -11, rawOffsetInMinutes: -660 },
  { name: "GMT-10", id: -10, rawOffsetInMinutes: -600 },
  { name: "GMT-9.3", id: -9.3, rawOffsetInMinutes: -570 },
  { name: "GMT-9", id: -9, rawOffsetInMinutes: -540 },
  { name: "GMT-8", id: -8, rawOffsetInMinutes: -480 },
  { name: "GMT-7", id: -7, rawOffsetInMinutes: -420 },
  { name: "GMT-6", id: -6, rawOffsetInMinutes: -360 },
  { name: "GMT-5", id: -5, rawOffsetInMinutes: -300 },
  { name: "GMT-4", id: -4, rawOffsetInMinutes: -240 },
  { name: "GMT-3.3", id: -3.3, rawOffsetInMinutes: -210 },
  { name: "GMT-3", id: -3, rawOffsetInMinutes: -180 },
  { name: "GMT-2", id: -2, rawOffsetInMinutes: -120 },
  { name: "GMT-1", id: -1, rawOffsetInMinutes: -60 },
  { name: "GMT0", id: 0, rawOffsetInMinutes: 0 },
  { name: "GMT+1", id: 1, rawOffsetInMinutes: 60 },
  { name: "GMT+2", id: 2, rawOffsetInMinutes: 120 },
  { name: "GMT+3", id: 3, rawOffsetInMinutes: 180 },
  { name: "GMT+3.3", id: 3.3, rawOffsetInMinutes: 210 },
  { name: "GMT+4", id: 4, rawOffsetInMinutes: 240 },
  { name: "GMT+4.3", id: 4.3, rawOffsetInMinutes: 270 },
  { name: "GMT+5", id: 5, rawOffsetInMinutes: 300 },
  { name: "GMT+5.3", id: 5.3, rawOffsetInMinutes: 330 },
  { name: "GMT+5.45", id: 5.45, rawOffsetInMinutes: 345 },
  { name: "GMT+6", id: 6, rawOffsetInMinutes: 360 },
  { name: "GMT+6.3", id: 6.3, rawOffsetInMinutes: 390 },
  { name: "GMT+7", id: 7, rawOffsetInMinutes: 420 },
  { name: "GMT+8", id: 8, rawOffsetInMinutes: 480 },
  { name: "GMT+8.45", id: 8.45, rawOffsetInMinutes: 525 },
  { name: "GMT+9", id: 9, rawOffsetInMinutes: 540 },
  { name: "GMT+9.3", id: 9.3, rawOffsetInMinutes: 570 },
  { name: "GMT+10", id: 10, rawOffsetInMinutes: 600 },
  { name: "GMT+10.3", id: 10.3, rawOffsetInMinutes: 630 },
  { name: "GMT+11", id: 11, rawOffsetInMinutes: 660 },
  { name: "GMT+12", id: 12, rawOffsetInMinutes: 720 },
  { name: "GMT+12.45", id: 12.45, rawOffsetInMinutes: 765 },
  { name: "GMT+13", id: 13, rawOffsetInMinutes: 780 },
  { name: "GMT+14", id: 14, rawOffsetInMinutes: 840 },
]
