import React from "react"
import { ReferenceInput, SelectInput } from "react-admin"
//useTranslate,
// ReferenceField,
//   TextField,
import { Box, Flex } from "rebass"
import moment from "moment"
import { RaTextArea, RaDateInput } from "../../components"
import ClientField from "./ClientField"
// import { formatDate } from "../../utils"

const formatDate = (value) => {
  return moment(value).toISOString()
}
export const MyTab = (props) => {
  // const translate = useTranslate()
  const { typePostUrl } = props

  return (
    <Box width={[1]}>
      {typePostUrl === "CREATE" ? (
        <ReferenceInput
          label="resources.merchants.fields.client"
          source="client_id"
          reference="clients"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : (
        <ClientField record={props.initialValues} />
      )}
      <Flex
        width={[1]}
        flexDirection={"row"}
        sx={{
          "&>div": {
            pr: [3],
          },
        }}
      >
        <RaDateInput
          source={"start_date"}
          label="resources.merchants.fields.start_date"
          parse={formatDate}
          options={{
            format: "DD-MMM-YYYY",
            variant: "inline",
            disableToolbar: true,
          }}
        />
        <RaDateInput
          source={"end_date"}
          label="resources.merchants.fields.end_date"
          parse={formatDate}
          options={{
            format: "DD-MMM-YYYY",
            variant: "inline",
            disableToolbar: true,
          }}
        />
      </Flex>
      <Box>
        {/* <DateInput
          label="resources.merchants.fields.end_date"
          source={"end_date"}
          parse={formatDate}
          options={{ format: "DD-MMM-YYYY" }}
        /> */}
      </Box>
      <RaTextArea
        source="remarks"
        label="resources.merchants.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Box>
  )
}
