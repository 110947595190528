import React from "react"
import { Box } from "rebass"
import { showNotification, useQueryWithStore } from "react-admin"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { MerchantField, ListMerchantProducts } from "./components"
import { HeaderDetail } from "../components"
const CataloguesMerchantDetail = (props) => {
  const { match } = props
  const { loading, data } = useQueryWithStore({
    type: "getOne",
    resource: "merchantClients",
    payload: { id: get(match, "params.id", 0) },
  })

  if (loading) {
    return <div />
  }
  // console.log("testing", data)
  const merchant_id = get(data, "merchant_id", 0)
  return (
    <Box width={[1]} pt={[3]}>
      <HeaderDetail
        title={"resources.merchants.detail_name"}
        basepath={`/catalogues/${props.match.params.catalogue_id}/merchants`}
      />
      <Box width={[1]} py={[2]}>
        <MerchantField {...props} merchant_id={merchant_id} />
        <ListMerchantProducts {...props} merchant_id={merchant_id} />
      </Box>
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CataloguesMerchantDetail)
