import React from "react"
import { useQueryWithStore } from "react-admin"
import TextRecord from "../../components/ra-field/TextRecord"
const MerchantField = (props) => {
  const { merchant_id } = props
  const { loading, data } = useQueryWithStore({
    type: "getOne",
    resource: "merchants",
    payload: { id: merchant_id },
  })

  if (loading) {
    return <div />
  }
  return (
    <TextRecord
      source={"name"}
      label={"resources.merchants.detail_name"}
      record={data}
    />
  )
}

export default MerchantField
