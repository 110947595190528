import React from "react"
import { showNotification } from "react-admin"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { CataloguesDashboard } from "../dashboard"

const CatalogChart = (props) => {
  return <CataloguesDashboard {...props} />
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CatalogChart)
