import { createMuiTheme } from "@material-ui/core/styles"
import red from "@material-ui/core/colors/red"
import teal from "@material-ui/core/colors/teal"
import { bcx, bcx_secondary_orange } from "../components/app"
import { inputStyles } from "./customInput"

//bcx_secondary,

export const themeMui = createMuiTheme({
  palette: {
    primary: bcx,
    secondary: bcx_secondary_orange,
    error: red,
  },
  typography: {
    fontFamily: ["SalesforceSans, sans-serif"].join(","),
    fontSize: 14,
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiTableSortLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontSize: 14,
        fontWeight: "900",
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
      },
    },

    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 16,
        minWidth: 280,
        fontWeight: "900",
        "&$shrink": {
          color: inputStyles.colorLabel,
          fontSize: 16,
          transform: "translate(0px, 6px) scale(0.75)",
        },
        "&$focused": {
          color: inputStyles.colorLabel,
        },
        "&$error": {
          color: red[500],
        },
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 280,
        fontWeight: "900",
        transform: "translate(0px, 26px) scale(1)",
        "&$shrink": {
          fontSize: 16,
          transform: "translate(0px, 6px) scale(0.75)",
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 14,
          transform: "translate(0px, 26px) scale(1)",
        },
      },
    },
    MuiSelect: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 280,
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 280,
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        margin: 0,
        marginTop: 12,
        "&$expanded": {
          margin: 0,
          marginTop: 12,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: "#FF9900",
      },
    },
    MuiSwitch: {
      root: {},
      colorPrimary: {
        "&$checked": {
          color: teal[500],
          "& + $track": {
            backgroundColor: teal[200],
            opacity: 1,
            border: "none",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: 5,
        paddingTop: 23,
        paddingBottom: 2,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        paddingTop: 23,
        paddingBottom: 2,
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "6px 2px 10px",
        paddingBottom: 2,
        fontSize: 14,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: inputStyles.letterSpacing,
        paddingBottom: 4,
        padding: "27px 2px 10px",
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
        paddingTop: 4,
        paddingBottom: 4,
        borderBottomWidth: 0,
      },
      head: {
        fontWeight: "bold",
        padding: 8,
      },
    },
    MuiToolbar: {
      regular: {
        paddingRight: 0,
        paddingLeft: 0,
      },
      gutters: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: "none",
        fontWeight: "bold",
      },
    },
    Layout: {
      content: {
        padding: 16,
      },
    },
  },
  sidebar: {
    width: 256,
    closedWidth: 256,
    backgroundColor: "#EFEFEF",
  },
})
