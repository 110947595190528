import React from "react"
//BooleanField
import { TextField, DateField, useTranslate } from "react-admin"
import { Flex, Box } from "rebass"
import { AddIcon } from "../components/icon_svg"
import { BetterList, ButtonEditView, CreateButton, Title } from "../components"
import { lightBlue } from "@material-ui/core/colors"

export { create as AccountCreate, edit as AccountEdit } from "./Create"

const AccountAction = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => {
  const translate = useTranslate()
  return (
    <Flex
      width={[1]}
      pt={[2]}
      flexDirection={"row"}
      justifyContent="space-between"
    >
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.accounts.name`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton
          basePath={basePath}
          icon={<AddIcon />}
          sizeIcon={"xsmall"}
          style={{ color: lightBlue[700] }}
        />
      </Flex>
    </Flex>
  )
}

export const AccountList = (props) => (
  <BetterList {...props} actions={<AccountAction />}>
    <TextField source="name" />
    {/* <BooleanField source="active" /> */}
    <DateField
      source="created_at"
      options={{
        day: "numeric",
        month: "short",
        year: "numeric",
      }}
    />
    <ButtonEditView {...props} />
  </BetterList>
)
