import React from "react"
import { required, SelectInput, ReferenceInput } from "react-admin"
import { Box } from "rebass"
import { RaTextInput, RaBooleanInput, RaTextArea } from "../../components"
import { ROLE_USER } from "../../utils"
import { listPaymentTerm, payment_mode } from "../Create"

const MainTab = props => {
  const { permissions, isCreate } = props
  return (
    <Box width={[1]} p={[2]}>
      <RaBooleanInput source="active" id={"active"} />
      {isCreate && permissions === ROLE_USER.super_admin && (
        <ReferenceInput
          label="resources.clients.fields.account"
          source="account_id"
          reference="accounts"
        >
          <SelectInput optionText="name" style={{ width: 200 }} />
        </ReferenceInput>
      )}
      <RaTextInput source="name" validate={required()} />
      <RaTextInput source="uen" labek="resources.clients.fields.uen" />
      <RaTextInput
        source="address"
        label="resources.clients.fields.address"
        style={{ width: 500 }}
        multiline
      />
      <RaTextInput
        source="contact.name"
        label="resources.clients.fields.contact_person"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.email"
        label="resources.clients.fields.email"
        style={{ width: 500 }}
      />
      <RaTextInput
        source="contact.phone"
        label="resources.clients.fields.phone"
        style={{ width: 500 }}
      />
      <SelectInput
        source="payment.terms"
        label="resources.clients.fields.payment_terms"
        choices={listPaymentTerm()}
        style={{ width: 180 }}
      />
      <Box>
        <SelectInput
          source="payment.mode"
          label="resources.payment_mode.name"
          choices={payment_mode}
          style={{ width: 180 }}
        />
      </Box>
      <RaTextArea
        source="remarks"
        label="resources.clients.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Box>
  )
}

export default MainTab
