import React from "react"
import { showNotification, useTranslate } from "react-admin"
import { withRouter } from "react-router-dom"
import { Flex, Box } from "rebass"
import { lightBlue } from "@material-ui/core/colors"

import { connect } from "react-redux"
import compose from "recompose/compose"
import { ListButton, Title } from "../components"
import { ProgrammeDashboard } from "../dashboard"
const ProgrammeChart = (props) => {
  const translate = useTranslate()
  const { location } = props
  const { pathname } = location
  const check = `/programmes`
  const n = pathname.indexOf(check)
  const currentParent = pathname.substring(0, n + check.length)

  return (
    <Box width={[1]} py={[3]}>
      <Box width={[1]} py={[2]} pb={[2]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Title fontSize={[2]} fontWeight={"bold"}>
            {translate("resources.programmes.info.loyalty_program_chart")}
          </Title>
          <ListButton
            basepath={currentParent}
            record={{}}
            style={{ color: lightBlue[700] }}
          />
        </Flex>
      </Box>
      <ProgrammeDashboard {...props} />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(ProgrammeChart)
