import React from "react"
import { Flex, Box } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import {
  TextField,
  DateField,
  BooleanField,
  useTranslate,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin"
import {
  BetterList,
  CreateButton,
  // BetterChartButton,
  BetterEditButton,
  Title,
} from "../components"
import { AddIcon } from "../components/icon_svg"
import { ROLE_USER, getProfile } from "../utils"

export { ChartShow as MerchantChartShow } from "./ChartShow"
export { create as MerchantCreate, edit as MerchantEdit } from "./Create"
export { Chart as MerchantChart } from "./Chart"
export { default as MerchantShow } from "./Show"
export { default as CataloguesMerchantDetail } from "./CataloguesMerchantDetail"
const ButtonChartEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    {/* <BetterChartButton
      {...props}
      isCustomLink={true}
      taburl="merchants"
      chartLink={"chartShow"}
    /> */}
    <BetterEditButton {...props} />
  </Flex>
)

const MerchantAction = ({ basePath }) => {
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"flex-end"}
      style={{ minHeight: 80 }}
      pt={[4]}
    >
      <Box />
      <Box>
        <CreateButton
          basePath={basePath}
          icon={<AddIcon />}
          sizeIcon={"xsmall"}
          style={{ color: lightBlue[700] }}
        />
      </Box>
    </Flex>
  )
}

const MerchantFilter = ({ permissions, ...props }) => {
  const translate = useTranslate()
  return (
    <Flex pt={[3]}>
      <Box width={[1]} style={{ position: "absolute" }}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.merchants.name`)}
        </Title>
      </Box>
      <Filter
        {...props}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 16,
          paddingBottom: 16,
          paddingRight: 16,
        }}
      >
        {permissions === ROLE_USER.super_admin && (
          <ReferenceInput
            label="Account"
            source="account_id"
            reference="accounts"
            alwaysOn
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          label="Search"
          source="search"
          alwaysOn
          style={{ minWidth: 180 }}
        />
      </Filter>
    </Flex>
  )
}

export const MerchantList = (props) => {
  const profile = getProfile()
  const { permissions } = props
  const filter =
    profile && permissions === ROLE_USER.account_admin
      ? { account_id: profile.account_id }
      : {}
  return (
    <BetterList
      {...props}
      actions={<MerchantAction />}
      filter={filter}
      filters={<MerchantFilter permissions={props.permissions} />}
    >
      <TextField source="name" />
      <BooleanField source="active" />
      <DateField
        source="created_at"
        options={{
          day: "numeric",
          month: "short",
          year: "numeric",
        }}
      />
      <ButtonChartEditView {...props} />
    </BetterList>
  )
}
