export default {
  rewards: {
    name: "Reward",
    dashboard_name: "Reward Dashboard",
    fields: {
      active: "Active",
      name: "Name",
      merchant_name: "Merchant Name",
      added: "Added",
      price: "Price",
      description: "Description",
      terms: "Terms",
      merchant_offer: {
        skus: "SKUs",
        min_order_value: "Minimum order value",
        merchant: "Merchant",
      },
      expiry_days: "Expiry (days)",
      points: "Points",
      value: "Value",
      created_at: "Create On",
      category: "Category",
      total_redemption_member: "Total Redemption Per Member",
      total_redemption: "Total redemptions",
      visible: "Check Birthday",
      day_before: "# of days before birthday month",
      valid_for: "Valid for",
    },
    info: {
      add: "Add reward",
      label_reward_1: "Add Reward to",
      catalogue: "Catalogue",
      birthday_rules: "Birthday Rules",
      day: "Day",
      days: "Days",
      month: "Month",
      months: "Months",
      available_bithday: "Available to member where birthday is",
    },
    message: {
      total_redemption_member:
        "Restrict total quantity per member. Put 0 for no restriction",
      total_redemption: "Maximum quantity available for redemption",
      valid_for: "How long member can use this reward during birthday month",
    },
  },
}
