import { UploadClient } from "../../restClient"

//const URL_UPLOAD = "/products/upload"

export class ImageUpload {
  /**
   * Instantiate the module given a quill instance and any options
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    // save the quill reference
    this.quill = quill
    // save options
    this.options = options
    // listen for drop and paste events
    this.quill
      .getModule("toolbar")
      .addHandler("image", this.selectLocalImage.bind(this))
  }

  /**
   * Select local image
   */
  selectLocalImage() {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        const checkBeforeSend =
          this.options.checkBeforeSend || this.checkBeforeSend.bind(this)
        checkBeforeSend(file, this.sendToServer.bind(this))
      } else {
        console.warn("You could only upload images.")
      }
    }
  }

  /**
   * Check file before sending to the server
   * @param {File} file
   * @param {Function} next
   */
  checkBeforeSend(file, next) {
    next(file)
  }

  /**
   * Send to server
   * @param {File} file
   */
  sendToServer(file) {
    const callbackOK =
      this.options.callbackOK || this.uploadImageCallbackOK.bind(this)
    const callbackKO =
      this.options.callbackKO || this.uploadImageCallbackKO.bind(this)

    let formData = new FormData()
    formData.append("caption", "product-description")
    formData.append("photos", file, file.name)
    console.log(this.options.resource)
    const resouce = "products" // this.options.resource
    UploadClient(formData, "/" + resouce + "/upload")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.url) {
          callbackOK(data.url, this.insert.bind(this))
        } else {
          callbackKO({
            code: 4001,
            type: "upload failed",
            body: "please try again",
          })
        }
      })
      .catch((e) => {
        if (e && e.message) {
          callbackKO({
            code: 400,
            type: "upload failed",
            body: e.message,
          })
        } else {
          callbackKO({
            code: 400,
            type: "upload failed",
            body: "please try again",
          })
        }
      })
  }

  /**
   * Insert the image into the document at the current cursor position
   * @param {String} data  The base64-encoded image URI
   */
  insert(data) {
    const selection = this.quill.getSelection()
    const index = selection ? selection.index : this.quill.getLength()
    this.quill.insertEmbed(index, "image", data.link, "user")
  }

  /**
   * callback on image upload succesfull
   * @param {Any} response http response
   */
  uploadImageCallbackOK(response, next) {
    next(response)
  }

  /**
   * callback on image upload failed
   * @param {Any} error http error
   */
  uploadImageCallbackKO(error) {
    alert(error)
  }
}
