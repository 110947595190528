import React from "react"
import { Flex } from "rebass"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { RaReferenceTextField } from "../../components"
import { CheckIcon } from "../../components/icon_svg"
import { AddButton } from "./"

export const TableRewardCreate = ({
  translate,
  listReward = [],
  refreshData,
  notify,
  catalogue_id,
  classes,
}) => {
  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>{translate("resources.rewards.fields.name")}</TableCell>
          <TableCell>
            {translate("resources.rewards.fields.merchant_name")}
          </TableCell>
          <TableCell>{translate("resources.rewards.fields.price")}</TableCell>
          <TableCell style={{ width: 40 }}>
            {translate("resources.rewards.fields.added")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listReward.map((row, index) => {
          return (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>
                <RaReferenceTextField
                  label=""
                  recordId={row.merchant_id}
                  source="name"
                  reference="merchants"
                  defaultValue=""
                />
              </TableCell>
              <TableCell>{row.merchant_offer.price}</TableCell>
              <TableCell>
                {row.isAdded ? (
                  <Flex flexDirection={"row"} justifyContent={"center"}>
                    <CheckIcon style={{ fontSize: 12 }} />
                  </Flex>
                ) : (
                  <AddButton
                    values={row}
                    notify={notify}
                    catalogue_id={catalogue_id}
                    refresh={refreshData}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
