import React from "react"
import {
  TextField,
  ReferenceField,
  useTranslate,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin"
import { get } from "lodash"
import { Box, Flex } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import {
  BetterList,
  CreateButton,
  Title,
  BetterShowButton,
  BetterEditButton,
} from "../components"
import { AddIcon, CheckIcon, CloseIcon } from "../components/icon_svg"
import { ROLE_USER, getProfile } from "../utils"
export { create as UserCreate, edit as UserEdit } from "./Create"
export { UserShow } from "./Show"

const ButtonShowEditPermissionView = (props) => {
  const { record, permissions } = props
  if (
    permissions === ROLE_USER.super_admin &&
    get(record, "role", "") === ROLE_USER.account_admin
  ) {
    return (
      <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
        <BetterShowButton {...props} />
        <BetterEditButton {...props} />
      </Flex>
    )
  }
  if (
    permissions === ROLE_USER.client_admin &&
    get(record, "role", "") === ROLE_USER.loyalty_program_admin
  ) {
    return (
      <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
        <BetterShowButton {...props} />
        <BetterEditButton {...props} />
      </Flex>
    )
  }
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <BetterShowButton {...props} />
      {props.permissions === ROLE_USER.account_admin ? (
        <BetterEditButton {...props} />
      ) : (
        <Box style={{ width: 28 }} />
      )}
    </Flex>
  )
}

const UsersAction = ({ basePath, permissions }) => {
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"flex-end"}
      style={{ minHeight: 80 }}
      pt={[4]}
    >
      <Box />
      <Box>
        {/* */}
        {(permissions === ROLE_USER.super_admin ||
          permissions === ROLE_USER.account_admin ||
          permissions === ROLE_USER.client_admin) && (
          <CreateButton
            basePath={basePath}
            icon={<AddIcon />}
            sizeIcon={"xsmall"}
            style={{ color: lightBlue[700] }}
          />
        )}
      </Box>
    </Flex>
  )
}

const UsersFilter = ({ permissions, ...props }) => {
  const translate = useTranslate()
  return (
    <Box pt={[3]}>
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.users.name`)}
        </Title>
      </Box>
      <Filter
        {...props}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 16,
          paddingRight: 16,
        }}
      >
        {permissions === ROLE_USER.super_admin && (
          <ReferenceInput
            label="Loyalty Program"
            source="loyalty_program_id"
            reference="loyaltyPrograms"
            alwaysOn
            allowEmpty
            style={{ width: 300 }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          label="Search"
          source="search"
          alwaysOn
          style={{ minWidth: 180 }}
        />
      </Filter>
    </Box>
  )
}

export const StatusField = ({ record }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {record.status === 1 ? (
        <CheckIcon style={{ fontSize: 12 }} />
      ) : (
        <CloseIcon style={{ fontSize: 12 }} />
      )}
    </div>
  )
}
export const UserList = (props) => {
  const profile = getProfile()
  const { permissions } = props
  const filter =
    profile && permissions === ROLE_USER.account_admin
      ? { account_id: profile.account_id }
      : {}
  return (
    <BetterList
      {...props}
      filter={filter}
      actions={<UsersAction permissions={props.permissions} />}
      filters={<UsersFilter permissions={props.permissions} />}
    >
      {props.permissions === ROLE_USER.super_admin && (
        <ReferenceField
          source="custom.loyalty_program_id"
          reference="loyaltyPrograms"
          link={false}
          label={"resources.users.fields.loyalty_program_name"}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField
        source="first_name"
        label={"resources.users.fields.first_name"}
      />
      <TextField
        source="last_name"
        label={"resources.users.fields.last_name"}
      />
      <TextField source="email" label={"resources.users.fields.email"} />
      <TextField source="role" label={"resources.users.fields.role"} />
      <ButtonShowEditPermissionView {...props} />
    </BetterList>
  )
}
