import React from "react"
import { Box } from "rebass"
import Paper from "@material-ui/core/Paper"
import { RaTextInput, CollapseMui, Title } from "../../components"
import { useStyles, BirthDaysContainer, TagsRules } from "./"

export const CollapsePaper = props => {
  const { updateField, translate, ...rest } = props
  const classes = useStyles()
  return (
    <Box>
      <CollapseMui
        label={translate("resources.catalogues.info.member_eligibility_rules")}
      >
        <Paper className={classes.paper}>
          <Box width={[1]} p={[2]}>
            <Box>
              <Title fontSize={12} pb={[2]}>
                {translate(
                  "resources.catalogues.info.member_eligibility_rules_title"
                )}
              </Title>

              <Box p={[2]} pl={[0]}>
                <Title fontWeight={"bold"} fontSize={12}>
                  {translate("resources.catalogues.info.tag_rules")}
                </Title>
              </Box>
              <TagsRules
                {...rest}
                classes={classes}
                translate={translate}
                updateField={updateField}
              />

              <Box p={[2]} pl={[0]}>
                <Title fontWeight={"bold"} fontSize={12}>
                  {translate("resources.catalogues.info.birthday_rules")}
                </Title>
              </Box>
              <BirthDaysContainer
                classes={classes}
                {...rest}
                translate={translate}
              />
              <RaTextInput
                source="restrictred_to_levels"
                style={{ width: 300 }}
                notetext="resources.catalogues.message.restrictred_to_levels"
              />
            </Box>
          </Box>
        </Paper>
      </CollapseMui>
    </Box>
  )
}
