import React from "react"
import { TextField, DateField, BooleanField, useTranslate } from "react-admin"
import { Box, Flex } from "rebass"
import {
  BetterList,
  ButtonEditView,
  Title,
  BetterDownloadCSVButton,
  BetterChartButton,
  CreateButton,
} from "../components"

const ProgrammeAction = () => {
  const translate = useTranslate()
  return (
    <Box width={[1]} pt={[3]}>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.programmes.name`)}
        </Title>
        <CreateButton basePath={"/programmes"} />
      </Flex>
    </Box>
  )
}
const ButtonEditCartView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterDownloadCSVButton {...props} isCustomLink={true} chartLink="csv" />
    <BetterChartButton {...props} isCustomLink={true} />
    <ButtonEditView {...props} />
  </Flex>
)

const ProgrammeList = (props) => (
  <BetterList {...props} actions={<ProgrammeAction />}>
    <TextField source="name" />
    <BooleanField source="active" />
    <DateField
      source="created_at"
      options={{
        day: "numeric",
        month: "short",
        year: "numeric",
      }}
    />
    <ButtonEditCartView {...props} />
  </BetterList>
)
export default ProgrammeList
