import React from "react"
import SubMenu from "./SubMenu"
import MenuItemLink from "./MenuItemLink"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import {
  CaseIcon,
  StarIcon,
  BagIcon,
  SettingsIcon,
  UserIcon,
  BadgeIcon,
} from "../icon_svg"
/**DocumentIcon,
  FilterIcon, 
  ViewIcon,*/
export const SuperAdminMenu = ({
  onMenuClick,
  handleToggle,
  menuSettings,
  open,
  translate,
}) => {
  return (
    <div style={{ backgroundColor: "#EFEFEF" }}>
      <MenuItemLink
        to={`/accounts`}
        primaryText={translate(`resources.menus.accounts`, {
          smart_count: 2,
        })}
        leftIcon={<CaseIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/clients`}
        primaryText={translate(`resources.menus.clients`, {
          smart_count: 2,
        })}
        leftIcon={<StarIcon />}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/catalogues`}
        primaryText={translate(`resources.menus.catalogues`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
      /> */}
      <MenuItemLink
        to={`/merchants`}
        primaryText={translate(`resources.menus.merchants`, {
          smart_count: 2,
        })}
        leftIcon={<BagIcon />}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/members`}
        primaryText={translate(`resources.menus.members`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
      /> */}
      <SubMenu
        handleToggle={() => handleToggle()}
        isOpen={menuSettings}
        sidebarIsOpen={open}
        name="resources.menus.admin"
        icon={<SettingsIcon />}
      >
        {/* <MenuItemLink
          to={`/register`}
          primaryText={translate(`resources.menus.registrations`, {
            smart_count: 2,
          })}
          leftIcon={<DocumentIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/provisions`}
          primaryText={translate(`resources.menus.provisions`, {
            smart_count: 2,
          })}
          leftIcon={<FilterIcon />}
          onClick={onMenuClick}
        /> */}
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.menus.users`, {
            smart_count: 2,
          })}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
    </div>
  )
}

export const AccountAdminMenu = ({
  onMenuClick,
  handleToggle,
  menuSettings,
  open,
  translate,
}) => {
  return (
    <div style={{ backgroundColor: "#EFEFEF" }}>
      <MenuItemLink
        to={`/clients`}
        primaryText={translate(`resources.menus.clients`, {
          smart_count: 2,
        })}
        leftIcon={<StarIcon />}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/catalogues`}
        primaryText={translate(`resources.menus.catalogues`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
      /> */}
      <MenuItemLink
        to={`/merchants`}
        primaryText={translate(`resources.menus.merchants`, {
          smart_count: 2,
        })}
        leftIcon={<BagIcon />}
        onClick={onMenuClick}
      />
      {/* <MenuItemLink
        to={`/members`}
        primaryText={translate(`resources.menus.members`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
      /> */}
      <SubMenu
        handleToggle={() => handleToggle()}
        isOpen={menuSettings}
        sidebarIsOpen={open}
        name="resources.menus.admin"
        icon={<SettingsIcon />}
      >
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.menus.users`, {
            smart_count: 2,
          })}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
    </div>
  )
}
export const AccountClientMenu = ({
  onMenuClick,
  translate,
  menuSettings,
  handleToggle,
  open,
}) => {
  return (
    <div style={{ backgroundColor: "#EFEFEF" }}>
      {/* <MenuItemLink
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        leftIcon={<Chart8 />}
        onClick={onMenuClick}
      /> */}
      <MenuItemLink
        to={`/programmes`}
        primaryText={translate(`resources.menus.programmes`, {
          smart_count: 2,
        })}
        leftIcon={<BadgeIcon />}
        onClick={onMenuClick}
      />
      <SubMenu
        handleToggle={() => handleToggle()}
        isOpen={menuSettings}
        sidebarIsOpen={open}
        name="resources.menus.admin"
        icon={<SettingsIcon />}
      >
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.menus.users`, {
            smart_count: 2,
          })}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      {/* */}
      {/* <MenuItemLink
        to={`/catalogues`}
        primaryText={translate(`resources.menus.catalogues`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
      /> */}

      {/* <MenuItemLink
        to={`/members`}
        primaryText={translate(`resources.menus.members`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
      /> */}
    </div>
  )
}

export const LoyaltyProgramAdminMenu = ({
  onMenuClick,
  translate,
  profile,
}) => {
  let urlLink = `/programmes`
  if (profile && (profile !== null || profile !== "")) {
    const data = JSON.parse(profile)
    urlLink = `/programmes/${data.loyalty_program_id}`
  }
  return (
    <div style={{ backgroundColor: "#EFEFEF" }}>
      <MenuItemLink
        to={urlLink}
        primaryText={translate(`resources.menus.programmes`, {
          smart_count: 2,
        })}
        leftIcon={<BadgeIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/download/csv`}
        primaryText={translate(`resources.menus.download_report`, {
          smart_count: 2,
        })}
        leftIcon={<CloudDownloadIcon />}
        onClick={onMenuClick}
      />
    </div>
  )
}
