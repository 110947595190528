export { GeneralSpendingRule } from "./GeneralSpendingRule"
export { MultiplyEarnedPoints } from "./MultiplyEarnedPoints"
export { MemberReferral } from "./MemberReferral"
export { MemberRegister } from "./MemberRegister"
export const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["blockquote"],
  ["clean"],
  ["image"],
]
