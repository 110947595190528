export default {
  users: {
    name: "Users",
    fields: {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile: "Phone number",
      role: "Role",
      status: "Status",
      created_at: "Create On",
      branch: "Branch",
      account: "Account",

      custom: {
        merchant: "Merchant",
        branch: "Branch",
        client: "Client",
      },
      loyalty_program_name: "Loyalty Program Name",
    },
  },
}
