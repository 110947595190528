export default {
  catalogues: {
    name: "Reward Catalogues",
    dashboard_name: "Catalog Dashboard",
    tabs: {
      info: "Catalogue Info",
      rewards: "Rewards",
      merchants: "Merchants",
    },
    info: {
      member_eligibility_rules: "Member Eligibility Rules",
      member_eligibility_rules_title:
        "Configure the rules whether to show this catalogue",
      tag_rules: "Tag Rules",
      birthday_rules: "Birthday Rules",
      day: "Day",
      days: "Days",
      month: "Month",
      months: "Months",
    },
    fields: {
      active: "Active",
      name: "Catalogue Name",
      publish_date: "Publish Date",
      description: "Description",
      terms: "Terms",
      banner: "Banners",
      tag_inclusion_exclusion: "Tag Inclusion or Exclusion",
      tags: "Tags",
      visible: "Check Birthday",
      day_before: "# of days before birthday month",
      valid_for: "Valid for",
      restrictred_to_levels: "Restrictred to levels",
      created_at: "Published On",
      merchant_name: "Merchant Name",
      product_name: "Name",
      merchant: "Merchant",
      start_at: "Start",
      end_at: "End",
    },
    tabmerchant: {
      name: "Name",
      price: "Price",
    },
    message: {
      date_start: "Start Date Time",
      date_end: "End Date Time",
      tag_inclusion_exclusion: "Member with or without of the follwong tag(s)",
      tags: "Tag(s) to be included or excluded in the restriction rules",
      visible: "Visible to member",
      day_before:
        "Eg. Put 7 to show catalogue 1 week before member’s birthday month",
      valid_for: "Numbe of months to show during member’s birthday month",
      restrictred_to_levels: "Restricted to members in certain level",
    },
  },
}
