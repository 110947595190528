import debounce from "lodash/debounce"
import React, { Component } from "react"
import PropTypes from "prop-types"
import Quill from "quill"
import { addField } from "ra-core"
import { translate } from "react-admin"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Snackbar from "@material-ui/core/Snackbar"
import { red } from "@material-ui/core/colors"
import { withStyles } from "@material-ui/core/styles"
import { ImageUpload } from "./ImageUpload"
import styles from "./styles"
import { InputLabel } from "@material-ui/core"

export class RTInputField extends Component {
  lastValueChange = null

  static propTypes = {
    addLabel: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    source: PropTypes.string,
    toolbar: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.shape({
        container: PropTypes.array,
        handlers: PropTypes.object,
      }),
    ]),
    fullWidth: PropTypes.bool,
  }

  static defaultProps = {
    addLabel: true,
    options: {}, // Quill editor options
    record: {},
    toolbar: true,
    fullWidth: true,
  }
  constructor(props) {
    super(props)
    this.state = {
      isEditMode: true,
      open: false,
      message: "",
      isUploadImage: false,
    }
  }
  handleRequestClose = () => {
    this.setState({
      open: false,
    })
  }

  componentDidMount() {
    const {
      input: { value },
      toolbar,
      options,
    } = this.props

    Quill.register(
      {
        "modules/imageUpload": ImageUpload,
      },
      true
    )

    this.quill = new Quill(this.divRef, {
      modules: {
        toolbar,
        clipboard: { matchVisual: false },
        imageUpload: {
          resource: this.props.resource,
          /**
           * personalize successful callback and call next function
           * to insert new url to the editor
           */
          callbackOK: (serverResponse, next) => {
            // console.log(serverResponse)
            if (serverResponse !== "") {
              const range = this.quill.getSelection(true)
              const index = range.index + range.length
              this.quill.insertEmbed(index, "image", serverResponse)
              this.setState({
                isUploadImage: false,
                open: false,
              })
            }
            next(serverResponse)
          },
          // personalize failed callback
          callbackKO: serverError => {
            // console.error(serverError)
            const message =
              serverError && serverError.body
                ? serverError.body
                : "Please try again"
            this.setState({
              message: message,
              isUploadImage: false,
              open: true,
            })
          },
          // optional
          // add callback when a image have been chosen
          checkBeforeSend: (file, next) => {
            const limit = 3
            const limitFileSize = megabytes => {
              const mb = 1024
              megabytes *= mb
              const isExceeding = file.size / mb > megabytes
              const fileSize = (file.size / mb / mb).toFixed(2)
              return { isExceeding, fileSize }
            }

            if (limitFileSize(limit).isExceeding) {
              // console.error(`File is exceeding limit size.`)
              this.setState({
                message: "File is exceeding limit size.",
                isUploadImage: false,
                open: true,
              })
              return next(file)
            }
            this.setState({
              isUploadImage: true,
            })
            return next(file)
          },
        },
      },
      theme: "snow",

      ...options,
    })

    this.quill.setContents(this.quill.clipboard.convert(value))

    this.editor = this.divRef.querySelector(".ql-editor")
    this.quill.on("text-change", debounce(this.onTextChange, 500))
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.input.value !== this.lastValueChange
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      const selection = this.quill.getSelection()
      this.quill.setContents(
        this.quill.clipboard.convert(this.props.input.value)
      )
      if (selection && this.quill.hasFocus()) {
        this.quill.setSelection(selection)
      }
    }
  }

  componentWillUnmount() {
    this.quill.off("text-change", this.onTextChange)
    this.quill = null
  }

  onTextChange = () => {
    const value =
      this.editor.innerHTML === "<p><br></p>" ? "" : this.editor.innerHTML
    this.lastValueChange = value
    this.props.input.onChange(value)
  }

  updateDivRef = ref => {
    this.divRef = ref
  }

  render() {
    const { label, source, addLabel } = this.props
    const { error, helperText = false } = this.props.meta
    return (
      <div style={{ paddingTop: 16 }}>
        {addLabel && (
          <InputLabel style={{ fontWeight: "bold" }}>
            {label || source}
          </InputLabel>
        )}
        <FormControl
          error={error !== null && error !== undefined}
          fullWidth={this.props.fullWidth}
          className="ra-rich-text-input-description"
        >
          <div data-testid="quill" ref={this.updateDivRef} />
          {error && <FormHelperText error>{error}</FormHelperText>}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
        <Snackbar
          open={this.state.open}
          message={this.state.message}
          autoHideDuration={4000}
          bodyStyle={{ backgroundColor: red[500] }}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    )
  }
}

const RTDescription = addField(withStyles(styles)(RTInputField))

RTDescription.defaultProps = {
  addLabel: true,
  fullWidth: true,
}
export default translate(RTDescription)
