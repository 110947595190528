import React from "react"
import { Route } from "react-router-dom"
import { BranchCreate, BranchEdit } from "./branches"
import { ProductCreate, ProductEdit, ProductChart } from "./products"
import {
  MerchantChart,
  MerchantChartShow,
  CataloguesMerchantDetail,
} from "./merchants"
import { RewardEdit, RewardChart, RewardCreate } from "./rewards"
import {
  ProgrammeChart,
  ProgrammeCreate,
  ProgrammeDownloadCSV,
  // ProgrammeLPACreate,
} from "./programmes"
import { LevelCreate, LevelEdit, LevelMember } from "./levels"
import { EarningRulesCreate, EarningRulesEdit } from "./earningRules"
import { MemberCreate, MemberEdit, MemberProfile } from "./members"
import { CatalogCreate, CatalogChart } from "./catalogues"
import { CreateMerchantClient, EditMerchantClient } from "./merchantClients"

// import {
//   RegistrationSetup,
//   ApprovedRegistration,
//   RegistrationPage,
// } from "./registrationsetup"

export default [
  <Route
    exact
    path="/merchants/:merchant_id/chart"
    render={() => <MerchantChart />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/chartShow"
    render={() => <MerchantChartShow />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/branches/create"
    render={() => <BranchCreate />}
  />,

  <Route
    exact
    path="/merchants/:merchant_id/branches/:id"
    render={() => <BranchEdit />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/products/create"
    render={() => <ProductCreate />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/client/create"
    render={() => <CreateMerchantClient />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/products/:id"
    render={() => <ProductEdit />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/client/:id"
    render={() => <EditMerchantClient />}
  />,
  <Route
    exact
    path="/merchants/:merchant_id/products/:id/chart"
    render={() => <ProductChart />}
  />,
  <Route
    exact
    path="/catalogues/:catalogue_id/rewards/create"
    render={() => <RewardCreate />}
  />,
  <Route
    exact
    path="/catalogues/:catalogue_id/rewards/:id"
    render={() => <RewardEdit />}
  />,

  <Route
    exact
    path="/catalogues/:catalogue_id/rewards/:id/chart"
    render={() => <RewardChart />}
  />,
  <Route
    exact
    path="/catalogues/:catalogue_id/merchants/:id"
    render={() => <CataloguesMerchantDetail />}
  />,
  <Route
    exact
    path="/clients/:client_id/programmes/create"
    render={() => <ProgrammeCreate />}
  />,
  <Route
    exact
    path="/clients/:client_id/programmes/:id/chart"
    render={() => <ProgrammeChart />}
  />,
  <Route
    exact
    path="/clients/:client_id/programmes/:id/csv"
    render={() => <ProgrammeDownloadCSV />}
  />,
  <Route
    exact
    path="/programmes/:program_id/catalog/create"
    render={() => <CatalogCreate />}
  />,
  <Route
    exact
    path="/programmes/:program_id/members/create"
    render={() => <MemberCreate />}
  />,
  <Route
    exact
    path="/programmes/:program_id/catalog/:id/chart"
    render={() => <CatalogChart />}
  />,
  <Route
    exact
    path="/programmes/:program_id/members/:id"
    render={() => <MemberEdit />}
  />,
  <Route
    exact
    path="/programmes/:program_id/members/:id/profile"
    render={() => <MemberProfile />}
  />,

  <Route
    exact
    path="/programmes/:program_id/levels/create"
    render={() => <LevelCreate />}
  />,
  <Route
    exact
    path="/programmes/:program_id/levels/:id"
    render={() => <LevelEdit />}
  />,
  <Route
    exact
    path="/programmes/:program_id/levels/:id/members"
    render={() => <LevelMember />}
  />,
  <Route
    exact
    path="/programmes/:program_id/earningRules/create"
    render={() => <EarningRulesCreate />}
  />,
  <Route
    exact
    path="/programmes/:program_id/earningRules/:id"
    render={() => <EarningRulesEdit />}
  />,
  <Route
    exact
    path="/programmes/:id/csv"
    render={() => <ProgrammeDownloadCSV />}
  />,
  <Route
    exact
    path="/programmes/:id/chart"
    render={() => <ProgrammeChart />}
  />,
  // <Route
  //   exact
  //   path="/programmes/:id/user"
  //   render={() => <ProgrammeLPACreate />}
  // />,

  <Route exact path="/download/csv" render={() => <ProgrammeDownloadCSV />} />,
  // <Route exact path="/setup" render={() => <RegistrationSetup />} noLayout />,
  // <Route
  //   exact
  //   path="/registration"
  //   render={() => <RegistrationPage />}
  //   noLayout
  // />,
  // <Route
  //   exact
  //   path="/approved"
  //   render={() => <ApprovedRegistration />}
  //   noLayout
  // />,
]
