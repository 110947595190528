import React from "react"
import { required, useTranslate, SelectInput } from "react-admin"
import { Box } from "rebass"
import {
  RaTextInput,
  RaBooleanInput,
  RaTextArea,
  categories,
  typeMerchants,
  RaNumberInput,
  ImageOlaInput,
  CollapseMui,
} from "../../components"
import { CollapseContent } from "./CollapseContent"
import { useStyles } from "./Form"

export const MyTab = props => {
  // console.log(props)
  const classes = useStyles()

  const translate = useTranslate()
  const { filterMerchant } = props
  return (
    <Box width={[1]}>
      <RaBooleanInput source="active" id={"active"} />
      <RaTextInput
        source="name"
        validate={required()}
        style={{ minWidth: 300 }}
      />
      <Box width={[1]}>
        <ImageOlaInput
          source="photos"
          toValue="photos"
          labelImage={translate("resources.products.fields.photos")}
          label={"resources.products.fields.photos"}
          accept="image/*"
          key="photos"
          {...props}
        />
      </Box>
      <Box>
        <SelectInput
          source={"category"}
          choices={categories}
          style={{ minWidth: 200 }}
        />
      </Box>

      <RaTextArea
        source="remarks"
        label="resources.products.fields.remarks"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <RaTextArea
        source="terms"
        label="resources.products.fields.terms"
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <Box>
        <SelectInput
          source={"type"}
          choices={typeMerchants}
          style={{ minWidth: 200 }}
        />
      </Box>
      <RaNumberInput
        source="points"
        label={"resources.products.fields.point_need"}
        notetext="resources.products.note_message.point_need"
      />
      <RaNumberInput
        source="value"
        label={"resources.products.fields.reward_value"}
        notetext="resources.products.note_message.reward_value"
      />
      {/* <RaNumberInput
        source="expiry_days"
        label={"resources.products.fields.expiry_days"}
      /> */}
      <Box width={[1]}>
        <CollapseMui label={translate("resources.products.title_expand")}>
          <CollapseContent
            {...props}
            filterMerchant={filterMerchant}
            classes={classes}
          />
        </CollapseMui>
      </Box>
    </Box>
  )
}
