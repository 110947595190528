export const styles = {
  preview: {
    float: "left",
  },
  dropzone: {
    width: 300,
    height: 200,
    borderWidth: 1,
    borderColor: "#D6D6D6",
    borderStyle: "dashed",
    cursor: "pointer",
    borderRadius: 5,
  },
  insideBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "inherit",
    fontSize: 12,
  },
  container: {
    display: "inline-block",
    position: "relative",
    padding: 5,
    paddingTop: 0,
  },
  removeButton: {
    position: "absolute",
    minWidth: "2rem",
    opacity: 1,
  },
  removeButtonHovered: {
    opacity: 1,
  },
  gridImage: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 0,
    paddingBottom: 10,
  },
  image: {
    maxHeight: "160px",
    margin: "0.2rem",
    marginTop: 0,
    maxWidth: "100%",
  },
  images: {
    maxHeight: "10rem",
    margin: "0.2rem",
    maxWidth: "60%",
  },
}
