export default {
  dashboard: {
    title: {
      new_member: "New Member",
    },
    field: {
      total_member: "Total Customer",
      total_spend: "Total Points Spend",
      total_order: "Total Orders",
      total_earn: "Total Points Earned",
      daily_member: "Daily new member",
    },
    fields: {
      total_redemption: "Total Redemption",
      total_consumption: "Total Consumption",
    },
    label: {
      start: "Start",
      redemption: "Redemption",
      consumption: "Consumption",
      end: "End",
    },
  },
}
