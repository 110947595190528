import React from "react"
import { get } from "lodash"
import { useQueryWithStore } from "react-admin"
import TextRecord from "../../components/ra-field/TextRecord"
const ClientField = (props) => {
  const { record } = props
  const { loading, data } = useQueryWithStore({
    type: "getOne",
    resource: "clients",
    payload: { id: get(record, "client_id", 0) },
  })

  if (loading) {
    return <div />
  }
  return (
    <TextRecord
      source={"name"}
      label={"resources.merchants.fields.client"}
      record={data}
    />
  )
}

export default ClientField
