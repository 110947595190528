import React from "react"
import { SaveButton, Toolbar, useTranslate } from "react-admin"
import { Box } from "rebass"
import {
  BetterCustomSimpleForm,
  RaBooleanInput,
  ImageOlaInput,
} from "../../components"
import { CollapseFields } from "./CollapseFields"
import { ListProducts } from "./ListProducts"

const RewardEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const Form = props => {
  const translate = useTranslate()
  return (
    <BetterCustomSimpleForm {...props} toolbar={<RewardEditToolbar />}>
      <Box width={[1]} pb={[2]}>
        <ImageOlaInput
          source="product.photos"
          toValue="product.photos"
          labelImage={translate("resources.products.fields.photos")}
          label={"resources.products.fields.photos"}
          accept="image/*"
          key="photos"
          {...props}
        />
      </Box>
      <ListProducts {...props} />
      <RaBooleanInput
        source="product.active"
        label={"resources.products.fields.active"}
        id={"active"}
      />

      <CollapseFields {...props} translate={translate} />
    </BetterCustomSimpleForm>
  )
}

export default Form
