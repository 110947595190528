import React, { Component } from "react"
import { Flex, Box, Text } from "rebass"
import { get } from "lodash"
import moment from "moment"
import Card from "@material-ui/core/Card"
import DollarIcon from "@material-ui/icons/AttachMoney"
import OrderIcon from "@material-ui/icons/ShoppingCart"
import MemberIcon from "@material-ui/icons/People"
import EarnIcon from "@material-ui/icons/Redeem"
import CardContent from "@material-ui/core/CardContent"
import { useTranslate } from "react-admin"
import { Title } from "react-admin"
import { cyan, pink, blue, indigo } from "@material-ui/core/colors"

import { RestApi } from "../restClient"
import { ContainerCard, ChartDailyMember } from "./components"
import { DatePicker } from "antd"

const { RangePicker } = DatePicker
// const dateFormat = "DD-MMM-YYYY"

const TitleField = () => {
  const translate = useTranslate()
  return (
    <Flex flexDirection={"row"} p={[1]} pt={[4]}>
      <Text width={["140px"]} fontSize={[0]}>
        {translate("resources.dashboard.label.start")}
      </Text>
      <Text fontSize={[0]}>{translate("resources.dashboard.label.end")}</Text>
    </Flex>
  )
}

class ProgrammeDashboard extends Component {
  constructor(props) {
    super(props)
    const start = moment().add(-7, "days")
    this.state = {
      totalField: {},
      dailyNewMember: [],
      label: [],
      dataSet: [],
      indexFilter: 3,
      start: start,
      end: moment(),
    }
  }

  componentDidMount() {
    // this.getListDaily(this.state.indexFilter)
    this.getTotalDaily(this.state.start, this.state.end)
    this.getNewListDaily(this.state.start, this.state.end)
  }
  getNewListDaily = (start, end) => {
    const { match } = this.props
    const LoyaltyProgramId = get(match, "params.id", 0)
    const newStart = moment(start).format("YYYY-MM-DD")
    const newEnd = moment(end).format("YYYY-MM-DD")
    const url = `/dashboards/dailyNewMembers?start=${encodeURIComponent(
      newStart
    )}&end=${encodeURIComponent(newEnd)}&loyalty_program_id=${LoyaltyProgramId}`
    RestApi(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data && data.length > 0) {
          const sortedArray = data.sort(
            (a, b) =>
              new moment(a.register_date).format("YYYYMMDD") -
              new moment(b.register_date).format("YYYYMMDD")
          )

          const dataSet = sortedArray.map((item) => item.total)
          const newFormat = sortedArray.map((item) =>
            moment(item.register_date).format("DD-MM-YYYY")
          )

          this.setState({
            dailyNewMember: data,
            label: newFormat,
            dataSet: dataSet,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  getTotalDaily = (start, end) => {
    const { match } = this.props
    const LoyaltyProgramId = get(match, "params.id", 0)
    const newStart = moment(start).format("YYYY-MM-DD")
    const newEnd = moment(end).format("YYYY-MM-DD")

    RestApi(
      `/dashboards?start=${encodeURIComponent(
        newStart
      )}&end=${encodeURIComponent(
        newEnd
      )}&loyalty_program_id=${LoyaltyProgramId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // console.log(data)
          this.setState({
            totalField: data,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  onHandleChange = (value) => {
    if (value !== null) {
      if (value[0]) {
        this.setState({
          start: value[0].toISOString(),
        })
      }
      if (value[1]) {
        this.setState({
          end: value[1].toISOString(),
        })
      }
      this.getTotalDaily(this.state.start, this.state.end)
      this.getNewListDaily(this.state.start, this.state.end)
    }
  }
  render() {
    const { totalField, dailyNewMember, label, dataSet } = this.state
    return (
      <Card style={{ marginTop: "1.2em", paddingLeft: 0, paddingRight: 0 }}>
        <Title title="Dashboard" />
        <CardContent>
          <Box width={[1]} pb={[2]}>
            <RangePicker
              placeholder={["Start", "End"]}
              allowClear={false}
              format="DD-MMM-YYYY"
              onChange={this.onHandleChange}
              defaultValue={[this.state.start, this.state.end]}
            />
          </Box>
          <Flex width={[1]} flexDirection={["row"]} flexWrap={["wrap"]}>
            <ContainerCard
              title={"total_member"}
              value={get(totalField, "total_member", 0)}
              icon={MemberIcon}
              bgcolorIcon={cyan[500]}
            />
            <ContainerCard
              title={"total_spend"}
              value={get(totalField, "total_spend", 0)}
              icon={DollarIcon}
              bgcolorIcon={indigo[500]}
            />
            <ContainerCard
              title={"total_earn"}
              value={get(totalField, "total_earn", 0)}
              icon={EarnIcon}
              bgcolorIcon={pink[500]}
            />
            <ContainerCard
              title={"total_order"}
              value={get(totalField, "total_order", 0)}
              icon={OrderIcon}
              bgcolorIcon={blue[500]}
            />
          </Flex>
          <TitleField />

          <ChartDailyMember
            {...this.props}
            dailyNewMember={dailyNewMember}
            label={label}
            dataSet={dataSet}
          />
        </CardContent>
      </Card>
    )
  }
}
export default ProgrammeDashboard
