import React from "react"
import { EditForm } from "./components"
import { useTranslate, useQuery, Toolbar, SaveButton } from "react-admin"
import { Flex, Box, Text } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import { ListButton } from "../components/buttons"
import { BetterEdit, FullLoadingScreen } from "../components"

const EditToolbar = (props) => {
  const { redirect1, ...rest } = props
  return (
    <Toolbar {...rest}>
      <SaveButton redirect={redirect1} />
    </Toolbar>
  )
}

const ActionForm = (props) => {
  const translate = useTranslate()
  const { basePath } = props
  // const urlList = `/clients/${data.client_id}${basePath}`
  return (
    <Box width={[1]} py={[3]}>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <Box width={[1]}>
          <Text fontWeight={"bold"} fontSize={[3]}>
            {translate(props.title)}
          </Text>
        </Box>
        <ListButton basepath={basePath} style={{ color: lightBlue[700] }} />
      </Flex>
    </Box>
  )
}

const ProgrammeClientEdit = (props) => {
  const { match, history } = props
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "programmes",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const urlList = `/programmes`
  return (
    <BetterEdit
      {...props}
      actions={<ActionForm title={"resources.programmes.name"} data={data} />}
    >
      <EditForm
        toolbar={<EditToolbar redirect1={() => history.push(urlList)} />}
        account_id={data.account_id}
        {...props}
      />
    </BetterEdit>
  )
}

export default ProgrammeClientEdit
