import React from "react"
import { Box } from "rebass"

export const ChartShow = (props) => (
  <Box>helloworld</Box>
  // <BetterShow
  //   {...props}
  //   actions={<ActionForm title="resources.merchants.name" />}
  // >
  //   <SimpleShowLayout>
  //     <MerchantChart />
  //   </SimpleShowLayout>
  // </BetterShow>
)
