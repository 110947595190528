import { ROLE_USER } from "../utils"

const superRoles = [
  {
    id: ROLE_USER.account_admin,
    name: ROLE_USER.account_admin,
  },
]
/***
 * ,
  {
    id: ROLE_USER.merchant,
    name: ROLE_USER.merchant,
  },
 * { id: ROLE_USER.super_admin, name: ROLE_USER.super_admin },
  {
    id: ROLE_USER.loyalty_program_user,
    name: ROLE_USER.loyalty_program_user,
  },
  {
    id: ROLE_USER.merchant,
    name: ROLE_USER.merchant,
  },
 * {
    id: ROLE_USER.loyalty_program_admin,
    name: ROLE_USER.loyalty_program_admin,
  },
 * {
    id: ROLE_USER.client_admin,
    name: ROLE_USER.client_admin,
  },
 */

const accountRoles = [
  {
    id: ROLE_USER.loyalty_program_admin,
    name: ROLE_USER.loyalty_program_admin,
  },
  {
    id: ROLE_USER.client_admin,
    name: ROLE_USER.client_admin,
  },
  {
    id: ROLE_USER.merchant,
    name: ROLE_USER.merchant,
  },
]
const clientRoles = [
  {
    id: ROLE_USER.loyalty_program_admin,
    name: ROLE_USER.loyalty_program_admin,
  },
  // {
  //   id: ROLE_USER.client_admin,
  //   name: ROLE_USER.client_admin,
  // },
]

export const roles = (permissions) => {
  if (permissions === ROLE_USER.super_admin) {
    return superRoles
  } else if (permissions === ROLE_USER.account_admin) {
    return accountRoles
  } else if (permissions === ROLE_USER.client_admin) {
    return clientRoles
  } else {
    return []
  }
}
