import React from "react"
import AppHeader from "./AppHeader"
import { Box, Flex, Image } from "rebass"
import { Layout, Sidebar } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import Menu from "./Menu"

const DRAWER_WIDTH = 256

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: "0em",
    paddingLeft: "2rem",
  },
}))

const MySidebar = (props) => {
  return (
    <Box
      width={DRAWER_WIDTH}
      style={{ backgroundColor: "#EFEFEF", height: "100%" }}
    >
      <Flex
        width={DRAWER_WIDTH}
        flexDirection={"column"}
        alignItems={"streach"}
      >
        <Box
          style={{
            position: "fixed",
            backgroundColor: "#EFEFEF",
            top: 0,
            width: DRAWER_WIDTH,
            zIndex: 2000,
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
            height: "10rem",
          }}
        >
          <Flex width={[1]} justifyContent={"center"} pb={[1]}>
            <Image
              src={require("../../assets/images/e-buy.svg")}
              height={120}
            />
          </Flex>
        </Box>
        <Box>
          <Sidebar
            {...props}
            size={DRAWER_WIDTH}
            closedSize={DRAWER_WIDTH}
            style={{
              overflowY: "scroll",
              position: "fixed",
              marginTop: "7rem",
              height: "100%",
              backgroundColor: "#EFEFEF",
              zIndex: 2001,
            }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default (props) => {
  const classes = useStyles()
  return (
    <Layout
      {...props}
      classes={classes}
      appBar={AppHeader}
      sidebar={MySidebar}
      menu={Menu}
      style={{ padding: 0 }}
    />
  )
}
