import React from "react"

import { useQueryWithStore } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FormLPA } from "./components"
import { FullLoadingScreen, NoAccessPage, ActionForm } from "../components"

const ProgrammeLPACreate = (props) => {
  const { match } = props
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "programmes",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const params = {
    role: "Loyalty Program Admin",
    custom: {
      loyalty_program_id: data && data.id ? data.id : parseInt(match.params.id),
    },
  }

  return (
    <Box width={[1]} p={[2]}>
      <ActionForm
        title={"resources.programmes.info.assign_loyalty_program_admin"}
        basePath={`/programmes`}
      />
      {data && data.id ? (
        <FormLPA {...props} typePost={"CREATE"} initialValues={params} />
      ) : (
        <NoAccessPage />
      )}
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, {}))

export default enhance(ProgrammeLPACreate)
