import React, { Fragment } from "react"
import { Box } from "rebass"
import { TextInput, SelectInput, FormDataConsumer, required } from "react-admin"

import {
  BetterCustomSimpleForm,
  RTDescription,
  RaBooleanInput,
} from "../components"
import {
  GeneralSpendingRule,
  MultiplyEarnedPoints,
  MemberReferral,
  MemberRegister,
  toolbar,
} from "./components"

const MainTab = props => {
  const { updateField, ...checkProps } = props
  return (
    <Box>
      <RaBooleanInput source="active" />
      <TextInput source="name" validate={required()} fullWidth />
      <RTDescription
        source="description"
        label="Description"
        toolbar={toolbar}
        key="Description"
      />

      <SelectInput
        source="type"
        choices={[
          { id: "General Spending Rule", name: "General Spending Rule" },
          { id: "Multiply Earned Points", name: "Multiply Earned Points" },
          { id: "Member Referral", name: "Member Referral" },
          { id: "Member Register", name: "Member Register" },
        ]}
        validate={required()}
        style={{ minWidth: 200 }}
      />

      <FormDataConsumer>
        {({ formData, translate, ...rest }) => {
          return (
            <Fragment>
              {formData.type === "General Spending Rule" && (
                <GeneralSpendingRule
                  formData={formData}
                  {...checkProps}
                  resource={props.sourceApi}
                />
              )}
              {formData.type === "Multiply Earned Points" && (
                <MultiplyEarnedPoints
                  formData={formData}
                  {...checkProps}
                  resource={checkProps.sourceApi}
                />
              )}
              {formData.type === "Member Referral" && (
                <MemberReferral
                  formData={formData}
                  {...checkProps}
                  resource={props.sourceApi}
                  updateField={updateField}
                />
              )}
              {formData.type === "Member Register" && (
                <MemberRegister
                  formData={formData}
                  {...rest}
                  resource={props.sourceApi}
                />
              )}
            </Fragment>
          )
        }}
      </FormDataConsumer>
    </Box>
  )
}

export const Form = props => (
  <BetterCustomSimpleForm {...props}>
    <MainTab {...props} />
  </BetterCustomSimpleForm>
)

export const earningRulesDefaulValue = {
  all_time_active: true,
  member_referral: {
    event_name: "",
    reward: "",
    points: 0,
    multi_level_option: {
      number_of_levels: 1,
      levels: [0],
    },
  },
}
