import React from "react"
import {
  Datagrid,
  DateField,
  FormTab,
  TextField,
  ReferenceField,
} from "react-admin"

import { Flex } from "rebass"
import { Pagination } from "react-admin"
import {
  BetterEditButton,
  BetterTabbedForm,
  RaReferenceManyField,
  BetterChartButton,
} from "../../components"
import MainTab from "./MainTab"

const ListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ButtonEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterEditButton {...props} isCustomLink={true} taburl="branches" />
  </Flex>
)

const ButtonEditCartView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartButton {...props} isCustomLink={true} taburl="products" />
    <BetterEditButton {...props} isCustomLink={true} taburl="products" />
  </Flex>
)

const ButtonEditClientView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <BetterEditButton {...props} isCustomLink={true} taburl="client" />
    </Flex>
  )
}

const PriceField = ({ record = {} }) => (
  <span>{record.cost + record.margin}</span>
)
PriceField.defaultProps = {
  label: "Price",
}

const EditForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    bashPath,
    ...checkProps
  } = props
  return (
    <BetterTabbedForm {...props}>
      <FormTab label="resources.merchants.tabs.merchant_info">
        <MainTab {...props} />
      </FormTab>
      <FormTab label="resources.merchants.tabs.branches" path="branches">
        <RaReferenceManyField
          addLabel={false}
          reference="branches"
          target="merchant_id"
          pagination={<ListPagination />}
          fullWidth
          filter={{ search: "" }}
        >
          <Datagrid>
            <TextField source="name" />
            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <ButtonEditView location={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.merchants.tabs.products" path="products">
        <RaReferenceManyField
          addLabel={false}
          reference="products"
          target="merchant_id"
          fullWidth
          pagination={<ListPagination />}
          filter={{ search: "" }}
        >
          <Datagrid style={{ width: "100%" }}>
            <TextField source="name" />
            <TextField source="category" />
            <TextField
              label={"resources.products.fields.cost"}
              source="merchant_offer.cost"
            />
            <TextField
              label={"resources.products.fields.price"}
              source="merchant_offer.price"
            />
            <TextField
              label={"resources.products.fields.margin"}
              source="merchant_offer.margin"
            />
            <DateField
              source="created_at"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <ButtonEditCartView location={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label="resources.merchants.tabs.client" path="client">
        <RaReferenceManyField
          addLabel={false}
          reference="merchantClients"
          target="merchant_id"
          fullWidth
          pagination={<ListPagination />}
          filter={{ search: "" }}
        >
          <Datagrid style={{ width: "100%" }}>
            <ReferenceField
              label={"resources.merchants.fields.client"}
              source="client_id"
              reference="clients"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField
              label={"resources.merchants.fields.start_date"}
              source="start_date"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <DateField
              label={"resources.merchants.fields.end_date"}
              source="end_date"
              options={{
                day: "numeric",
                month: "short",
                year: "numeric",
              }}
            />
            <ButtonEditClientView location={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
    </BetterTabbedForm>
  )
}

export default EditForm
