import React from "react"
import {
  TextField,
  ReferenceField,
  DateField,
  ReferenceManyField,
  Datagrid,
  NumberField,
  BooleanField,
} from "react-admin"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

class Transaction extends React.Component {
  state = {
    tabIndex: 0,
  }
  changeTab(e, tabIndex) {
    this.setState({ tabIndex })
  }
  render() {
    return (
      <Card style={{ marginBottom: 8 }}>
        <CardContent>
          <Tabs
            value={this.state.tabIndex}
            onChange={this.changeTab.bind(this)}
          >
            <Tab label="Transactions" />
            <Tab label="Points transfers" />
            <Tab label="Rewards" />
          </Tabs>
          {this.state.tabIndex === 0 && (
            <div>
              <ReferenceManyField
                {...this.props}
                reference="transactions"
                target="member"
                filter={{ type: "credit", contains_order: true }}
              >
                <Datagrid>
                  <DateField source="created_at" />
                  <TextField source="order_details.ref" />
                  <ReferenceField source="channel_id" reference="channels">
                    <TextField source="name" />
                  </ReferenceField>
                  <NumberField source="order_details.total" />
                  <NumberField source="points" />
                </Datagrid>
              </ReferenceManyField>
            </div>
          )}
          {this.state.tabIndex === 1 && (
            <div>
              <ReferenceManyField
                {...this.props}
                reference="transactions"
                target="member"
              >
                <Datagrid>
                  <DateField source="created_at" />
                  <TextField source="ref" />
                  <TextField source="description" />
                  <TextField source="type" />
                  <NumberField source="points" />
                </Datagrid>
              </ReferenceManyField>
            </div>
          )}
          {this.state.tabIndex === 2 && (
            <div>
              <ReferenceManyField
                {...this.props}
                reference="memberVouchers"
                target="member"
              >
                <Datagrid>
                  <DateField source="created_at" />
                  <ReferenceField source="reward_id" reference="rewards">
                    <TextField source="name" />
                  </ReferenceField>
                  <NumberField source="points" />
                  <DateField source="expire_at" />
                  <BooleanField source="used" />
                  <DateField source="used_at" />
                </Datagrid>
              </ReferenceManyField>
            </div>
          )}
        </CardContent>
      </Card>
    )
  }
}

export default Transaction
