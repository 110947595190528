import React, { useState } from "react"
import {
  showNotification,
  useQuery,
  useTranslate,
  useDataProvider,
} from "react-admin"
import { withRouter } from "react-router-dom"
import { Flex, Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FullLoadingScreen, Pagination } from "../components"
import { ListMembers, HeaderMember } from "./components"

const LevelMember = props => {
  const { match } = props
  const dataProvider = useDataProvider()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [total, setTotal] = useState(0)
  const [isData, setIsData] = useState(true)
  const [listMembers, setListMembers] = useState([])
  const translate = useTranslate()

  const { data, loading } = useQuery({
    type: "getOne",
    resource: "levels",
    payload: { id: match.params.id },
  })

  if (isData) {
    dataProvider
      .getList("members", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "created_at", order: "ASC" },
        filter: { level_id: match.params.id },
      })
      .then(({ data, total }) => {
        if (data) {
          setListMembers([...data])
          setTotal(total)
        }
        setIsData(false)
      })
  }
  const loadMore = newPage => {
    setPage(newPage)
    const params = {
      pagination: { page: newPage, perPage: perPage },
      sort: { field: "created_at" },
      filter: { level_id: match.params.id },
    }
    dataProvider
      .getList("members", params)
      .then(({ data, total }) => {
        if (data) {
          setListMembers([...data])
          setTotal(total)
          setIsData(false)
        }
      })
      .catch(error => {})
  }
  const loadPerPage = newPerPage => {
    setPerPage(newPerPage)
    const params = {
      pagination: { page: page, perPage: newPerPage },
      sort: { field: "created_at", order: "ASC" },
      filter: { level_id: match.params.id },
    }
    dataProvider
      .getList("members", params)
      .then(({ data, total }) => {
        if (data) {
          setListMembers([...data])
          setTotal(total)
          setIsData(false)
        }
      })
      .catch(error => {})
  }

  if (loading) {
    return <FullLoadingScreen />
  }

  return (
    <Box width={[1]} p={[2]}>
      <HeaderMember data={data} match={match} />
      <ListMembers translate={translate} members={listMembers} />
      <Flex
        width={[1]}
        flexDirection={["row"]}
        justifyContent={["flex-end"]}
        alignItems={["center"]}
      >
        <Pagination
          translate={translate}
          total={total}
          page={page}
          perPage={perPage}
          setPage={loadMore}
          setPerPage={loadPerPage}
        />
      </Flex>
    </Box>
  )
}

const enhance = compose(
  withRouter,
  connect(
    undefined,
    { showNotification }
  )
)

export default enhance(LevelMember)
