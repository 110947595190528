import React from "react"

import {
  EditToolbar,
  BetterSimpleForm,
  BetterCreate,
  BetterEdit,
  ActionForm,
} from "../components"
import { has } from "lodash"
import { MainTab, EditForm } from "./components"
import { getProfile } from "../utils"

export const payment_mode = [
  { id: "Wire", name: "resources.payment_mode.list.wire" },
  { id: "Credit Card", name: "resources.payment_mode.list.credit_card" },
]
export const listPaymentTerm = () => {
  let newArray = []
  for (let index = 1; index <= 31; index++) {
    let days = "days"
    if (index === 1) {
      days = "day"
    }
    newArray.push({ id: index, name: `${index} ${days}` })
  }
  return newArray
}

const Form = props => {
  const profile = getProfile()
  const initValues = has(profile, "account_id")
    ? { account_id: profile.account_id }
    : {}
  return (
    <BetterSimpleForm {...props} initialValues={initValues}>
      <MainTab {...props} isCreate={true} />
    </BetterSimpleForm>
  )
}
//
export const create = props => {
  const { permissions } = props
  return (
    <BetterCreate
      {...props}
      actions={<ActionForm title={"resources.clients.name"} />}
    >
      <Form permissions={permissions} />
    </BetterCreate>
  )
}
export const edit = props => (
  <BetterEdit
    {...props}
    actions={<ActionForm title={"resources.clients.name"} />}
  >
    <EditForm toolbar={<EditToolbar />} {...props} />
  </BetterEdit>
)
