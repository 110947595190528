import React from "react"
import { useMutation } from "react-admin"
import { Flex } from "rebass"
import { IconButton } from "@material-ui/core"
import { AddIcon } from "../../components/icon_svg"

const AddButton = ({ values, notify, catalogue_id, refresh }) => {
  const value = {
    catalog_id: parseInt(catalogue_id),
    product_id: values.id,
    rules: {
      total_redemption_per_member: 0,
      total_redemptions: 0,
      birthday_rule: {
        active: true,
        valid_for_month: 1,
      },
    },
  }

  const [handleAddClick, { loading }] = useMutation(
    {
      type: "create",
      resource: "rewards",
      payload: { data: value },
    },
    {
      onSuccess: ({ data }) => {
        notify("resources.notification.add_reward")
        refresh(data)
      },
      onFailure: error => {
        notify("resources.notification.please_try_again", "warning")
      },
    }
  )
  return (
    <Flex flexDirection={"row"} justifyContent={"flex-end"}>
      <IconButton size={"small"} onClick={handleAddClick} disabled={loading}>
        <AddIcon style={{ fontSize: 12 }} />
      </IconButton>
    </Flex>
  )
}
export default AddButton
