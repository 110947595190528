import React from "react"
import { Box } from "rebass"
import Paper from "@material-ui/core/Paper"
import { RaBooleanInput, RaSelectInput, Title } from "../../components"
import { useStyles } from "./utils"

export const listValidFor = [
  { id: 1, name: "1 month" },
  { id: 2, name: "2 months" },
  { id: 3, name: "3 months" },
  { id: 4, name: "4 months" },
  { id: 5, name: "5 months" },
  { id: 6, name: "6 months" },
  { id: 7, name: "7 months" },
  { id: 8, name: "8 months" },
  { id: 9, name: "9 months" },
  { id: 10, name: "10 months" },
  { id: 11, name: "11 months" },
  { id: 12, name: "12 months" },
]

export const BirthDaysContainer = ({ translate }) => {
  const classes = useStyles()
  return (
    <Box pt={[3]}>
      <Box pb={[1]}>
        <Title fontWeight={"bold"} fontSize={12}>
          {translate("resources.rewards.info.birthday_rules")}
        </Title>
      </Box>
      <Paper className={classes.paper}>
        <Box>
          <RaBooleanInput
            label="resources.rewards.fields.visible"
            source="rules.birthday_rule.active"
            id={"visible"}
          />

          <Box width={[1]}>
            <RaSelectInput
              choices={listValidFor}
              label="resources.rewards.fields.valid_for"
              source="rules.birthday_rule.valid_for_month"
              style={{ width: 100 }}
              notetext="resources.rewards.message.valid_for"
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
