export default {
  merchants: {
    name: "Merchants",
    detail_name: "Merchant",
    tabs: {
      merchant_info: "Merchant Info",
      branches: "Branches",
      products: "Products",
      client: "Clients",
    },
    fields: {
      active: "Active",
      name: "Name",
      uen: "UEN",
      address: "Business Address",
      contact_person: "Contact Person",
      email: "Contact Email",
      phone: "Contact Phone",
      payment_terms: "Payment Terms",
      payment_mode: "Payment Mode",
      website: "Website",
      remarks: "Remarks",
      start_date: "Start",
      end_date: "End",
      client: "Client",
      api_settings: {
        app_id: "Application ID",
        app_secret: "Application Secret",
      },
      created_at: "Create On",
    },
  },
}
