import React from "react"
import { useQuery, useNotify } from "react-admin"
import { useRouteMatch } from "react-router"
import { useHistory } from "react-router-dom"
import { Box } from "rebass"
import { get } from "lodash"
import { Form } from "./components"
import { FullLoadingScreen } from "../components"

const ProgrammeCreate = props => {
  const match = useRouteMatch()
  const history = useHistory()
  const showNotification = useNotify()
  const client_id = get(match, "params.client_id", 0)
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "clients",
    payload: { id: client_id },
  })
  const {
    createHref,
    goBack,
    go,
    goForward,
    push,
    replace,
    block,
    listen,
    ...restHistory
  } = history
  const { isExact, ...mymatch } = match

  if (loading) {
    return <FullLoadingScreen />
  }
  const dummyData = {
    client_id: parseInt(client_id),
    account_id: data.account_id,
  }
  const urlList = `/clients/${client_id}/programmes`
  return (
    <Box width={[1]} p={[2]}>
      <Form
        {...restHistory}
        {...mymatch}
        {...props}
        account_id={data.account_id}
        showNotification={showNotification}
        sourceApi="programmes"
        typePostUrl={"CREATE"}
        pathUrl="programmes"
        initialValues={dummyData}
        urlList={urlList}
      />
    </Box>
  )
}

export default ProgrammeCreate
