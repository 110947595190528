import React from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import DollarIcon from "@material-ui/icons/AttachMoney"
import { withStyles } from "@material-ui/core/styles"
// import Typography from "@material-ui/core/Typography"
import { Text } from "rebass"
import { translate } from "react-admin"

import CardIcon from "./CardIcon"

const styles = {
  main: {
    width: 220,
    marginRight: "1em",
    marginTop: 20,
    paddingTop: 10,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 8,
    minHeight: 50,
  },
}

const ContainerCard = ({
  value,
  translate,
  classes,
  title,
  icon = DollarIcon,
  bgcolorIcon = "#31708f",
}) => (
  <div className={classes.main}>
    <CardIcon Icon={icon} bgColor={bgcolorIcon} />
    <Card className={classes.card}>
      <Text fontSize={14}>
        {translate(`resources.dashboard.field.${title}`)}
      </Text>
      <Text mt={[2]} fontSize={24} fontWeight={"bold"}>
        {value}
      </Text>
    </Card>
  </div>
)

const enhance = compose(withStyles(styles), translate)

export default enhance(ContainerCard)
