import React from "react"
import { Flex, Box } from "rebass"
import { lightBlue } from "@material-ui/core/colors"
import {
  TextField,
  DateField,
  BooleanField,
  useTranslate,
  Filter,
  ReferenceInput,
  SelectInput,
} from "react-admin"
import { BetterList, CreateButton, ButtonEditView, Title } from "../components"
import { AddIcon } from "../components/icon_svg"

import { ROLE_USER } from "../utils"

export { create as ClientCreate, edit as ClientEdit } from "./Create"
// export { ClientChart } from "./components"

const ClientAction = ({ basePath }) => {
  return (
    <Box width={[1]} pt={[2]} pl={[3]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Box></Box>
        <Box>
          <CreateButton
            basePath={basePath}
            icon={<AddIcon />}
            sizeIcon={"xsmall"}
            style={{ color: lightBlue[700] }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

const ClientFilter = ({ permissions, ...props }) => {
  const translate = useTranslate()
  return (
    <Box pt={[3]}>
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.clients.name`)}
        </Title>
      </Box>
      <Filter
        {...props}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 16,
          paddingRight: 16,
        }}
      >
        {permissions === ROLE_USER.super_admin && (
          <ReferenceInput
            label="Account"
            source="account_id"
            reference="accounts"
            alwaysOn
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </Filter>
    </Box>
  )
}

export const ClientList = props => {
  return (
    <BetterList
      {...props}
      actions={<ClientAction />}
      filters={<ClientFilter permissions={props.permissions} />}
    >
      <TextField source="name" />
      <BooleanField source="active" />
      <DateField
        source="created_at"
        options={{
          day: "numeric",
          month: "short",
          year: "numeric",
        }}
      />
      <ButtonEditView {...props} />
    </BetterList>
  )
}
