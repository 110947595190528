import React, { Component } from "react"
import PropTypes from "prop-types"
import { shallowEqual } from "recompose"
import Dropzone from "react-dropzone"
import { get } from "lodash"
import { Box, Flex } from "rebass"
import { UploadClient } from "../../restClient"
import Button from "@material-ui/core/Button"
import { red, grey } from "@material-ui/core/colors"
import CircularProgress from "@material-ui/core/CircularProgress"
import Snackbar from "@material-ui/core/Snackbar"
import { RemoveCircle } from "@material-ui/icons"
import { translate } from "react-admin"
import { styles } from "./styles"
import { ERROR_MESSAGE_MAX_SIZE, ERROR_MESSAGE_FAILED } from "./utils"
import "./index.css"

class ImageOlaInput extends Component {
  constructor(props) {
    super(props)
    let photos = get(props, `record[${props.source}]`, [])
    let isShowing = true
    if (photos.length === 1 && photos[0] instanceof Object) {
      isShowing = false
    }
    this.state = {
      photos: photos,
      isShow: isShowing,
      isLoading: false,
      openSnackBar: false,
      variant: "success",
      message: "Please try again",
    }
    // console.log(props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let photos = get(nextProps, `record[${nextProps.source}]`, [])
    this.setState({ photos: photos })
  }
  convertTagsName = (name, tags) => {
    let brand = name.trim()
    let newTags = ""
    brand = brand.replace(/\s+/g, "-")
    brand = brand.replace(/[&/\\#,+[/\]()$~%.@'":*!`^?<>{}]/g, "")
    if (tags) {
      const tagsTrim = tags.map(tag => {
        return tag.trim()
      })
      newTags = tagsTrim.join("-")
      newTags = newTags.replace(/\s+/g, "-")
      //remove special character
      newTags = newTags.replace(/[&/\\#,+[/\]()$~%.@'"^:*!`?<>{}]/g, "")
    }

    let joinData = newTags === "" ? brand : brand + "-" + newTags
    // console.log(joinData);
    return joinData
  }
  onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoading: true,
      })
      const { basePath, toValue, labelImage, sourceApi } = this.props
      const name = toValue
      const flag = labelImage
      const newBasePath = basePath ? basePath : `/${sourceApi}`
      // console.log(newBasePath)
      let url = newBasePath ? newBasePath + "/upload" : +"/products/upload"
      if (newBasePath === "/payment") {
        url = "/products/upload"
      } else if (newBasePath === "/catalogues") {
        url = "/catalogs/upload"
      } else if (newBasePath === "/adbanner") {
        url = "/products/upload"
      }
      if (name !== undefined && name !== "") {
        let brand = name.trim()
        brand = brand.replace(/\s+/g, "-")
        brand = brand.replace(/[&/\\#,+[/\]()$~%.@'"^:*!`?<>{}]/g, "")

        let joinData = brand

        if (flag.toLowerCase() === "desktop") {
          joinData += "-desktop"
        } else if (flag.toLowerCase() === "mobile") {
          joinData += "-mobile"
        }
        let formData = new FormData()
        // caption as brand-product name
        formData.append("caption", joinData)
        accepted.forEach(file => {
          formData.append("photos", file, file.name)
        })

        UploadClient(formData, url)
          .then(response => response.json())
          .then(data => {
            if (data && data.url) {
              this.onAdd(data.url)
            } else {
              this.setState({
                isLoading: false,
                openSnackBar: true,
                variant: "error",
                message: ERROR_MESSAGE_FAILED,
              })
            }
          })
          .catch(e => {
            this.setState({
              isLoading: false,
              openSnackBar: true,
              variant: "error",
              message: ERROR_MESSAGE_FAILED,
            })
          })
      }
    } else {
      this.setState({
        isLoading: false,
        openSnackBar: true,
        message: ERROR_MESSAGE_MAX_SIZE,
      })
    }
    //console.log(this.props);
  }

  onAdd(url) {
    const updatedFiles = [...this.state.photos, url]
    // //console.log(updatedFiles);
    this.setState({
      photos: updatedFiles,
      isLoading: false,
      openSnackBar: true,
      variant: "success",
      message: "Image Uploaded",
    })

    this.props.updateField(this.props.source, updatedFiles)
  }

  onRemove = file => () => {
    const filteredFiles = this.state.photos.filter(
      stateFile => !shallowEqual(stateFile, file)
    )
    this.setState({ photos: filteredFiles })
    // this.props.input.onChange(filteredFiles)
    this.props.updateField(this.props.source, filteredFiles)
  }

  renderItem() {
    // console.log(this.state.photos.length)
    if (this.state.photos.length === 0) {
      return <div />
    }
    return (
      <Box>
        {this.state.photos.length > 0 &&
          this.state.photos.map((item, index) => {
            // console.log(item)
            return (
              <Box key={index}>
                <Button
                  style={styles.removeButton}
                  onClick={this.onRemove(item)}
                >
                  <RemoveCircle style={{ fill: red[900] }} />
                </Button>
                <img
                  src={item}
                  style={styles.images}
                  alt="delete"
                  title="photos"
                />
              </Box>
            )
          })}
      </Box>
    )
  }
  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    })
  }
  render() {
    const {
      accept,
      // children,
      disableClick,
      elStyle,
      maxSize,
      minSize,
      multiple,
      labelImage,
      // style,
    } = this.props
    return (
      <div style={elStyle}>
        <div className="labelImage">{labelImage}</div>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
        >
          <Box pr={[2]}>
            <Dropzone
              onDrop={this.onDrop}
              accept={accept}
              disableClick={disableClick}
              maxSize={maxSize}
              minSize={minSize}
              multiple={multiple}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: 180,
                    height: 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: grey[200],
                    borderWidth: 0,
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {this.state.isLoading && (
                      <div style={styles.insideBox}>
                        <CircularProgress />
                      </div>
                    )}
                    {!this.state.isLoading && (
                      <div style={styles.insideBox}>
                        <span>Drop your image file here </span>
                        <span>or Click here</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </Box>
          <Flex>
            {this.state.isShow &&
              this.state.photos.length > 0 &&
              this.renderItem()}
          </Flex>
          {/* <div className="previews"></div> */}
        </Flex>
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.message}
          autoHideDuration={4000}
          variant={this.state.variant}
          onClose={this.handleRequestClose}
        />
      </div>
    )
  }
}

ImageOlaInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
}

ImageOlaInput.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  onUpload: () => {},
}

export default translate(ImageOlaInput)
