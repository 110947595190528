import React, { useRef } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import {
  useTranslate,
  sanitizeEmptyValues,
  getFormInitialValues,
} from "react-admin"
import SimpleFormView from "./SimpleFormView"
import CustomFormView from "./CustomFormView"

// import getFormInitialValues from "./getFormInitialValues"
// import FormInput from "./FormInput"
// import Toolbar from "./Toolbar"
// import CardContentInner from "../layout/CardContentInner"

const SimpleOlehForm = ({ initialValues, defaultValue, saving, ...props }) => {
  let redirect = useRef(props.redirect)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect
  }

  const translate = useTranslate()

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  )

  const submit = (values) => {
    const finalRedirect =
      typeof redirect.current === undefined ? props.redirect : redirect.current
    const finalValues = sanitizeEmptyValues(finalInitialValues, values)

    props.save(finalValues, finalRedirect)
  }
  const { isCustomRender, ...newProps } = props
  return (
    <Form
      key={props.version}
      initialValues={finalInitialValues}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
        updateField: ([field, value], state, utils) => {
          utils.changeValue(state, field, () => value)
        },
      }}
      keepDirtyOnReinitialize
      destroyOnUnregister
      subscription={defaultSubscription}
      {...newProps}
      render={({
        form: {
          mutators: { updateField },
        },
        ...formProps
      }) =>
        isCustomRender ? (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        ) : (
          <SimpleFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )
      }
    />
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

export default SimpleOlehForm
