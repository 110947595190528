import React, { Children, useCallback } from "react"
import PropTypes from "prop-types"
import { FormSpy } from "react-final-form"

import classnames from "classnames"
import {
  useInitializeFormWithRecord,
  FormInput,
  Toolbar,
  CardContentInner,
} from "react-admin"
import { IsDebugForm } from "./ValuesForm"
import { API_SERVER, API_DEBUG } from "../../restClient"
import { sanitizeRestProps } from "./utils"

const SimpleFormView = ({
  basePath,
  children,
  className,
  invalid,
  form,
  pristine,
  record,
  redirect: defaultRedirect,
  resource,
  saving,
  setRedirect,
  submitOnEnter,
  toolbar,
  undoable,
  version,
  handleSubmit,
  variant,
  margin,
  updateField,
  dirtyFieldsSinceLastSubmit,
  ...rest
}) => {
  useInitializeFormWithRecord(record)

  const handleSubmitWithRedirect = useCallback(
    (redirect = defaultRedirect) => {
      setRedirect(redirect)
      handleSubmit()
    },
    [setRedirect, defaultRedirect, handleSubmit]
  )

  return (
    <form
      className={classnames("simple-form", className)}
      {...sanitizeRestProps(rest)}
    >
      <CardContentInner key={version}>
        {Children.map(children, (input) => (
          <FormInput
            basePath={basePath}
            input={input}
            record={record}
            resource={resource}
            variant={variant}
            margin={margin}
            updateField={(key, value) => updateField(key, value)}
          />
        ))}
      </CardContentInner>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
      {API_SERVER !== API_DEBUG && IsDebugForm && (
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            return (
              <code style={{ fontSize: 10, padding: 10, margin: 16 }}>
                {JSON.stringify(props.values, undefined, 2)}
              </code>
            )
          }}
        </FormSpy>
      )}
    </form>
  )
}

SimpleFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number,
}

SimpleFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
}

export default SimpleFormView
