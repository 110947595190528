import React from "react"
import { Box, Flex } from "rebass"
import { TextFieldForm, ReferanceTextForm } from "../../components"

export const ListProducts = props => {
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        pt={[2]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
      >
        <TextFieldForm
          source="product.name"
          label="resources.rewards.fields.name"
        />
        <TextFieldForm
          source="product.merchant_offer.price"
          label="resources.rewards.fields.price"
        />
        <ReferanceTextForm
          label="resources.rewards.fields.merchant_name"
          source="product.merchant_id"
          reference="merchants"
          optionText="name"
        />
      </Flex>
    </Box>
  )
}
