import React, { useState } from "react"
import { showNotification, useTranslate, downloadCSV } from "react-admin"
import { withRouter } from "react-router-dom"
import { Flex, Box } from "rebass"
import { get } from "lodash"
import { lightBlue } from "@material-ui/core/colors"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { DatePicker } from "antd"
import * as Moment from "moment"
import { extendMoment } from "moment-range"
import { ListButton, Title } from "../components"
import { TitleRangePicker } from "../dashboard"
import Button from "@material-ui/core/Button"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import { RestJsonReports } from "../restClient"
import "antd/dist/antd.css"
const moment = extendMoment(Moment)

const { RangePicker } = DatePicker
const defaultStart = moment().add(-7, "days")
const ProgrammeDownloadCSV = (props) => {
  // console.log(props)
  const translate = useTranslate()
  const [myStart, setMyStart] = useState(defaultStart)
  const [myEnd, setmyEnd] = useState(moment())
  const { location } = props
  const { pathname } = location
  const check = `/programmes`
  const n = pathname.indexOf(check)
  const currentParent = pathname.substring(0, n + check.length)
  const onHandleChange = (value) => {
    if (value !== null) {
      if (value[0]) {
        setMyStart(moment(value[0]).format("YYYY-MM-DD"))
      }
      if (value[1]) {
        setmyEnd(moment(value[1]).format("YYYY-MM-DD"))
      }
    }
  }
  const onDownload = () => {
    const { match } = props
    if (myStart && myEnd && myStart !== "" && myEnd !== "") {
      ///admin/merchants/2/report?start_date=2020-08-01&end_date=2020-08-31
      // console.log(newStart, newEnd)
      const url = `/loyaltyPrograms/${get(
        match,
        "params.id",
        0
      )}/report?start_date=${myStart}&end_date=${myEnd}`
      // console.log(url)
      RestJsonReports(url)
        .then((response) => response.text())
        .then((data) => {
          // console.log(data)
          downloadCSV(data, `Report-${myStart}-to-${myEnd}`)
          // if (data && data.length > 0) {
          //   console.log(data)
          // }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  const isDownload = get(props, "location.pathname", "") !== "/download/csv"
  return (
    <Box width={[1]} py={[3]}>
      {isDownload && (
        <Box width={[1]} py={[2]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Title fontSize={[2]} fontWeight={"bold"}>
                {translate("resources.programmes.info.title_download_report")}
              </Title>
            </Box>
            <ListButton
              basepath={currentParent}
              record={{}}
              style={{ color: lightBlue[700] }}
            />
          </Flex>
        </Box>
      )}
      <TitleRangePicker />
      <Box width={[1]}>
        <RangePicker
          placeholder={["Start", "End"]}
          allowClear={false}
          format="DD-MMM-YYYY"
          onChange={onHandleChange}
          defaultValue={[myStart, myEnd]}
        />
      </Box>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent="flex-start"
        py={[3]}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudDownloadIcon />}
          onClick={onDownload}
        >
          {translate("resources.buttons.generate_csv")}
        </Button>
      </Flex>
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(ProgrammeDownloadCSV)
