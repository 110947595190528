import React from "react"
import { lightBlue } from "@material-ui/core/colors"
import { Flex, Box } from "rebass"
import { get } from "lodash"
import {
  useTranslate,
  Toolbar,
  SaveButton,
  useQueryWithStore,
} from "react-admin"
import { BetterEdit, FullLoadingScreen, Title } from "../components"
import { EditForm } from "./components"
import { ListButton } from "../components/buttons"

const EditToolbar = (props) => {
  const { redirect1, ...rest } = props
  return (
    <Toolbar {...rest}>
      <SaveButton redirect={redirect1} />
    </Toolbar>
  )
}

const ActionForm = (props) => {
  const translate = useTranslate()
  const { url_list } = props

  return (
    <Box width={[1]} pt={[3]} pb={[2]}>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <Box width={[1]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(props.title)}
          </Title>
        </Box>
        <ListButton basepath={url_list} style={{ color: lightBlue[700] }} />
      </Flex>
    </Box>
  )
}

const CatalogEdit = (props) => {
  const { match, history } = props
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "catalogues",
    payload: { id: match.params.id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }

  const lpi = get(data, "loyalty_program_id", "")
  const urlList = `/programmes/${lpi}/catalog`
  return (
    <BetterEdit
      {...props}
      actions={
        <ActionForm title={"resources.catalogues.name"} url_list={urlList} />
      }
    >
      <EditForm
        toolbar={<EditToolbar redirect1={() => history.push(urlList)} />}
        {...props}
      />
    </BetterEdit>
  )
}

export default CatalogEdit
