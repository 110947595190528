import React from "react"
import { Admin, Resource } from "react-admin"
import { JssProvider } from "react-jss"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import authClient from "./authClient"
import LoginPage from "./auth/LoginPage"
import { themeMui } from "./themes"
import { AppLayout, AppLogoutButton } from "./components/app"
import translations from "./i18n"
import { AccountList, AccountCreate, AccountEdit } from "./accounts"
// import { MemberCreate1, MemberEdit1, MemberList, MemberShow } from "./members"
import {
  MerchantList,
  MerchantCreate,
  MerchantEdit,
  // MerchantShow,
} from "./merchants"
import { CatalogEdit } from "./catalogues"
import { ClientList, ClientCreate, ClientEdit } from "./clients"
import {
  ProgrammeEdit,
  ProgrammeList,
  ProgrammeClientEdit,
  CreateProgramme,
} from "./programmes"

// import { HerokuRegisterList, HerokuRegisterEdit } from "./register"
// import { HerokuProvisionsList, HerokuProvisionsEdit } from "./provisions"
import { UserList, UserCreate, UserEdit, UserShow } from "./users"
import { generateClassName, ROLE_USER } from "./utils"
import customRoutes from "./customRoutes"
import { dataProvider } from "./restClient"
import { Dashboard } from "./dashboard"

export const isShowing = (permissions, type) => {
  let showing = false
  if (permissions === ROLE_USER.super_admin) {
    return true
  } else if (permissions === ROLE_USER.account_admin) {
    switch (type) {
      case "members":
      case "merchants":
      case "clients":
      case "catalogues":
      case "programmes":
      case "users":
        return true

      default:
        return false
    }
  } else if (permissions === ROLE_USER.client_admin) {
    switch (type) {
      case "members":
      case "catalogues":
      case "programmes":
      case "users":
        return true
      default:
        return false
    }
  } else if (permissions === ROLE_USER.loyalty_program_admin) {
    switch (type) {
      case "members":
      case "catalogues":
      case "programmes":
        return true
      default:
        return false
    }
  }

  return showing
}

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <JssProvider generateClassName={generateClassName}>
      <Admin
        layout={AppLayout}
        dataProvider={dataProvider}
        customRoutes={customRoutes}
        title="Loyalty Admin"
        theme={themeMui}
        authProvider={authClient}
        i18nProvider={translations}
        loginPage={LoginPage}
        logoutButton={AppLogoutButton}
        dashboard={Dashboard}
      >
        {(permissions) => {
          // console.log(permissions)
          return [
            <Resource
              name="accounts"
              list={permissions === ROLE_USER.super_admin ? AccountList : null}
              create={
                permissions === ROLE_USER.super_admin ? AccountCreate : null
              }
              edit={permissions === ROLE_USER.super_admin ? AccountEdit : null}
            />,
            <Resource
              name="members"
              // list={isShowing(permissions, "members") ? MemberList : null}
              // create={isShowing(permissions, "members") ? MemberCreate1 : null}
              // edit={isShowing(permissions, "members") ? MemberEdit1 : null}
              // show={isShowing(permissions, "members") ? MemberShow : null}
            />,
            <Resource
              name="merchants"
              list={isShowing(permissions, "merchants") ? MerchantList : null}
              create={
                isShowing(permissions, "merchants") ? MerchantCreate : null
              }
              edit={isShowing(permissions, "merchants") ? MerchantEdit : null}
              // show={isShowing(permissions, "merchants") ? MerchantShow : null}
            />,
            <Resource
              name="catalogues"
              edit={isShowing(permissions, "catalogues") ? CatalogEdit : null}
            />,
            <Resource
              name="clients"
              list={isShowing(permissions, "clients") ? ClientList : null}
              create={isShowing(permissions, "clients") ? ClientCreate : null}
              edit={isShowing(permissions, "clients") ? ClientEdit : null}
            />,
            permissions === ROLE_USER.client_admin ? (
              <Resource
                name="programmes"
                list={ProgrammeList}
                create={CreateProgramme}
                edit={
                  isShowing(permissions, "programmes")
                    ? ProgrammeClientEdit
                    : null
                }
              />
            ) : null,
            permissions === ROLE_USER.loyalty_program_admin ||
            permissions === ROLE_USER.super_admin ||
            permissions === ROLE_USER.account_admin ? (
              <Resource
                name="programmes"
                edit={
                  isShowing(permissions, "programmes") ? ProgrammeEdit : null
                }
              />
            ) : null,
            // <Resource
            //   name="register"
            //   list={
            //     permissions === ROLE_USER.super_admin ? HerokuRegisterList : null
            //   }
            //   edit={
            //     permissions === ROLE_USER.super_admin ? HerokuRegisterEdit : null
            //   }
            // />,
            // <Resource
            //   name="provisions"
            //   list={
            //     permissions === ROLE_USER.super_admin
            //       ? HerokuProvisionsList
            //       : null
            //   }
            //   edit={
            //     permissions === ROLE_USER.super_admin
            //       ? HerokuProvisionsEdit
            //       : null
            //   }
            // />,
            <Resource
              name="users"
              create={isShowing(permissions, "users") ? UserCreate : null}
              list={isShowing(permissions, "users") ? UserList : null}
              edit={isShowing(permissions, "users") ? UserEdit : null}
              show={isShowing(permissions, "users") ? UserShow : null}
            />,
            <Resource name="earningRules" />,
            <Resource name="levels" />,
            <Resource name="branches" />,
            <Resource name="rewards" />,
            <Resource name="transactions" />,
            <Resource name="products" />,
            <Resource name="memberVouchers" />,
            <Resource name="loyaltyPrograms" />,
            <Resource name="merchantClients" />,
          ]
        }}
      </Admin>
    </JssProvider>
  </MuiPickersUtilsProvider>
)

export default App
