import React from "react"
import { Datagrid, DateField, FormTab, TextField } from "react-admin"

import { Flex } from "rebass"
import { Pagination } from "react-admin"
import {
  BetterEditButton,
  BetterTabbedForm,
  RaReferenceManyField,
  BetterChartButton,
  BetterDownloadCSVButton,
} from "../../components"
import MainTab from "./MainTab"

const ListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ButtonEditCartView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterDownloadCSVButton
      {...props}
      isCustomLink={true}
      taburl="programmes"
      chartLink="csv"
    />
    <BetterChartButton {...props} isCustomLink={true} taburl="programmes" />
    <BetterEditButton {...props} isCustomLink={true} taburl="programmes" />
  </Flex>
)

const PriceField = ({ record = {} }) => (
  <span>{record.cost + record.margin}</span>
)
PriceField.defaultProps = {
  label: "Price",
}

const EditForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    bashPath,
    ...checkProps
  } = props

  return (
    <BetterTabbedForm {...props}>
      <FormTab label="resources.clients.tabs.client_info">
        <MainTab {...props} />
      </FormTab>
      <FormTab label="resources.clients.tabs.programmes" path="programmes">
        <RaReferenceManyField
          addLabel={false}
          reference="programmes"
          target="client_id"
          pagination={<ListPagination />}
          fullWidth
          notShowSearch={true}
          filter={{ q: "" }}
        >
          <Datagrid>
            <TextField source="name" />
            <DateField source="created_at" />
            <ButtonEditCartView localtion={checkProps.location} />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
    </BetterTabbedForm>
  )
}

export default EditForm
