import merchants from "./merchants"
import menus from "./menus"
import memberVouchers from "./memberVouchers"
import members from "./members"
import provisions from "./provisions"
import accounts from "./accounts"
import channels from "./channels"
import clients from "./clients"
import dashboard from "./dashboard"
import earningRules from "./earningRules"
import levels from "./levels"
import loyaltyPrograms from "./loyaltyPrograms"
import login from "./login"
import register from "./register"
import registration from "./registration"
import rewards from "./rewards"
import setup from "./setup"
import settings from "./settings"
import transactions from "./transactions"
import users from "./users"
import programmes from "./programmes"
import buttons from "./buttons"
import payment_mode from "./payment_mode"
import branches from "./branches"
import products from "./products"
import notification from "./notification"
import catalogues from "./catalogues"
export default {
  ...accounts,
  ...buttons,
  ...branches,
  ...catalogues,
  ...channels,
  ...clients,
  ...dashboard,
  ...earningRules,
  ...levels,
  ...loyaltyPrograms,
  ...login,
  ...merchants,
  ...members,
  ...memberVouchers,
  ...menus,
  ...notification,
  ...products,
  ...programmes,
  ...payment_mode,
  ...provisions,
  ...register,
  ...registration,
  ...rewards,
  ...settings,
  ...setup,
  ...transactions,
  ...users,
}
