import React from "react"
import { Box } from "rebass"
import Paper from "@material-ui/core/Paper"
import { RaNumberInput, CollapseMui } from "../../components"
import { BirthDaysContainer } from "./BirthDaysContainer"

export const CollapseFields = props => {
  const { translate } = props

  return (
    <CollapseMui label={translate("resources.products.title_expand")}>
      <Paper typepaper={"paper"} style={{ marginTop: 0, padding: 16 }}>
        <Box width={[1]}>
          <Box>
            <RaNumberInput
              label="resources.rewards.fields.total_redemption_member"
              source="rules.total_redemption_per_member"
              style={{ width: 300 }}
              notetext="resources.rewards.message.total_redemption_member"
            />
          </Box>
          <Box pb={[2]} pt={[1]}>
            <RaNumberInput
              label="resources.rewards.fields.total_redemption"
              source="rules.total_redemptions"
              style={{ width: 300 }}
              notetext="resources.rewards.message.total_redemption"
            />
          </Box>
          <BirthDaysContainer {...props} translate={translate} />
        </Box>
      </Paper>
    </CollapseMui>
  )
}
