import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FormFields } from "./components"
import { FullLoadingScreen } from "../components"

const CatalogCreate = (props) => {
  const { match } = props
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "programmes",
    payload: { id: match.params.program_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  // console.log(data)
  // const urlList = `/programmes/${match.params.program_id}/catalog`
  const dummyData = {
    loyalty_program_id: data.id,
  }
  return (
    <Box width={[1]} pt={[3]}>
      <FormFields
        {...props}
        sourceApi="catalogues"
        typePostUrl={"CREATE"}
        pathUrl="catalogues"
        initialValues={dummyData}
        record={dummyData}
      />
    </Box>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CatalogCreate)
