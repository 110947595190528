import React from "react"
import { useTranslate } from "react-admin"
import { Box } from "rebass"

import { Line } from "react-chartjs-2"

const ChartDailyMember = (props) => {
  const translate = useTranslate()
  const { label, dataSet } = props
  const newData = {
    labels: label,
    datasets: [
      {
        label: translate("resources.dashboard.title.new_member"),
        fill: true,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataSet,
      },
    ],
  }
  return (
    <Box width={[9.8 / 10]} py={[2]}>
      <Line data={newData} />
    </Box>
  )
}

export default ChartDailyMember
