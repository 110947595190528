import React, { useRef } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import {
  useTranslate,
  sanitizeEmptyValues,
  getFormInitialValues,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin"
import SimpleFormView from "../../components/form/SimpleFormView"
import CustomFormView from "../../components/form/CustomFormView"

// import getFormInitialValues from "./getFormInitialValues"
// import FormInput from "./FormInput"
// import Toolbar from "./Toolbar"
// import CardContentInner from "../layout/CardContentInner"

const FormCreateLPA = ({
  initialValues,
  defaultValue,
  saving,
  history,
  typePost,
  staticContext,
  ...props
}) => {
  let redirect = useRef(props.redirect)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect
  }
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const notify = useNotify()
  const redirect1 = useRedirect()

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  )

  const submit = (values) => {
    // const finalRedirect =
    //   typeof redirect.current === undefined ? props.redirect : redirect.current
    const finalValues = sanitizeEmptyValues(finalInitialValues, values)
    // console.log(finalValues)
    // props.save(finalValues, finalRedirect)
    if (typePost === "CREATE") {
      const params = {
        data: finalValues,
      }
      dataProvider
        .create("users", params)
        .then(({ data }) => {
          // console.log("user", data)
          notify("ra.notification.created")
          redirect1("/programmes")
        })
        .catch((error) => {
          notify("resources.notification.please_try_again", "warning")
        })
    }
  }
  const { isCustomRender, ...newProps } = props
  return (
    <Form
      key={props.version}
      initialValues={finalInitialValues}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
        updateField: ([field, value], state, utils) => {
          utils.changeValue(state, field, () => value)
        },
      }}
      keepDirtyOnReinitialize
      destroyOnUnregister
      subscription={defaultSubscription}
      {...newProps}
      render={({
        form: {
          mutators: { updateField },
        },
        ...formProps
      }) =>
        isCustomRender ? (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        ) : (
          <SimpleFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )
      }
    />
  )
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

export default FormCreateLPA
