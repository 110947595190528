import React from "react"
import { get } from "lodash"
import { Box, Text } from "rebass"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { useQueryWithStore, useTranslate } from "react-admin"
import NumberFormat from "react-number-format"
import { FullLoadingScreen } from "../../components"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
  },
  thLabel: {
    width: 400,
  },
}))

const ListMerchantProducts = (props) => {
  const classes = useStyles()
  const { merchant_id } = props
  const translate = useTranslate()
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "products",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: { merchant_id: merchant_id },
    },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  if (data.length === 0) {
    return <div />
  }
  return (
    <Box width={[1]}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.thLabel}>
              <Typography className={classes.label}>
                {translate("resources.catalogues.tabmerchant.name")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label}>
                {translate("resources.catalogues.tabmerchant.price")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell className={classes.thLabel}>
                  <Text fontSize={[0]}>{row.name}</Text>
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={get(row, "merchant_offer.price", "")}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => <Text fontSize={[0]}>{value}</Text>}
                    prefix="$"
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}
export default ListMerchantProducts
