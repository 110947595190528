import React from "react"
import { get } from "lodash"
import { TextField, DateField, useQueryWithStore } from "react-admin"
import { Flex, Box, Text } from "rebass"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Avatar from "@material-ui/core/Avatar"

const LevelName = ({ record }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: "levels",
    payload: { id: record.level_id },
  })
  if (!loaded) {
    return <span />
  }
  if (error) {
    return <span />
  }
  return (
    <Text fontSize={[12]} pb={[1]}>
      {get(data, "name", "")}
    </Text>
  )
}

export const FieldRow = props => (
  <Flex
    {...props}
    width={[1]}
    flexDirection={"row"}
    marginBottom={[1]}
    alignItems={["center"]}
  />
)

export const ProfileSummary = props => (
  <Card style={{ marginBottom: 8 }}>
    <CardContent>
      <Flex width={[1]} alignItems={"center"} justifyContent={"center"}>
        <Avatar
          alt=""
          src={props.record.avatar || "/avatar.jpg"}
          style={{ width: "100%", height: "auto" }}
        />
      </Flex>
      <Text fontSize={[12]} pb={[1]}>
        {props.record.first_name} {props.record.last_name}
      </Text>
      <Box width={[1]}>
        <LevelName {...props} />
      </Box>
      <Box width={[1]}>
        <TextField {...props} source="email" />
      </Box>
      <Box width={[1]}>
        <TextField {...props} source="phone" />
      </Box>
    </CardContent>
  </Card>
)
export const ProfileDetails = props => (
  <Card>
    <CardHeader
      disableTypography={true}
      title={
        <Text fontSize={[14]} fontWeight={"bold"}>
          Profile details
        </Text>
      }
      style={{ borderBottom: "solid 1px #cccccc" }}
    />
    <CardContent>
      <FieldRow>
        <Text fontSize={[12]} pr={[1]} style={{ width: 70 }}>
          First name
        </Text>
        <TextField {...props} source="first_name" />
      </FieldRow>
      <FieldRow>
        <Text fontSize={[12]} pr={[1]} style={{ width: 70 }}>
          Last name
        </Text>
        <TextField {...props} source="last_name" />
      </FieldRow>
      <FieldRow>
        <Text fontSize={[12]} pr={[1]} style={{ width: 70 }}>
          Birth date
        </Text>
        <DateField {...props} source="birthdate" />
      </FieldRow>
      <FieldRow>
        <Text fontSize={[12]} pr={[1]} style={{ width: 70 }}>
          Gender
        </Text>
        <TextField {...props} source="gender" />
      </FieldRow>
    </CardContent>
  </Card>
)
