import React from "react"
import {
  FormDataConsumer,
  AutocompleteArrayInput,
  ImageField,
  DateTimeInput,
} from "react-admin"
import { get } from "lodash"
import {
  ImageOlaSingleInput,
  RaBooleanInput,
  RaReferenceArrayInput,
  PanelView,
} from "../../components"

export const DataDefaultForm = props => (
  <div>
    <PanelView title="resources.earningRules.title.effective_dates">
      <div>
        <RaBooleanInput
          source="all_time_active"
          label={"resources.earningRules.fields.all_time_active"}
          format={v => !v}
          parse={v => !v}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const alltimeactive = get(formData, "all_time_active", true)
            return (
              !alltimeactive && (
                <div>
                  <DateTimeInput
                    source="start_at"
                    label={"resources.earningRules.fields.start_at"}
                    style={{ paddingRight: 24 }}
                  />
                  <DateTimeInput
                    source="end_at"
                    label={"resources.earningRules.fields.end_at"}
                  />
                </div>
              )
            )
          }}
        </FormDataConsumer>
      </div>
    </PanelView>
    <RaReferenceArrayInput
      source="levels"
      reference="levels"
      label={"resources.earningRules.fields.levels"}
      notetext={"resources.earningRules.notetext.levels"}
      {...props}
    >
      <AutocompleteArrayInput optionText="name" />
    </RaReferenceArrayInput>
    <ImageOlaSingleInput
      source="photo"
      toValue="photo"
      labelImage=""
      label={"resources.levels.fields.photo"}
      accept="image/*"
      key="photo"
      {...props}
    >
      <ImageField source="src" title="title" />
    </ImageOlaSingleInput>
  </div>
)
